import React from "react";
import AudioPlayer from "./audioPlayer";
import VideoPlayer from "./videoPlayer";

const MediaPlayer = ({ data }) => {
  // useEffect(() => {
  //   console.log(data?.duration);
  //   let arr = [];
  //   for (let index = 0; index < data?.duration * 2; index++) {
  //     arr.push(index);
  //   }
  //   console.log(arr);
  //   setReactBar(arr);
  // }, [data]);
  function renderMedia(type) {
    switch (type) {
      case "video":
        return (
          <VideoPlayer
            src={`https://nhost.tautapp.co/storage/o/${data?.video_key}`}
          />
        );

      case "screen":
        return (
          <VideoPlayer
            src={`https://nhost.tautapp.co/storage/o/${data?.video_key}`}
          />
        );

      case "":
        return (
          <VideoPlayer
            src={`https://nhost.tautapp.co/storage/o/${data?.video_key}`}
          />
        );

      case "audio":
        return (
          <AudioPlayer
            src={`https://nhost.tautapp.co/storage/o/${data?.video_key}`}
          />
        );

      default:
        break;
    }
  }

  // const [openReactMenuItem, setOpenReactMenuItem] = useState(null);
  return (
    <div className=" flex flex-col w-full max-w-3xl relative ">
      {renderMedia(data?.content_type)}

      {/* <div className=" flex flex-col w-full px-4 absolute bottom-2">
        <div className="  flex flex-row w-full h-1 bg-gray-400    justify-around items-center">
          {reactBar?.map((item) => (
            <div
              key={item}
              className=" flex flex-col h-3 w-3 rounded-full bg-gray-300 hover:bg-gray-400 cursor-pointer"
              onClick={() =>
                item === openReactMenuItem
                  ? setOpenReactMenuItem(null)
                  : setOpenReactMenuItem(item)
              }
              onMouseOver={() => setOpenReactMenuItem(item)}>
              <div
                className={` dropdown ${
                  item === openReactMenuItem && "dropdown-open"
                } `}
                onMouseLeave={() =>
                  setTimeout(() => {
                    setOpenReactMenuItem(null);
                  }, 600)
                }>
                <ul className="dropdown-content  menu  shadow bg-base-100 rounded-box    ">
                  <li className="ml-0 p-0">
                    <a>👍</a>
                  </li>
                  <li className="ml-0 p-0">
                    <a>👎</a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* <div className=" flex flex-row justify-between px-4 items-center w-full py-1 bg-blue-500 rounded-full">
        {divider.map((item) => (
          <div key={item} className=" h-2 w-2 rounded-full bg-white"></div>
        ))}
      </div> */}
    </div>
  );
};

export default MediaPlayer;
