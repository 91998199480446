import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StateContext } from "../../context/appContext";

import { auth } from "../../util/nhost";

const Profile = () => {
  const state = useContext(StateContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout().then(() => navigate("/login"));
  };
  return (
    <div>
      <div className="dropdown  ">
        <label tabIndex={0}>
          <div className="w-10 h-10 rounded-full object-cover  cursor-pointer ">
            <img
              className="    w-full h-full  object-cover   object-top"
              alt=""
              src={
                state?.userData?.avatar_url
                  ? state?.userData?.avatar_url
                  : "/assets/images/avatar.png"
              }
            />
          </div>
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content  right-0  menu p-2 shadow bg-base-100 rounded-box w-52">
          <div className="  cursor-default flex flex-col p-2 pl-4 ml-2 rounded-lg bg-base-200 ">
            <span className=" text-sm text-gray-600   break-all">
              {auth?.currentUser?.display_name}
            </span>

            <span className=" text-sm text-gray-500 break-all">
              {auth?.currentUser?.email}
            </span>
          </div>
          <li>
            <Link to="/profile">My profile</Link>
          </li>
          <li>
            <Link to="/addressbook">My address book</Link>
          </li>
          <li>
            <button onClick={() => handleLogout()}>Log out</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Profile;
