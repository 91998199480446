import { useAuth } from "@nhost/react-auth";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CreateContentStatus from "../../components/global/createContentStatus";
import RecorderLayout from "../../components/layout/recorderLayout";
import OnboardingModal from "../../components/modal/onboardingModal";
import { DispatchContext, StateContext } from "../../context/appContext";
import PreRecorderView from "./preRecorderView";
import Recorder from "./recorder";
const RecorderPage = ({ destination }) => {
  const [step, setStep] = useState(0);
  const { type } = useParams();
  const [data, setData] = useState({});
  const dispatch = useContext(DispatchContext);
  const { signedIn } = useAuth();
  console.log(destination);
  useEffect(() => {
    dispatch({ type: "updateRecordingStep", payload: 2 });
    dispatch({ type: "updateOnboardingLocation", payload: "prerecording" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  function renderStep(step) {
    switch (step) {
      case 0:
        return <PreRecorderView setStep={(e) => setStep(e)} />;
      case 1:
        return (
          <Recorder
            type={type}
            data={data}
            setData={(e) => setData(e)}
            setStep={(e) => setStep(e)}
          />
        );

      default:
        break;
    }
  }
  const state = useContext(StateContext);
  return (
    <RecorderLayout>
      {signedIn && !state?.userData?.onboarding && <OnboardingModal />}
      <CreateContentStatus />
      {renderStep(step)}
    </RecorderLayout>
  );
};

export default RecorderPage;
