import { useApolloClient } from "@apollo/client";
import { useAuth } from "@nhost/react-auth";
import React, { useContext, useEffect, useState } from "react";
import { storage, auth } from "../../util/nhost";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CREATE_REPLY_PUBLIC } from "../../util/insertQueries";
import DataLoading from "../../components/global/dataLoading";
import { idGenerator } from "../../components/global/idGenerator";
import PublishCoverDisplay from "../../components/global/publishCoverDisplay";
import MainLayout from "../../components/layout/mainLayout";
import { DispatchContext, StateContext } from "../../context/appContext";
import CreateContentStatus from "../../components/global/createContentStatus";
import ReactGA from "react-ga4";

const ReplyTopic = ({ videoid }) => {
  const { type } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const client = useApolloClient();
  const { signedIn } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const state = useContext(StateContext);
  const { id } = useParams();
  const [uploadProgress, setUploadProgress] = useState(0);

  function onUploadProgress(e) {
    console.log(e.loaded / e.total);
    setUploadProgress(e.loaded / e.total);
  }
  console.log(state.media);
  const handleSubmitResponsePublic = async (e) => {
    setSubmitting(true);
    let videoKey;
    let thumbnailKey;
    try {
      await storage
        .put(
          `/public/${auth.getClaim("x-hasura-user-id")}/video/${
            Date.now() + "-" + idGenerator(5)
          }`,
          state.media.blob,
          null,
          onUploadProgress
        )
        .then((vidKey) => (videoKey = vidKey.key))
        .then(
          () =>
            type === "video" &&
            storage.put(
              `/public/${auth.getClaim("x-hasura-user-id")}/thumbnail/${
                Date.now() + "-" + idGenerator(5)
              }`,
              state.media.coverPhoto,
              onUploadProgress
            )
        )
        .then((thumb) => (thumbnailKey = thumb.key))
        .then(() =>
          client.mutate({
            variables: {
              object_response: {
                name: signedIn ? auth.currentUser?.display_name : name,
                email: signedIn ? auth.currentUser?.email : email,
                duration: state.media.duration,
                video_key: videoKey,
                thumbnail_key: thumbnailKey,
                main_video_access_key: id,
                desc: "",
                verified_email: signedIn ? true : false,
                content_type: state.media.type,
              },
              object_billing: {
                access_key: id,
                duration: state.media.duration,
                type: 1,
                user_id: signedIn ? auth.currentUser?.email : email,
              },
            },
            mutation: CREATE_REPLY_PUBLIC,
            context: {
              headers: {
                "x-hasura-role": signedIn ? "user" : "public",
                "x-hasura-user-email": signedIn
                  ? auth.currentUser?.email
                  : email,
              },
            },
          })
        )
        .then((res) => {
          console.log(res);
          ReactGA.event({
            category: "reply",
            action: "reply_recorded ",
          });
          setSubmitted(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log({ error });
    }
  };
  const navigate = useNavigate();
  const dispatch = useContext(DispatchContext);
  const [finishStatus, setfinishStatus] = useState(false);

  useEffect(() => {
    state.media === null && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    dispatch({ type: "updateRecordingStep", payload: 3 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Changes that you made may not be saved.")) {
        setfinishStatus(true);
        navigate(`/create/${type}`);
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  return (
    <MainLayout>
      <CreateContentStatus />
      <div className="hero  bg-base-200"></div>
      {submitted ? (
        signedIn ? (
          <div className=" w-full  max-w-3xl flex flex-col space-y-4 items-center py-10  ">
            <div className="hero-content text-center">
              <div className="max-w-2xl">
                <h1 className="text-5xl font-bold">All set! </h1>

                <p className="py-6">Your Reply is now published!</p>
              </div>
            </div>

            <Link
              to={`/topic/${id}`}
              className="w-full flex flex-col items-center ">
              <button className="  btn  max-w-sm w-full ">
                Go to the topic
              </button>
            </Link>
            <Link to={`/`} className="w-full flex flex-col items-center ">
              <button className="  btn  max-w-sm w-full btn-outline ">
                Go to main page
              </button>
            </Link>
          </div>
        ) : (
          <div className=" w-full  max-w-3xl flex flex-col space-y-4 items-center py-10  ">
            <div className="hero-content text-center">
              <div className="max-w-2xl">
                <h1 className="text-5xl font-bold">We received your reply</h1>
                <h2 className="text-5xl font-bold">One more step</h2>
                <p className="py-6">
                  We sent you an email! Please confirm the posting of your video
                  by clicking on a link in that email.
                </p>
              </div>
            </div>

            <Link
              to={`/topic/${id}`}
              className="w-full flex flex-col items-center ">
              <button className="  btn  max-w-sm w-full ">
                Go to main topic
              </button>
            </Link>
          </div>
        )
      ) : (
        <div className=" flex flex-col w-full">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold">Publish your Reply</h1>
              <p className="py-6">
                By hitting Publish your Reply will be visible to the Topic
                creator.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4  w-full justify-center   space-y-4   items-center md:items-start">
            <PublishCoverDisplay type={type} />

            <div className="flex flex-col space-y-4  w-full max-w-sm ml-10">
              {!signedIn && (
                <div className="flex flex-col space-y-4 w-full ">
                  <div className=" flex flex-col  space-y-1">
                    <label className="label">
                      <span className="label-text">First Name</span>
                    </label>{" "}
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="input input-bordered"
                      required
                    />
                  </div>
                  <div className=" flex flex-col  space-y-1">
                    <label className="label">
                      <span className="label-text">Email</span>
                    </label>{" "}
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input input-bordered"
                      required
                    />
                  </div>
                </div>
              )}

              {submitting ? (
                <div className="flex flex-col items-center space-y-2">
                  <DataLoading />
                  <progress
                    class="progress progress-primary w-56"
                    value={uploadProgress}
                    max="1"></progress>
                </div>
              ) : (
                <div className="flex flex-row w-full max-w-lg justify-end items-center ">
                  <button
                    onClick={() => handleSubmitResponsePublic()}
                    className="btn w-full ">
                    Publish
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default ReplyTopic;
