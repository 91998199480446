import React, { useEffect } from "react";
import RTCMediaDetectorForAudio from "../../components/global/rtcMediaDetectorForAudio";
import RecordingStatus from "./recordingStatus";

const ScreenRecording = ({ recording, setReadyToRecord, checkingMedia }) => {
  useEffect(() => {
    setReadyToRecord(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {checkingMedia && (
        <RTCMediaDetectorForAudio
          setMediaWarn={(e) => console.log(e)}
          resetRecorder={(e) => console.log("reset RTC")}
        />
      )}
      <div className=" flex flex-col relative">
        {recording && <RecordingStatus />}
        <img
          src="https://mk0droplrg5q83m5xg0r.kinstacdn.com/wp-content/uploads/2021/01/movie-production-crew-banner-cartoon-people-with-giant-cinema-vector-id1198834011-768x532.jpg"
          alt=""
          className=" rounded-2xl drop-shadow-lg"
        />
      </div>
    </div>
  );
};

export default ScreenRecording;
