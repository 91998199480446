import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { recordingTypes } from "./recordingTypes";
import _ from "lodash";
import { DispatchContext } from "../../context/appContext";
const PreRecorderView = ({ setStep }) => {
  const dispatch = useContext(DispatchContext);
  const { type } = useParams();
  const handleStart = () => {
    setStep(1);
    dispatch({ type: "updateOnboardingLocation", payload: "recording" });
  };

  const recordingType = _.find(recordingTypes, { type: type });
  const navigate = useNavigate();
  return (
    <>
      <div className=" w-full items-start p-4">
        <button
          onClick={() => navigate(-1)}
          className=" btn btn-outline btn-info">
          Back to type selection
        </button>
      </div>
      <div className="hero bg-base-200">
        <div className="hero-content flex-col lg:flex-row">
          <img
            alt="videobit"
            src={recordingType.img}
            className=" w-full sm:max-w-sm rounded-lg shadow-2xl"
          />
          <div>
            <h1 className="text-5xl font-bold">{recordingType.title}</h1>
            <p className="py-6">{recordingType.startHelper}</p>
            <button className="btn btn-primary" onClick={() => handleStart()}>
              Ready to start ?
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreRecorderView;
