import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { validateEmail } from "../components/global/validateEmail";
import { wrongEmail } from "../components/global/wrongEmail";
import MainLayout from "../components/layout/mainLayout";
import {
  CHECK_REPLY_USER_AVATAR,
  GET_ADDRESS_BOOK_GROUPS,
  GET_MY_ADDRESS_BOOK,
} from "../util/getQueries";
import {
  ADD_CONTACT_TO_GROUP,
  CREATE_NEW_ADDRESS_BOOK_GROUP,
  CREATE_NEW_ADDRESS_BOOK_RECORD,
  DELETE_ADDRESS_BOOK_BY_ID,
  SEND_INVITE_FROM_ADDRESSBOOK,
} from "../util/insertQueries";
import { auth } from "../util/nhost";
import _ from "lodash";
import UserAvatarItem from "../components/navigation/userAvatarItem";

const AddressBookPage = () => {
  const client = useApolloClient();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [offset, setOffset] = useState(0);
  const handleAddNewToAddressBook = () => {
    client
      .mutate({
        variables: {
          object: {
            name: name,
            email: email,
            user_id: auth.getClaim("x-hasura-user-id"),
          },
        },
        mutation: CREATE_NEW_ADDRESS_BOOK_RECORD,
      })
      .then((res) => {
        setName("");
        setEmail("");
        getMyAddressBook();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleDeleteAddressBook = (id) => {
    client
      .mutate({
        variables: {
          id: id,
        },
        mutation: DELETE_ADDRESS_BOOK_BY_ID,
      })
      .then((res) => {
        getMyAddressBook();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  // eslint-disable-next-line no-unused-vars
  const handleInviteUserToVideoBit = (id) => {
    client
      .mutate({
        variables: {
          id: id,
        },
        mutation: SEND_INVITE_FROM_ADDRESSBOOK,
      })
      .then((res) => {
        getMyAddressBook();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const [data, setData] = useState([]);
  const [totalRecord, setTotalRecord] = useState([]);
  const getMyAddressBook = async (e) => {
    console.log(selectedGroup);
    let arr = [];
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_ADDRESS_BOOK,
        fetchPolicy: "no-cache",
        variables: {
          offset: offset,
          limit: 10,
          group_id: selectedGroup === null ? {} : { _eq: selectedGroup },
        },

        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setData(data.address_book);
        for (
          let index = 0;
          index < Math.ceil(data.address_book_aggregate.aggregate.count / 10);
          index++
        ) {
          arr.push(index);
        }
        setTotalRecord(arr);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  const [addressBookGroup, setAddressBookGroup] = useState([]);

  const getMyAddressBookGroups = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_ADDRESS_BOOK_GROUPS,
        fetchPolicy: "no-cache",

        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        console.log(data);
        setAddressBookGroup(data.address_book_group);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getMyAddressBook();
    getMyAddressBookGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const [createNewGroup, setCreateNewGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showListOfGroup, setShowListOfGroup] = useState(false);
  const handleSelectGroup = (e) => {
    setSelectedGroup(e);
    getMyAddressBook(e);
  };
  const handleCreateNewGroup = () => {
    client
      .mutate({
        variables: {
          object: {
            name: newGroupName,
            user_id: auth.getClaim("x-hasura-user-id"),
          },
        },
        mutation: CREATE_NEW_ADDRESS_BOOK_GROUP,
      })
      .then((res) => {
        setCreateNewGroup(false);
        setNewGroupName("");
        getMyAddressBook();
        getMyAddressBookGroups();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleAddContactToGroup = (contact, group) => {
    setShowListOfGroup(false);
    console.log(contact, group);
    client
      .mutate({
        variables: {
          id: contact,
          group_id: group,
        },
        mutation: ADD_CONTACT_TO_GROUP,
      })
      .then((res) => {
        getMyAddressBook();
        getMyAddressBookGroups();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainLayout>
      <div className=" flex flex-col w-full space-y-4">
        <span className="  text-5xl font-bold py-4 md:py-0 ">
          My address book
        </span>
        <br />
        <div className=" flex flex-row items-end flex-wrap space-x-2">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Name</span>
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="name"
              className="input input-bordered "
              required
            />
          </div>
          <div className="form-control">
            <label className="label">
              {wrongEmail(email)}
              <span className="label-text">Email</span>
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="email"
              className="input input-bordered"
              required
            />
          </div>
          {name !== "" && validateEmail(email) && (
            <button
              className=" btn btn-primary"
              onClick={() => handleAddNewToAddressBook()}>
              Save
            </button>
          )}
        </div>

        {_.isEmpty(data) ? (
          <div>
            <span>There is no contact in your address book</span>
          </div>
        ) : (
          <div className=" flex flex-row w-full items-start space-x-2">
            {/* <div className="  space-y-4 ">
              <div>
                {!createNewGroup ? (
                  <button
                    className=" btn btn-primary w-full"
                    onClick={() => setCreateNewGroup(true)}>
                    New group
                  </button>
                ) : (
                  <div className=" flex flex-col space-y-2">
                    <input
                      type="text"
                      placeholder="Group name"
                      className="input input-bordered"
                      required
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                    />
                    <div className="  flex-1 flex-row space-x-1 w-full">
                      {" "}
                      <button
                        onClick={() => handleCreateNewGroup()}
                        className={`${
                          newGroupName === ""
                            ? "btn btn-disabled btn-sm "
                            : "btn btn-secondary btn-sm "
                        }
                        `}>
                        Save
                      </button>
                      <button
                        className={"btn btn-outline btn-sm "}
                        onClick={() => {
                          setCreateNewGroup(false);
                          setNewGroupName("");
                        }}>
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <ul className="menu bg-base-100  w-56 p-2 rounded-box">
                <li className="menu-title">
                  <span>Contact groups</span>
                </li>

                <li>
                  {!_.isEmpty(addressBookGroup) && (
                    <button
                      onClick={() => handleSelectGroup(null)}
                      className={`   ${
                        selectedGroup === null && " text-white  bg-secondary"
                      } `}>
                      All Contacts
                    </button>
                  )}
                </li>
                {addressBookGroup.map((item) => (
                  <li key={item.id} className=" flex flex-row justify-between">
                    <button
                      onClick={() => handleSelectGroup(item.id)}
                      className={`   ${
                        item.id === selectedGroup && " text-white  bg-secondary"
                      }   `}>
                      <span>{item.name} </span>{" "}
                      <div className="indicator-item badge  badge-info">
                        {item.address_books_aggregate.aggregate.count}
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div> */}
            <div className=" flex flex-col items-center w-full   h-96 space-y-6">
              <div className="overflow-x-auto w-full  h-full rounded-xl border-gray-300 border-2">
                <table
                  style={{ borderRadius: "30px" }}
                  className="table   bg-white  w-full ">
                  <thead className="">
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="  ">
                          {" "}
                          <AvatarDisplay email={item.email} />
                        </td>
                        <th className="   w-2/5    align-middle content-center">
                          {" "}
                          {item.name}
                        </th>
                        <td className="   w-2/5"> {item.email}</td>
                        <td>
                          {" "}
                          <div className=" flex flex-row space-x-2 items-center">
                            {/* {item.group_id === null ? (
                              <div className=" dropdown">
                                <button
                                  className=" btn  btn-sm"
                                  onClick={() => setShowListOfGroup(true)}>
                                  add to group
                                </button>
                                <ul
                                  className={`dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 h-44 overflow-y-auto ${
                                    showListOfGroup && "dropdown-open"
                                  }`}>
                                  {addressBookGroup.map((group) => (
                                    <li key={group.id}>
                                      <button
                                        onClick={() =>
                                          handleAddContactToGroup(
                                            item.id,
                                            group.id
                                          )
                                        }>
                                        {group.name}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <span className=" badge badge-primary">
                                {item?.address_book_group?.name}
                              </span>
                            )} */}
                            <button
                              className=" btn btn-sm"
                              onClick={() => handleDeleteAddressBook(item.id)}>
                              delete
                            </button>
                            {item?.existing_user === null && (
                              <div>
                                {item.invite_sent ? (
                                  <div className=" badge  bg-pink-400 border-0 cursor-default">
                                    Invited
                                  </div>
                                ) : (
                                  <button
                                    className=" btn btn-sm btn-outline"
                                    onClick={() =>
                                      handleInviteUserToVideoBit(item.id)
                                    }>
                                    Invite to VideoBit
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="btn-group">
                {totalRecord?.map((item) => (
                  <button
                    key={item}
                    className={`btn ${offset === item * 10 && " btn-active"}`}
                    onClick={() => setOffset(item * 10)}>
                    {item + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default AddressBookPage;

const AvatarDisplay = (email) => {
  const client = useApolloClient();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const getReplyUserAvatar = async () => {
    if (email !== null) {
      try {
        const { loading, error, data } = await client.query({
          query: CHECK_REPLY_USER_AVATAR,
          fetchPolicy: "no-cache",
          variables: {
            _ilike: email?.email,
          },
          context: {
            headers: {
              "x-hasura-role": "user",
            },
          },
        });

        if (!loading && !error && data) {
          console.log(data);
          setAvatarUrl(_.head(data.users)?.avatar_url);
        }
      } catch (error) {
        console.log(error);

        /// error message need notification
      }
    }
  };
  useEffect(() => {
    email !== null && avatarUrl === null && getReplyUserAvatar();
  }, [email]);

  return <UserAvatarItem url={avatarUrl} />;
};
