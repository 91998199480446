import React, { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";
import "./audioPlayer.css";
const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#d8d4d4",
  progressColor: "OrangeRed",
  cursorColor: "OrangeRed",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 100,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

export default function AudioPlayer({ src }) {
  return (
    <div className=" flex flex-col w-full">
      <Waveform url={src} />
    </div>
  );
}

function Waveform({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.7);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });
    return () => wavesurfer.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const handlePlayPause = () => {
    wavesurfer.current.on("play", () => {
      console.log(wavesurfer.current.isPlaying());
    });

    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  useEffect(() => {
    wavesurfer.current.on("finish", () => {
      setPlay(false);
    });
  }, [wavesurfer]);
  return (
    <div className="drop-shadow-lg border-4 border-primary  rounded-2xl">
      <div className=" bg-base-100   border-b rounded-t-xl py-5   ">
        <div id="waveform" ref={waveformRef} />
        {/* <div className="controls">
          <button onClick={handlePlayPause} className="text-white">
            {!playing ? "Play" : "Pause"}
          </button>
        </div> */}
      </div>
      <div className=" bg-primary  text-slate-500  rounded-b-xl flex items-center">
        <button
          onClick={() => handlePlayPause()}
          type="button"
          className="bg-white text-slate-900 dark:bg-slate-100 dark:text-slate-700 flex-none -my-2 mx-auto w-20 h-20 rounded-full ring- ring-slate-900/5 shadow-md flex items-center justify-center"
          aria-label="Pause">
          {playing ? (
            <svg width="30" height="32" fill="currentColor">
              <rect x="6" y="4" width="4" height="24" rx="2" />
              <rect x="20" y="4" width="4" height="24" rx="2" />
            </svg>
          ) : (
            <svg
              width="27"
              height="32"
              viewBox="0 0 28 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.25725 0.571254C1.10278 0.478574 0.910405 0.476147 0.753651 0.5649C0.596896 0.653653 0.5 0.819864 0.5 1V31C0.5 31.1801 0.596896 31.3463 0.753651 31.4351C0.910405 31.5239 1.10278 31.5214 1.25725 31.4287L26.2572 16.4287C26.4079 16.3384 26.5 16.1756 26.5 16C26.5 15.8244 26.4079 15.6616 26.2572 15.5713L1.25725 0.571254Z"
                fill="#797777"
                stroke="black"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}
