import { useApolloClient } from "@apollo/client";
import { useAuth } from "@nhost/react-auth";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { auth } from "../util/nhost";
import { CHECK_USER_CONSENT_STATUS } from "../util/getQueries";
import { APPROVE_SIGNUP_BY_TEACHER } from "../util/insertQueries";
import DataLoading from "../components/global/dataLoading";
import BottomNotification from "../components/notification/bottomNotification";
import MainLayout from "../components/layout/mainLayout";
import GetUserIP from "../hooks/getUserIP";
const SignupReviewAndParentApprovalPage = () => {
  const { id } = useParams();
  const client = useApolloClient();
  // eslint-disable-next-line no-unused-vars
  const [parentEmail, setParentEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [exisiting, setExisting] = useState(false);
  const { signedIn } = useAuth();
  const [rnd, setRnd] = useState();
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState("alert-info");
  console.log(auth);
  console.log(signedIn);
  const parent_ip = GetUserIP();

  const getAllPublicVideos = async (id) => {
    try {
      const { loading, error, data } = await client.query({
        query: CHECK_USER_CONSENT_STATUS,
        variables: {
          user_id: id,
        },

        context: {
          headers: {
            "x-hasura-role": signedIn ? "user" : "public",
          },
        },
        // variables: {
        //   topic: selectedTopic === "All" ? "%%" : selectedTopic,
        //   title: searchValue === "" ? "%%" : `%${searchValue}%`,
        // },
      });

      if (!loading && !error && data) {
        if (data.parent_approval_by_pk === null) {
          setExisting(false);
          setLoading(false);
        } else {
          setExisting(true);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getAllPublicVideos(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, signedIn]);
  const approveSignupByParent = async () => {
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    client
      .mutate({
        variables: {
          parent_email: signedIn ? auth.currentUser.email : parentEmail,
          user_id: id,
          parent_ip: parent_ip,
        },
        mutation: APPROVE_SIGNUP_BY_TEACHER,
        context: {
          headers: {
            "x-hasura-role": signedIn ? "user" : "public",
            "x-hasura-email": signedIn ? auth.currentUser.email : parentEmail,
          },
        },
      })

      .then((res) => {
        setNotificationType("alert-info");
        setNotification(
          "Your child now has access and can start learning with VideoBit."
        );

        setTimeout(() => {
          window.open("https://videobit.io", "_self");
        }, 2000);
      })
      .catch((error) => {
        setNotificationType(" alert-info");
        setNotification(
          "There is Error. Make sure you use same email address you got consent email."
        );
      });
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.logout().then(() => navigate("/login"));
  };
  return (
    <div className="">
      {loading ? (
        <div className=" flex flex-col h-full py-10 ">
          <DataLoading />
        </div>
      ) : exisiting ? (
        <div className="flex flex-col space-y-4">
          <span className="  font-light text-lg ">
            This link is not valid or the account is already approved.
          </span>

          <button
            onClick={() => window.open("https://videobit.io", "_self")}
            className={`w-full
                   btn
              }`}>
            Go to videobit.io{" "}
          </button>
        </div>
      ) : (
        <MainLayout className="flex flex-col space-y-4 ">
          <span className="  max-w-lg  bg-base-100 shadow p-4 text-lg font-light  rounded-md ">
            We believe VideoBit will be a great addition to your child's
            learning journey, so we're asking for your permission for your child
            to use VideoBit. If you’re logged out, then first please enter your
            email that your child entered for this parent’s consent. If you’re
            logged in, then just click on the confirmation button below. By
            permitting your child to access VideoBit, you also confirm that you
            have read and agree with VideoBit's{" "}
            <a
              href="https://videobit.io/privacy"
              target="_blank"
              className="btn-link"
              rel="noreferrer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://videobit.io/terms"
              target="_blank"
              className="btn-link"
              rel="noreferrer">
              Term of Services
            </a>
            . If you don't agree to provide consent for your child to have an
            account in VideoBit, then you can just ignore or close this page.
          </span>
          {signedIn ? (
            <div>
              <span className=" p-2  bg-base-300 shadow-sm py-2  rounded-md  font-light text-lg  ">
                You are signed in as {auth.currentUser.email}. If you want to
                use a different email for child account confirmation, then{" "}
                <span
                  className="btn-link"
                  onClick={() => {
                    handleLogout();
                  }}>
                  {" "}
                  log out
                </span>{" "}
                first.
              </span>
            </div>
          ) : (
            <div className=" flex flex-col  space-y-1 max-w-sm w-full pb-6">
              <span className=" text-sm">Email</span>{" "}
              <input
                type="text"
                className="input input-bordered"
                value={parentEmail}
                onChange={(e) => setParentEmail(e.target.value)}
              />{" "}
            </div>
          )}
          {
            <button
              disabled={!signedIn && parentEmail === ""}
              onClick={() => approveSignupByParent()}
              className={`w-full max-w-lg ${
                !signedIn && parentEmail === ""
                  ? " btn btn-disabled"
                  : "btn btn-primary"
              }`}>
              Give consent{" "}
            </button>
          }
        </MainLayout>
      )}

      <BottomNotification
        type={notificationType}
        message={notification}
        rnd={rnd}
      />
    </div>
  );
};

export default SignupReviewAndParentApprovalPage;
