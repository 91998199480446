import { useAuth } from "@nhost/react-auth";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DispatchContext } from "../../context/appContext";
import { useCheckUserData } from "../../hooks/useCheckUserData";
import GlobalSearch from "../modal/globalSearch";
import DarkModeSelector from "./darkModeSelector";
import Profile from "./profile";
const MainTopNavbar = ({ onboarding }) => {
  const { signedIn } = useAuth();
  useCheckUserData();
  const navigate = useNavigate();
  const dispatch = useContext(DispatchContext);

  return (
    <div
      className={` z-30  fixed flex  top-0 flex-row  bg-base-200 max-w-5xl w-full justify-between p-2 py-4  ${
        signedIn && " border-b-2"
      }   items-center`}>
      <Link
        to="/"
        className=" text-3xl font-bold cursor-pointer flex flex-row items-end">
        <span className="  text-primary">V</span>
        <span className=" hidden sm:flex  text-primary">ideo</span>
        <span className=" text-secondary">B</span>
        <span className=" text-secondary hidden sm:flex">it</span>
      </Link>
      <div className=" flex flex-row items-center space-x-2">
        {signedIn && !onboarding && (
          <button
            onClick={() => {
              navigate("/create");
              dispatch({ type: "updateOnboardingLocation", payload: "create" });
            }}
            className=" btn btn-secondary">
            Create topic
          </button>
        )}
        {signedIn && !onboarding && <GlobalSearch />}
        <DarkModeSelector />
        {signedIn && <Profile />}
      </div>
    </div>
  );
};

export default MainTopNavbar;
