import { validateEmail } from "./validateEmail";

export const wrongEmail = (email) => {
  if (email.includes("@")) {
    if (!validateEmail(email)) {
      return (
        <label className="label">
          <span className="label-text text-red-500">Wrong email</span>
        </label>
      );
    }
  }
};
