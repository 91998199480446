import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { SMART_INDICATOR_DATA } from "../../util/getQueries";
import ItemHelp from "./itemHelp";

const SmartIndicator = ({ email }) => {
  const client = useApolloClient();
  const [indicator, setIndicator] = useState("strong");
  const getSmartIndicator = async () => {
    console.log(email);
    try {
      const { loading, error, data } = await client.query({
        query: SMART_INDICATOR_DATA,
        fetchPolicy: "no-cache",
        variables: {
          email: email,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        console.log(data);
        if (
          data.response_feedback_aggregate.aggregate.count >
          data.smart_indicator_rate_aggregate.aggregate.stddev.feedback_count /
            2
        ) {
          setIndicator("strong");
        } else {
          setIndicator("normal");
        }
      }
    } catch (error) {
      console.log(error);

      /// error message need notification
    }
  };
  useEffect(() => {
    email && getSmartIndicator();
  }, [email]);
  function renderType(type) {
    switch (type) {
      case "weak":
        return (
          <div className=" relative text-3xl font-bold  w-32 h-12 flex flex-col items-center justify-center  rounded-md bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500">
            <span className=" text-white"> C</span>
            <span className=" text-xs absolute -bottom-1  right-0 flex flex-row items-center  ">
              <span className="text-white cursor-default">SI</span>{" "}
              <ItemHelp text="VideoBit Smart indicator help you to give feedback to reply based on their recent acitivities and feedbacks." />
            </span>
          </div>
        );
      case "normal":
        return (
          <div className=" relative text-3xl font-bold   w-32 h-12 flex flex-col items-center justify-center  rounded-md bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            <span className=" text-white"> B</span>
            <span className=" text-xs absolute -bottom-1  right-0 flex flex-row items-center  ">
              <span className="text-white cursor-default">SI</span>{" "}
              <ItemHelp text="VideoBit Smart indicator help you to give feedback to reply based on their recent acitivities and feedbacks." />
            </span>
          </div>
        );
      case "strong":
        return (
          <div className=" relative text-3xl font-bold w-32 h-12 flex flex-col items-center justify-center  rounded-md bg-gradient-to-r from-blue-500 via-green-500 to-green-600">
            <span className=" text-white"> A</span>
            <span className=" text-xs absolute -bottom-1  right-0 flex flex-row items-center  ">
              <span className="text-white cursor-default">SI</span>{" "}
              <ItemHelp text="VideoBit Smart indicator help you to give feedback to reply based on their recent acitivities and feedbacks." />
            </span>
          </div>
        );

      default:
        break;
    }
  }
  return <div>{renderType(indicator)}</div>;
};

export default SmartIndicator;
