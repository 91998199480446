import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth } from "../util/nhost";
import MainLayout from "../components/layout/mainLayout";
import AuthWrapper from "./authWrapper";
import BottomNotification from "../components/notification/bottomNotification";
import { useNavigate, useLocation } from "react-router";
import ScreenLoading from "../components/global/screenLoading";
import { wrongEmail } from "../components/global/wrongEmail";
import { validateEmail } from "../components/global/validateEmail";
import { useAuth } from "@nhost/react-auth";
import ReactGA from "react-ga4";

const LoginPage = () => {
  // const { signedIn } = useAuth();
  // useEffect(() => {
  //   signedIn && navigate("/");
  // }, [signedIn]);

  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rnd, setRnd] = useState();
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState("alert-info");
  const [loading, setLoading] = useState(false);
  const handleLogin = async () => {
    setLoading(true);
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    try {
      await auth.login({ email, password });
    } catch (error) {
      ReactGA.event({
        category: "login",
        action: "login_failed",
      });
      setLoading(false);
      setNotificationType("alert-error");
      return setNotification(error.response.data.message);
    }
    setTimeout(() => {
      ReactGA.event({
        category: "login",
        action: "Logged_in",
      });
      setLoading(false);
      setNotificationType("alert-info");
      setNotification("You are logged in successfully");
      setTimeout(() => {
        navigate(
          location?.state?.from?.pathname
            ? location?.state?.from?.pathname
            : "/"
        );
      }, 300);
    }, 500);
  };
  return (
    <MainLayout>
      <AuthWrapper
        header="log in"
        desc="Welcome back! Please log in to access your VideoBit account."
        footer={
          <span>
            Don't have an account?{" "}
            <Link to="/register" className="link link-primary">
              Register now
            </Link>
          </span>
        }>
        <div className="card-body">
          <div className="form-control">
            <label className="label">
              {wrongEmail(email)}
              <span className="label-text">Email</span>
            </label>
            <input
              type="text"
              placeholder="email"
              className="input input-bordered"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              placeholder="password"
              className="input input-bordered"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className="label">
              <Link to="/reset" className="label-text-alt link link-hover">
                Forgot password?
              </Link>
            </label>
          </div>
          <div className="form-control mt-6">
            <button
              disabled={!validateEmail(email) && password !== ""}
              onClick={() => handleLogin()}
              className={
                validateEmail(email) && password !== ""
                  ? "btn btn-primary"
                  : "btn btn-disabled"
              }>
              Log in
            </button>
          </div>
          <div className="form-control mt-2">
            <span className="   font-bold text-center label-text">Or</span>
          </div>
          <div className="form-control mt-2">
            <button
              onClick={() => auth.login({ provider: "google" })}
              className="btn text-gray-600 border-0 bg-gray-100 hover:bg-gray-200 flex flex-row w-full  space-x-4 items-center ">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg">
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path
                    fill="#4285F4"
                    d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                  />
                  <path
                    fill="#34A853"
                    d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                  />
                  <path
                    fill="#EA4335"
                    d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                  />
                </g>
              </svg>
              <span>Sign in with Google</span>
            </button>
          </div>
        </div>
      </AuthWrapper>

      <ScreenLoading open={loading} />
      <BottomNotification
        type={notificationType}
        message={notification}
        rnd={rnd}
      />
    </MainLayout>
  );
};

export default LoginPage;
