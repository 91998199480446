import React from "react";

const MainTopicStat = ({ views, replies, privateComm, student2Student }) => {
  return (
    <div className="stats md:stats-vertical stats-horizontal shadow overflow-hidden">
      <div className="stat">
        <div className=" stat-value text-xl text-primary">
          {privateComm ? "Private" : "Open to all"}
        </div>
        {/* <div className="stat-desc">21% more than last month</div> */}
      </div>
      {!privateComm && student2Student && (
        <div className="stat">
          <div className=" stat-title text-primary">Every one can comment</div>
          {/* <div className="stat-desc">21% more than last month</div> */}
        </div>
      )}

      <div className="stat">
        <div className="stat-figure text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-8 h-8 stroke-current">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"></path>
          </svg>
        </div>
        <div className="stat-title">Views</div>
        <div className="stat-value text-secondary">{views}</div>
        {/* <div className="stat-desc">21% more than last month</div> */}
      </div>

      <div className="stat">
        <div className="stat-title">Replies</div>
        <div className="stat-value ">{replies}</div>
      </div>
    </div>
  );
};

export default MainTopicStat;
