// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import useCountDown from "react-countdown-hook";
import "react-html5video/dist/styles.css";
import "./player.css";
import ScreenRecording from "./screenRecording";
import VideoRecording from "./videoRecording";
import AudioRecording from "./audioRecording";
import RecordingButton from "./recordingButton";
import { DispatchContext, StateContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import PreviewPlayer from "../../components/global/previewPlayer";
const initialTime = 60 * 1000;
const interval = 1000;
const Recorder = ({ type, data, setData, setStep }) => {
  const [timeLeft, actions] = useCountDown(initialTime, interval);
  const [duration, setDuration] = useState(0);
  const state = useContext(StateContext);

  const { status, startRecording, stopRecording, clearBlobUrl, mediaBlobUrl } =
    useReactMediaRecorder({
      screen: type === "screen" && true,
      video: type === "video" && true,
      audio: true,
      blobPropertyBag: {
        type: "video/mp4",
      },
    });
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeout(() => {
        if (recording) {
          handleStopRecording();
        }
      }, 900);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const handleStartRecording = () => {
    startRecording();
  };

  useEffect(() => {
    if (status === "recording") {
      actions.start();
      setRecording(true);
      setRecorded(false);
    }
    if (type !== "video") {
      if (status === "stopped") {
        handleStopRecording();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleStopRecording = async () => {
    setRecorded(true);
    setRecording(false);
    stopRecording();
    console.log(mediaBlobUrl);

    setDuration((initialTime - timeLeft) / 1000);
    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    setData(audioBlob);
    console.log(audioBlob);
    actions.reset();
  };

  const handleStopRecordingVideo = (blob) => {
    setData(blob);
    console.log(blob);
    data && setRecorded(true);
    data && setRecording(false);
  };

  const handleRetakeMedia = () => {
    clearBlobUrl();
    setRecorded(false);
    setRecording(false);
    actions.reset();
  };
  const dispatch = useContext(DispatchContext);
  const navigate = useNavigate();
  function selectPublishRoute(dest) {
    switch (dest) {
      case "topic":
        return `/create/${type}/publish`;

      case "reply":
        return `publish`;
      case "comment":
        return `publish`;

      default:
        break;
    }
  }

  const handleGoToPublish = async () => {
    const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
    dispatch({ type: "updateOnboardingLocation", payload: "publish" });

    dispatch({
      type: "updateRecordedMedia",
      payload: {
        blob: type === "video" ? data : blob,
        type: type,
        coverPhoto: coverPhoto,
        duration: duration,
      },
    });

    navigate(selectPublishRoute(state.recordingDestination));

    console.log(data);
  };

  function recordingView(type) {
    switch (type) {
      case "screen":
        return (
          <ScreenRecording
            checkingMedia={status === "acquiring_media"}
            recording={recording}
            readyToRecord={readyToRecord}
            setReadyToRecord={(e) => setReadyToRecord(e)}
          />
        );

      case "video":
        return (
          <VideoRecording
            setCoverPhoto={(e) => setCoverPhoto(e)}
            recording={recording}
            readyToRecord={readyToRecord}
            setReadyToRecord={(e) => setReadyToRecord(e)}
            startRecording={() => setRecording(true)}
            stopRecording={(blob) => handleStopRecordingVideo(blob)}
            setDuration={(e) => setDuration(e)}
          />
        );

      case "audio":
        return (
          <AudioRecording
            checkingMedia={status === "acquiring_media"}
            recording={recording}
            readyToRecord={readyToRecord}
            setReadyToRecord={(e) => setReadyToRecord(e)}
          />
        );

      default:
        break;
    }
  }
  const [readyToRecord, setReadyToRecord] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recorded, setRecorded] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState(null);
  console.log(status);

  // eslint-disable-next-line no-unused-vars
  const [recorderActive, setRecorderActive] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const resetRecorder = () => {
    setRecorderActive(false);
    setTimeout(() => {
      setRecorderActive(true);
    }, 100);
  };
  useEffect(() => {
    if (recorded) {
      dispatch({ type: "updateOnboardingLocation", payload: "preview" });
    } else {
      dispatch({ type: "updateOnboardingLocation", payload: "recording" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorded]);
  return (
    <div className="   max-w-2xl w-full items-center flex flex-col">
      {recorded ? (
        <div className=" flex flex-col items-center p-2  w-full relative aspect-video   justify-center   ">
          {
            <div className=" bg-base-300 rounded-2xl drop-shadow-lg w-full ">
              <PreviewPlayer
                src={
                  data && type === "video"
                    ? URL.createObjectURL(data)
                    : mediaBlobUrl
                }
                type={type}
              />
            </div>
          }
        </div>
      ) : (
        <div className=" flex flex-col items-center p-2  mt-10  w-full relative aspect-video   justify-center   ">
          {(status === "idle" ||
            status === "recording" ||
            status === "acquiring_media") &&
            recordingView(type)}
        </div>
      )}

      <div className=" flex flex-col space-y-4 items-center ">
        <div>
          {recorded ? (
            <div className=" flex flex-col items-center space-y-4">
              <span className=" py-2 text-2xl">Do you like it ?</span>
              <div className=" flex flex-row space-x-2">
                {" "}
                <button
                  className="btn   btn-circle btn-lg  btn-secondary"
                  onClick={() => handleGoToPublish()}>
                  Yes
                </button>
                <button
                  className="btn btn-circle  btn-lg  btn-primary"
                  onClick={() => handleRetakeMedia()}>
                  No
                </button>
              </div>
            </div>
          ) : (
            <div className=" py-4">
              {type !== "video" && (
                <RecordingButton
                  recording={recording}
                  recordingTimeLeft={timeLeft / 1000}
                  startRecording={() => handleStartRecording()}
                  stopRecording={() => handleStopRecording()}
                />
              )}
            </div>
          )}
        </div>
        <div className=" py-4">
          {" "}
          {timeLeft !== 0 && (
            <span className="countdown font-mono text-6xl scale-150">
              <span style={{ [`--value`]: timeLeft / 1000 }}></span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recorder;
