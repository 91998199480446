import React from "react";
import { Routes, Route } from "react-router-dom";
import AgeDeclare from "../auth/ageDeclare";
import LoginPage from "../auth/loginPage";
import ParentApprovalPending from "../auth/parentApprovalPending";
import ProfilePage from "../auth/profilePage";
import RegisterPage from "../auth/registerPage";
import { RequireAuth } from "../auth/requireAuth";
import ResetPasswordPage from "../auth/resetPassword";
import SignupReviewAndParentApprovalPage from "../auth/signupReviewAndParentApprovalPage";
import UsagePage from "../auth/usagePage";
import AddressBookPage from "../pages/addressBookPage";
import CreateVideoMainPage from "../pages/create";
import ConfirmReplyEmail from "../pages/create/confirmReplyEmail";
import PublishComment from "../pages/create/publishComment";
import PublishTopic from "../pages/create/publishTopic";
import RecorderPage from "../pages/create/recorderPage";
import ReplyTopic from "../pages/create/replyTopic";
import LandingPage from "../pages/landing";
import NotFoundPage from "../pages/landing/notFoundPage";
import OnboardingPage from "../pages/landing/onboardingPage";
import TopicsPage from "../pages/landing/topicsPage";
import MainTopicPlayer from "../pages/mainTopicPlayer";
import PublicTopicPlayer from "../pages/mainTopicPlayer/publicTopicPlayer";
import ResponsePlayer from "../pages/mainTopicPlayer/responsePlayer";
import StatsPage from "./stats";
import StandRegister from "../pages/external/standRegister";

const Router = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<LandingPage />} /> */}

      <Route path="/login" element={<LoginPage />} />
      <Route path="/standregister" element={<StandRegister />} />

      <Route path="/cr/:id" element={<ConfirmReplyEmail />} />

      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset" element={<ResetPasswordPage />} />
      <Route path="/age" element={<AgeDeclare />} />
      <Route path="/stats" element={<StatsPage />} />
      <Route path="/parentapproval" element={<ParentApprovalPending />} />
      <Route
        path="/signupreview/:id"
        element={<SignupReviewAndParentApprovalPage />}
      />

      <Route path="/orders">
        <Route path="" element={<div>Coming soon...</div>} />
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth>
            <LandingPage />
          </RequireAuth>
        }
      />
      <Route
        path="/onboarding"
        element={
          <RequireAuth>
            <OnboardingPage />
          </RequireAuth>
        }
      />
      <Route
        path="/topics"
        element={
          <RequireAuth>
            <TopicsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        }
      />
      <Route
        path="/usage"
        element={
          <RequireAuth>
            <UsagePage />
          </RequireAuth>
        }
      />
      <Route
        path="/addressbook"
        element={
          <RequireAuth>
            <AddressBookPage />
          </RequireAuth>
        }
      />
      <Route path="/a/:id" element={<PublicTopicPlayer />} />
      <Route path="/create">
        <Route
          path=""
          element={
            <RequireAuth>
              <CreateVideoMainPage destination="topic" />
            </RequireAuth>
          }></Route>
        <Route
          path=":type"
          element={
            <RequireAuth>
              <RecorderPage destination="topic" />
            </RequireAuth>
          }></Route>
        <Route
          path=":type/publish"
          element={
            <RequireAuth>
              <PublishTopic />
            </RequireAuth>
          }></Route>
      </Route>
      <Route path="/reply/:id">
        <Route
          path=""
          element={<CreateVideoMainPage destination="reply" />}></Route>
        <Route
          path=":type"
          element={<RecorderPage destination="reply" />}></Route>
        <Route path=":type/publish" element={<ReplyTopic />}></Route>
      </Route>

      <Route path="/comment/:responseid">
        <Route
          path=""
          element={
            <RequireAuth>
              <CreateVideoMainPage destination="comment" />
            </RequireAuth>
          }></Route>
        <Route
          path=":type"
          element={
            <RequireAuth>
              <RecorderPage destination="comment" />
            </RequireAuth>
          }></Route>
        <Route
          path=":type/publish"
          element={
            <RequireAuth>
              <PublishComment />
            </RequireAuth>
          }></Route>
      </Route>

      <Route path="/topic">
        <Route
          path=":id"
          element={
            <RequireAuth>
              <MainTopicPlayer />
            </RequireAuth>
          }></Route>
        <Route
          path=":id/:responseid"
          element={
            <RequireAuth>
              <ResponsePlayer />
            </RequireAuth>
          }></Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
