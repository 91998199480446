import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataLoading from "../../components/global/dataLoading";
import MainLayout from "../../components/layout/mainLayout";
import VideoCoverCard from "../../components/video/videoCoverCard";
import _ from "lodash";
import {
  GET_MY_CATEGORIES,
  GET_MY_INTRACTIONS_VIDEO,
  GET_MY_VIDEOS,
  SUB_TO_MY_VIDEOS,
} from "../../util/getQueries";
import { auth } from "../../util/nhost";
import VideoPlayer from "../../components/global/videoPlayer";
const TopicsPage = () => {
  const [myVideos, setMyVideos] = useState([]);
  const [intractVideos, setIntractVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const client = useApolloClient();
  const [topicViewType, setTopicViewType] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limitData, setLimitData] = useState(1000);

  const getMyVideos = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_VIDEOS,
        variables: {
          _eq: auth.getClaim("x-hasura-user-id"),
          category: `%${selectedCategory}%`,
          limit: limitData,
        },
        fetchPolicy: "cache-first",

        context: {
          headers: {
            "x-hasura-role": "user",
            "X-Hasura-Email": auth.currentUser?.email,
          },
        },
      });

      if (!loading && !error && data) {
        setMyVideos(data.video_table);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };

  const getMyIntractVideos = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_INTRACTIONS_VIDEO,
        fetchPolicy: "no-cache",
        variables: {
          email: auth.currentUser.email,
          limit: limitData,

          user_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setIntractVideos(data.video_table);
        //  console.log(data.video_table);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getMyVideos();
    getMyIntractVideos();
    getMyCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getMyVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    const getlist = client.subscribe({
      query: SUB_TO_MY_VIDEOS,
      variables: {
        _eq: auth.getClaim("x-hasura-user-id"),
        category: `%${selectedCategory}%`,
        limit: limitData,
      },
      fetchPolicy: "no-cache",
    });
    const subscription = getlist.subscribe(
      ({ data }) => {
        setMyVideos(data.video_table);
      },
      (error) => console.log(error)
    );
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const [categories, setCategories] = useState([]);

  const getMyCategories = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_CATEGORIES,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setCategories(data.topic_categories);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };

  return (
    <MainLayout>
      <div className=" flex flex-col  md:flex-row justify-between items-center w-full py-2 pb-6 border-b">
        <span className="  text-5xl font-bold py-4 md:py-0 ">My topics</span>
        <div className=" hidden sm:flex btn-group">
          <button
            className={`btn btn-sm ${topicViewType === "all" && "btn-active"}`}
            onClick={() => setTopicViewType("all")}>
            Show all
          </button>
          <button
            className={`btn btn-sm ${
              topicViewType === "myvideo" && "btn-active"
            }`}
            onClick={() => setTopicViewType("myvideo")}>
            Created By me
          </button>
          <button
            className={`btn btn-sm ${
              topicViewType === "intract" && "btn-active"
            }`}
            onClick={() =>
              !_.isEmpty(intractVideos) && setTopicViewType("intract")
            }>
            I have replied to
          </button>
        </div>
      </div>

      <div className=" flex flex-row flex-wrap  items-start w-full  pt-2">
        <div
          onClick={() => setSelectedCategory("")}
          className={` ${
            selectedCategory === "" && "badge-secondary"
          } badge badge-lg mr-2 mb-2 uppercase  cursor-pointer`}>
          All
        </div>
        {categories.map((cat) => (
          <div
            key={cat.id}
            onClick={() => setSelectedCategory(cat.name)}
            className={` ${
              selectedCategory === cat?.name && "badge-secondary"
            } badge badge-lg mr-2 mb-2 uppercase  cursor-pointer`}>
            {cat.name}
          </div>
        ))}
      </div>

      {(topicViewType === "all" || topicViewType === "myvideo") && (
        <div className=" flex flex-col w-full items-start justify-start px-4 sm:px-0 ">
          {(topicViewType === "all" || topicViewType === "myvideo") &&
            !_.isEmpty(myVideos) && (
              <div className=" section-header ">Created by me</div>
            )}
          {isLoading ? (
            <DataLoading />
          ) : _.isEmpty(myVideos) ? (
            selectedCategory !== "" ? (
              <NoRecordBySelectedTag
                setSelectedCategory={(e) => setSelectedCategory("")}
              />
            ) : (
              <FirstTimeNoVideo />
            )
          ) : (
            <div className=" grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-8">
              {myVideos?.map((item, index) => (
                <Link to={`/topic/${item.access_key}`} key={item.id}>
                  <VideoCoverCard
                    data={item}
                    label={"new replies or comments"}
                    readed={
                      item?.main_video_reads_aggregate?.aggregate?.count > 0 &&
                      _.isEmpty(
                        _(item?.response_tables)
                          .thru(function (coll) {
                            return _.union(
                              coll,
                              _.map(coll, "reply_tables") || []
                            );
                          })
                          .flatten()
                          .find({
                            reply_video_reads_aggregate: {
                              aggregate: { count: 0 },
                            },
                          })
                      ) &&
                      _.isEmpty(
                        _.find(item?.response_tables, function (o) {
                          return o.video_reads_aggregate.aggregate.count === 0;
                        })
                      )
                    }
                  />
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
      {(topicViewType === "all" || topicViewType === "intract") &&
        !_.isEmpty(intractVideos) && (
          <div className=" flex flex-col w-full items-start justify-start px-4">
            <div className=" section-header ">I have replied to</div>
            {isLoading ? (
              <DataLoading />
            ) : (
              <div className=" grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-8">
                {intractVideos?.map((item) => (
                  <Link to={`/topic/${item.access_key}`} key={item.id}>
                    <VideoCoverCard
                      data={item}
                      label={"new replies or comments"}
                      readed={
                        item?.main_video_reads_aggregate?.aggregate?.count >
                          0 &&
                        _.isEmpty(
                          _(item?.response_tables)
                            .thru(function (coll) {
                              return _.union(
                                coll,
                                _.map(coll, "reply_tables") || []
                              );
                            })
                            .flatten()
                            .find({
                              reply_video_reads_aggregate: {
                                aggregate: { count: 0 },
                              },
                            })
                        ) &&
                        _.isEmpty(
                          _.find(item?.response_tables, function (o) {
                            return (
                              o.video_reads_aggregate.aggregate.count === 0
                            );
                          })
                        )
                      }
                    />
                  </Link>
                ))}
              </div>
            )}
          </div>
        )}
    </MainLayout>
  );
};

export default TopicsPage;

const NoRecordBySelectedTag = ({ setSelectedCategory }) => {
  return (
    <div className="hero  py-10">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold ">
            There is nothing in this category, yet
          </h1>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => setSelectedCategory("all")}>
            See all videos
          </button>
        </div>
      </div>
    </div>
  );
};
const FirstTimeNoVideo = () => {
  return (
    <div className="hero   py-10">
      <div className="hero-content text-center">
        <div className="">
          <h1 className="text-5xl font-bold">
            Ready to create your first topic?
          </h1>
          <br />
          <div className=" flex flex-col w-full max-w-3xl relative ">
            <VideoPlayer src="https://www.youtube.com/watch?v=3iQeTaCtgnI" />
          </div>
          <br />
          <Link to="/create" className="btn btn-primary">
            Create topic
          </Link>
        </div>
      </div>
    </div>
  );
};
