import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../../components/global/videoPlayer";
import { DispatchContext } from "../../context/appContext";

const OnboardingPage = () => {
  const dispatch = useContext(DispatchContext);
  const navigate = useNavigate();
  const handleGoToCreate = () => {
    dispatch({ type: "updateOnboardingLocation", payload: "type" });
    navigate("/create");
  };
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row">
        <div className=" flex flex-col w-full max-w-3xl relative ">
          <VideoPlayer src="https://www.youtube.com/watch?v=3iQeTaCtgnI" />
        </div>
        <div>
          <h1 className="text-5xl font-bold">Welcome to </h1>{" "}
          <div className=" flex flex-row items-end text-5xl font-bold">
            <span className=" flex  text-primary">Video</span>

            <span className=" text-secondary flex">Bit</span>
          </div>
          <p className="py-6">
            With VideoBit you can Record short messages with your camera or
            voice. Then, just share it with anyone and keep the conversation
            going.
          </p>
          <button
            className="btn btn-primary"
            onClick={() => handleGoToCreate()}>
            Create your first topic
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
