import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import MainLayout from "../components/layout/mainLayout";
import {
  GET_MY_CURRENT_MONTH_USAGE,
  GET_THIS_MONTH_USAGE_DETAIL,
} from "../util/getQueries";
import { auth } from "../util/nhost";
import _ from "lodash";
import moment from "moment";
import DataLoading from "../components/global/dataLoading";

const UsagePage = () => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(true);
  const [currentMonthUsage, setCurrentMonthUsage] = useState({});
  const getMyCurrentMonthUsage = async () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_CURRENT_MONTH_USAGE,
        fetchPolicy: "no-cache",
        variables: {
          _eq: auth.getClaim("x-hasura-user-id"),
          _gte: firstDay,
          _lte: lastDay,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setCurrentMonthUsage(
          data?.total_video_duration_aggregate?.aggregate?.sum
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getThisMonthUsageDetail = async () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    try {
      const { loading, error, data } = await client.query({
        query: GET_THIS_MONTH_USAGE_DETAIL,
        fetchPolicy: "no-cache",
        variables: {
          _eq: auth.getClaim("x-hasura-user-id"),
          _gte: firstDay,
          _lte: lastDay,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        console.log(data?.total_video_duration);
        console.log(
          _.map(
            _.groupBy(data.total_video_duration, function (item) {
              return moment(item.created_at).startOf("day").format();
            }),
            function (group, day) {
              return {
                day: day,
                data: group,
              };
            }
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getThisMonthUsageDetail();
    getMyCurrentMonthUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainLayout>
      {isLoading ? (
        <DataLoading />
      ) : (
        <div className=" flex flex-col items-start w-full space-y-6">
          <span className="  text-5xl font-bold py-4 md:py-0 ">
            My VideoBit
          </span>

          <span>This month usage</span>
          <span>
            {Math.ceil(
              (currentMonthUsage?.duration +
                currentMonthUsage?.reply +
                currentMonthUsage?.response) /
                60
            )}{" "}
            Minutes
          </span>
          <progress
            className="progress progress-primary w-56 h-6"
            value={Math.ceil(
              (currentMonthUsage?.duration +
                currentMonthUsage?.reply +
                currentMonthUsage?.response) /
                60
            )}
            max="100"></progress>
        </div>
      )}
    </MainLayout>
  );
};

export default UsagePage;
