import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/appContext";

const PublishCoverDisplay = ({ media, type }) => {
  const state = useContext(StateContext);
  const [imageSrc, setImageSrc] = useState(null);
  useEffect(() => {
    switch (type) {
      case "video":
        return (
          state.media !== null &&
          setImageSrc(URL?.createObjectURL(state?.media?.coverPhoto))
        );
      case "audio":
        return setImageSrc(
          "https://y31uv4ra1.vo.llnwd.net/content/wp/tweaklibrary_com/uploads/2020/05/best-Audio-recording-software.jpg"
        );
      case "screen":
        return setImageSrc(
          "https://static.teamviewer.com/resources/2019/04/TeamViewer-Screen-Recorder-laptop-1024x683.jpg"
        );

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, media]);

  return (
    <div>
      {/* <Video
        src={media}
        autoPlay
        mute
        controls={["Seek", "Time", "Volume", "Fullscreen"]}
      /> */}
      {<img src={imageSrc} className=" rounded-xl shadow-md max-w-lg" alt="" />}
    </div>
  );
};

export default PublishCoverDisplay;
