import { gql } from "@apollo/client";

export const GET_SELF_DATA = gql`
  query getSelf($user_id: uuid!) {
    me: users_by_pk(id: $user_id) {
      id
      display_name
      avatar_url
      age_declared
      under_aged
      account {
        id
        email
        account_roles {
          role
        }
      }
    }
    parent_approval(where: { user_id: { _eq: $user_id } }) {
      approved
    }
  }
`;

export const GET_MY_VIDEOS = gql`
  query myVideos($_eq: uuid!, $category: String, $limit: Int!) {
    video_table(
      limit: $limit
      order_by: { created_at: desc }
      where: {
        creator_id: { _eq: $_eq }
        deleted: { _eq: false }
        video_category: { _ilike: $category }
      }
    ) {
      id
      title
      video_key
      created_at
      access_key
      video_public
      thumbnail_key
      video_category
      content_type
      creator_id
      main_video_reads_aggregate {
        aggregate {
          count
        }
      }
      response_tables {
        email
        reply_tables {
          reply_video_reads_aggregate {
            aggregate {
              count
            }
          }
        }
        video_reads_aggregate {
          aggregate {
            count
          }
        }
      }

      response_tables_aggregate {
        aggregate {
          count
        }
      }
      video_counters_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_MY_INTRACTIONS_VIDEO = gql`
  query myVideos($email: String!, $limit: Int!) {
    video_table(
      limit: $limit
      order_by: { created_at: desc }
      where: {
        deleted: { _eq: false }
        _or: [{ response_tables: { email: { _eq: $email } } }]
      }
    ) {
      id
      title
      video_key
      created_at
      access_key
      video_public
      thumbnail_key
      content_type
      creator_id
      main_video_reads_aggregate {
        aggregate {
          count
        }
      }
      response_tables {
        email
        reply_tables {
          reply_video_reads_aggregate {
            aggregate {
              count
            }
          }
        }
        video_reads_aggregate {
          aggregate {
            count
          }
        }
      }
      response_tables_aggregate {
        aggregate {
          count
        }
      }
      video_counters_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_MY_CATEGORIES = gql`
  query MyQuery {
    topic_categories {
      name
      id
    }
  }
`;

export const GET_VIDEOBIT_BY_ACCESSKEY = gql`
  query MyQuery($access_key: String!, $feedback: String) {
    video_table_by_pk(access_key: $access_key) {
      title
      video_key
      thumbnail_key
      created_at
      access_key
      creator_id
      reply_public
      video_public
      converted
      desc
      content_type
      duration
      private_comm
      control_reply
      student_to_student
      pincode
      video_topic {
        slug
        title
      }
      video_category
      main_video_reads_aggregate {
        aggregate {
          count
        }
      }
      video_counters_aggregate {
        aggregate {
          count
        }
      }
      user {
        display_name
      }
      response_tables(order_by: { created_at: desc }) {
        reject_by_creator
        approved_by_creator
        created_at
        verified_email
        id
        video_key
        thumbnail_key
        updated_at
        name
        email
        desc
        content_type
        response_feedbacks {
          feedback_datum {
            slug
          }
        }
        reply_tables(order_by: { created_at: desc }) {
          reply_video_reads_aggregate {
            aggregate {
              count
            }
          }
        }
        video_reads_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_MY_VIDEO_SEARCH = gql`
  query MyQuery($user_id: uuid!, $title: String!) {
    video_table(
      order_by: { created_at: desc }
      where: { creator_id: { _eq: $user_id }, title: { _ilike: $title } }
    ) {
      content_type
      id
      title
      thumbnail_key
      access_key
    }
  }
`;

export const GET_RESPONSE_REPLY = gql`
  query MyQuery($id: uuid!, $user_id: uuid) {
    response_table_by_pk(id: $id) {
      video_table {
        thumbnail_key
        video_key
        title
        access_key
        created_at
        content_type
        duration
        control_reply
        control_comment
        creator_id
        student_to_student
      }
      reply_tables(order_by: { created_at: desc }) {
        approved_by_creator
        duration
        id
        video_key
        thumbnail_key
        created_at
        user_id
        content_type
        user {
          display_name
          avatar_url
        }
        reply_video_reads_aggregate {
          aggregate {
            count
          }
        }
      }
      reject_by_creator
      approved_by_creator
      created_at
      content_type
      id
      email
      name
      desc
      video_key
      thumbnail_key
      duration
      mylike: response_likes_aggregate(
        where: { user_id: { _eq: $user_id }, response_id: { _eq: $id } }
      ) {
        aggregate {
          count
        }
      }
      response_likes_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_VIDEOBIT_CRTEATOR_ID = gql`
  query MyQuery($id: uuid!) {
    response_table_by_pk(id: $id) {
      email
      video_table {
        access_key
        thumbnail_key
        title
        user {
          id
        }
      }
    }
  }
`;

export const GET_VIDEO_BY_LINK_PUBLIC = gql`
  query MyQuery($access_key: String!) {
    video_table_by_pk(access_key: $access_key) {
      title
      video_key
      created_at
      thumbnail_key
      access_key
      content_type
      pincode
      desc
      user {
        display_name
      }
      video_counters_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CHECK_USER_CONSENT_STATUS = gql`
  query MyQuery($user_id: uuid!) {
    parent_approval_by_pk(user_id: $user_id) {
      id
    }
  }
`;

export const GET_ALL_ACTIVE_FEEDBACK = gql`
  query MyQuery($response_id: uuid!, $user_id: uuid) {
    feedback_data(
      order_by: { order: asc }
      where: { deleted: { _eq: false } }
    ) {
      image
      name
      id
      myfeedback: response_feedbacks_aggregate(
        where: {
          response_id: { _eq: $response_id }
          feedbacker_user_id: { _eq: $user_id }
        }
      ) {
        aggregate {
          count
        }
      }
      response_feedbacks_aggregate(
        where: { response_id: { _eq: $response_id } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ALL_FEEDBACK_FOR_FILTER = gql`
  query MyQuery {
    feedback_data(
      order_by: { order: asc }
      where: { deleted: { _eq: false } }
    ) {
      image
      name
      id
      slug
    }
  }
`;

export const GET_MY_FEEDBACK_BADGES = gql`
  query MyQuery($email: String!) {
    feedback_data(order_by: { order: asc }) {
      response_feedbacks_aggregate(
        where: { response_table: { email: { _eq: $email } } }
      ) {
        aggregate {
          count
        }
      }
      id
      name
      image
    }
  }
`;

export const GET_MY_ADDRESS_BOOK = gql`
  query MyQuery(
    $limit: Int = 10
    $offset: Int = 0
    $group_id: uuid_comparison_exp = {}
  ) {
    address_book_aggregate {
      aggregate {
        count
      }
    }
    address_book(
      limit: $limit
      offset: $offset
      where: { group_id: $group_id }
    ) {
      id
      name
      email
      invite_sent
      existing_user
      group_id
      address_book_group {
        id
        name
      }
    }
  }
`;

export const SEARCH_MY_ADDRESS_BOOK = gql`
  query MyQuery($_ilike: String!, $_ilike1: String!, $limit: Int = 10) {
    address_book(
      where: {
        _or: [{ name: { _ilike: $_ilike } }, { email: { _ilike: $_ilike1 } }]
      }
      limit: $limit
    ) {
      id
      email
      name
    }
  }
`;

export const GET_SHARE_LINK_EMAIL_PER_VIDEO = gql`
  query MyQuery($access_key: String!) {
    video_table_by_pk(access_key: $access_key) {
      share_link_emails {
        id
        email
        name
      }
    }
  }
`;

export const SUB_TO_VIDEOBIT_BY_ACCESSKEY = gql`
  subscription MyQuery($access_key: String!) {
    video_table_by_pk(access_key: $access_key) {
      title
      video_key
      thumbnail_key
      created_at
      access_key
      creator_id
      reply_public
      video_public
      converted
      video_topic {
        slug
        title
      }
      main_video_reads_aggregate {
        aggregate {
          count
        }
      }
      video_counters_aggregate {
        aggregate {
          count
        }
      }
      user {
        display_name
      }
      response_tables(order_by: { created_at: desc }) {
        created_at
        verified_email
        id
        video_key
        thumbnail_key
        updated_at
        name
        email
        desc
        reply_tables(order_by: { created_at: desc }) {
          reply_video_reads_aggregate {
            aggregate {
              count
            }
          }
        }
        video_reads_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
export const SUB_TO_MY_VIDEOS = gql`
  subscription MySubscription($_eq: uuid!, $category: String, $limit: Int!) {
    video_table(
      limit: $limit
      order_by: { created_at: desc }
      where: {
        creator_id: { _eq: $_eq }
        deleted: { _eq: false }
        video_category: { _ilike: $category }
      }
    ) {
      id
      title
      video_key
      created_at
      access_key
      video_public
      thumbnail_key
      video_category
      content_type
      main_video_reads_aggregate {
        aggregate {
          count
        }
      }
      response_tables {
        reply_tables {
          reply_video_reads_aggregate {
            aggregate {
              count
            }
          }
        }
        video_reads_aggregate {
          aggregate {
            count
          }
        }
      }
      response_tables_aggregate {
        aggregate {
          count
        }
      }

      video_counters_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CHECK_EMAIL_IN_ADDRESSBOOK = gql`
  query MyQuery($email: String!) {
    address_book(where: { email: { _eq: $email } }) {
      email
    }
  }
`;

export const GET_MY_CURRENT_MONTH_USAGE = gql`
  query MyQuery($_eq: uuid!, $_gte: timestamptz!, $_lte: timestamptz!) {
    total_video_duration_aggregate(
      where: {
        creator_id: { _eq: $_eq }
        created_at: { _gte: $_gte, _lte: $_lte }
      }
    ) {
      aggregate {
        sum {
          duration
          reply
          response
        }
      }
    }
  }
`;

export const GET_THIS_MONTH_USAGE_DETAIL = gql`
  query MyQuery($_eq: uuid!, $_gte: timestamptz!, $_lte: timestamptz!) {
    total_video_duration(
      where: {
        creator_id: { _eq: $_eq }
        created_at: { _gte: $_gte, _lte: $_lte }
      }
    ) {
      created_at
      duration
      reply
      response
    }
  }
`;

// feedback title need to revisit

// student can only give like only for reply to open every one

// only teacher can see feedback not student, student cannot see

// CATRGORY NUMBER BADGE

// query MyQuery {
//   feedback_data(where: {}) {
//     response_feedbacks_aggregate(where: {response_table: {video_table: {video_category: {_ilike: "class a"}}}}) {
//       aggregate {
//         count
//       }
//     }
//     name
//     image
//   }
// }

// feedback under comment button rather than modal

//Filter base on feedback

// query MyQuery {
//   video_table(where: {response_tables: {response_feedbacks: {feedback_datum: {id: {}}}}}) {
//     title
//   }
// }

export const GET_FEEDBACK_PER_CATEGORY = gql`
  query MyQuery($category: String!, $creator_id: uuid!) {
    feedback_data(
      order_by: { order: asc }
      where: {
        response_feedbacks: {
          response_table: {
            video_table: {
              video_category: { _eq: $category }
              creator_id: { _eq: $creator_id }
            }
          }
        }
      }
    ) {
      name
      image
      id
      response_feedbacks_aggregate(
        where: {
          response_table: {
            video_table: {
              creator_id: { _eq: $creator_id }
              video_category: { _eq: $category }
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CHECK_REPLY_USER_AVATAR = gql`
  query MyQuery($_ilike: String!) {
    users(where: { email: { _ilike: $_ilike } }) {
      email
      display_name
      avatar_url
    }
  }
`;

export const SMART_INDICATOR_DATA = gql`
  query MyQuery($email: String!) {
    smart_indicator_rate_aggregate {
      aggregate {
        max {
          response_count
          feedback_count
          response
        }
        variance {
          response
          response_count
          feedback_count
        }
        var_samp {
          response
          response_count
          feedback_count
        }
        stddev {
          response
          response_count
          feedback_count
        }
      }
    }
    response_feedback_aggregate(
      where: { response_table: { email: { _eq: $email } } }
    ) {
      aggregate {
        count
      }
    }
    response_table_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count(columns: access_key)
        stddev_samp {
          duration
        }
        stddev_pop {
          duration
        }
      }
    }
  }
`;

export const GET_ADDRESS_BOOK_GROUPS = gql`
  query MyQuery {
    address_book_group {
      name
      id
      address_books_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
