/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DetectRTC from "detectrtc";
import useUserMedias from "../../hooks/useUserMedia";

const RTCMediaDetectorForAudio = ({ resetRecorder, setMediaWarn, type }) => {
  // eslint-disable-next-line no-unused-vars
  const [isCheckingCamPermission, setIsCheckingCamPermission] =
    React.useState(true);

  const {
    hasPermission: hasCamPermission,
    requestAccess: requestCamAccess,
    // stream: cameraStream
  } = useUserMedias("video", setIsCheckingCamPermission);

  const handleRequestPermission = async () => {
    navigator.getUserMedia(
      // constraints
      {
        video: true,
        audio: true,
      },

      // successCallback
      function (localMediaStream) {
        var video = document.querySelector("video");
        video.src = window.URL.createObjectURL(localMediaStream);
        video.onloadedmetadata = function (e) {
          // Do something with the video here.
        };
      },

      // errorCallback
      function (err) {
        if (err === "PERMISSION_DENIED") {
          // Explain why you need permission and how to update the permission setting
        }
      }
    );
  };
  const [showWarning, setShowWarning] = useState(false);
  const [mediaStatus, setMediaStatus] = useState({
    hasMic: true,

    micPermission: true,
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      DetectRTC.load();
      setTimeout(() => {
        setMediaStatus({
          hasMic: DetectRTC.hasMicrophone,
          micPermission: DetectRTC.isWebsiteHasMicrophonePermissions,
        });
      }, 300);
      //   console.log({
      //     hasCamera: DetectRTC.hasWebcam,
      //     hasMic: DetectRTC.hasMicrophone,
      //     cameraPermission: DetectRTC.isWebsiteHasWebcamPermissions,
      //     micPermission: DetectRTC.isWebsiteHasMicrophonePermissions,
      //   });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    setShowWarning(false);
  }, []);
  useEffect(() => {
    if (mediaStatus.hasMic && mediaStatus.micPermission) {
      showWarning && resetRecorder();
      setMediaWarn(false);
      setShowWarning(false);
    } else {
      setMediaWarn(true);
      setShowWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaStatus]);

  return (
    showWarning && (
      <div className="modal modal-open">
        <div className="modal-box">
          <div className="flex flex-col  w-full items-center space-y-4 justify-center    ">
            <div>
              <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="47" fill="#CBD5E0" />
                <path
                  d="M50 0C22.3859 0 0 22.3859 0 50C0 77.6141 22.3859 100 50 100C77.6141 100 100 77.6141 100 50C100 22.3859 77.6141 0 50 0ZM50 96.875C24.1125 96.875 3.125 75.8875 3.125 50C3.125 24.1125 24.1125 3.125 50 3.125C75.8875 3.125 96.875 24.1125 96.875 50C96.875 75.8875 75.8875 96.875 50 96.875Z"
                  fill="#111826"
                />
                <path
                  d="M50 57.8125C55.175 57.8125 59.375 53.6125 59.375 48.4375V34.375C59.375 29.1984 55.175 25 50 25C44.8234 25 40.625 29.1984 40.625 34.375V48.4375C40.625 53.6125 44.8234 57.8125 50 57.8125ZM43.75 34.375C43.75 30.9234 46.5484 28.125 50 28.125C53.4516 28.125 56.25 30.9234 56.25 34.375V48.4375C56.25 51.8891 53.4516 54.6875 50 54.6875C46.5484 54.6875 43.75 51.8891 43.75 48.4375V34.375ZM65.625 48.4375C65.625 47.5734 64.9266 46.875 64.0625 46.875C63.1984 46.875 62.5 47.5734 62.5 48.4375C62.5 55.3406 56.9031 60.9375 50 60.9375C43.0969 60.9375 37.5 55.3406 37.5 48.4375C37.5 47.5734 36.8016 46.875 35.9375 46.875C35.0734 46.875 34.375 47.5734 34.375 48.4375C34.375 56.5406 40.5422 63.1984 48.4375 63.9828V71.875H43.75C42.8859 71.875 42.1875 72.5734 42.1875 73.4375C42.1875 74.3016 42.8859 75 43.75 75H56.25C57.1141 75 57.8125 74.3016 57.8125 73.4375C57.8125 72.5734 57.1141 71.875 56.25 71.875H51.5625V63.9828C59.4578 63.1984 65.625 56.5406 65.625 48.4375Z"
                  fill="#111826"
                />
              </svg>
            </div>

            <span className=" text-2xl  text-center w-full ">
              Give access to your microphone
            </span>

            <div className=" w-full p-4">
              <button className=" btn w-full max-w-sm" onClick={resetRecorder}>
                {"Turn on mic"}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default RTCMediaDetectorForAudio;
