import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import { NhostAuthProvider } from "@nhost/react-auth";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { auth } from "./util/nhost";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./context/appContext";
import CheckBrowser from "./util/checkBrowser";
ReactDOM.render(
  <React.StrictMode>
    <CheckBrowser />
    <BrowserRouter>
      <AppProvider>
        <NhostAuthProvider auth={auth}>
          <NhostApolloProvider
            auth={auth}
            gqlEndpoint="https://nhost.tautapp.co:8080/v1/graphql">
            <App />
          </NhostApolloProvider>
        </NhostAuthProvider>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
