import React, { useContext } from "react";
import { StateContext } from "../../context/appContext";

const OnboardingModal = ({ l }) => {
  const state = useContext(StateContext);

  function renderSection() {
    switch (state?.onboardingLocation) {
      case "home":
        return <div>This is home page</div>;
      case "type":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <h1>Select topic type</h1>
            <br />
            You need to select what type of topic you want to create. You can
            learn more about each type by clicking on Learn button
          </div>
        );

      case "prerecording":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <br />
            Make sure you have connected camera and microphone to start
            recording .
          </div>
        );
      case "audio":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <br />
            Make sure you have connected microphone to start recording audio.
          </div>
        );
      case "video":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <br />
            Make sure you have connected camera and microphone to start
            recording video.
          </div>
        );
      case "screen":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <br />
            Make sure you have connected microphone to start recording screen.
            You need to select screen or window to start recording in next step.
          </div>
        );
      case "recording":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <br />
            Start recording. First time you need to give access to VideoBit to
            record your topic. You have 6o seconds to record your content.
          </div>
        );
      case "publish":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <br />
            You are almost done. Give title and description to your new topic.
            Create category if you needed. also you can define permissions to
            moderate your topic.
          </div>
        );
      case "preview":
        return (
          <div className=" text-lg  sm:text-2xl font-bold">
            <br />
            Review your recording here. If you want, you can re-record it by
            clicking 'No'. If all is good, proceed by clicking 'Yes'.
          </div>
        );
      case "share":
        return (
          <div className=" text-lg sm:text-2xl font-bold">
            <div className=" text-lg sm:text-2xl font-bold">
              Amazing!. Here you can copy link of topic and share. Or directly
              send this link by email.
            </div>
          </div>
        );

      default:
        break;
    }
  }
  return (
    <div className=" flex flex-col max-w-xl w-full  bg-primary text-base-100 absolute  rounded-xl p-6 z-50 shadow  top-0 right-2">
      {renderSection()}
    </div>
  );
};

export default OnboardingModal;
