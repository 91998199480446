import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DataLoading from "../../components/global/dataLoading";

const ConfirmReplyEmail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    console.log(id);
    id &&
      axios
        .post(
          "https://nhost.tautapp.co:8080/api/rest/confirmresponse",
          { id: id },
          {
            headers: {
              "content-type": "application/json",
              "x-hasura-role": "user",
            },
          }
        )
        .then((res) => {
          //   console.log(res);
          setTimeout(() => {
            setLoading(false);
            setError(false);
          }, 700);
        })
        .catch(function (error) {
          //   console.log(error);
          setTimeout(() => {
            setLoading(false);
            setError(true);
          }, 700);
        });
  }, [id]);
  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      {loading ? (
        <DataLoading />
      ) : (
        <div>
          {error ? (
            <div>Some thing is wrong, check your link in email again </div>
          ) : (
            <div className=" flex flex-col space-y-2 items-center">
              <span>You confirmed your response.</span>
              <button
                className=" btn btn-primary"
                onClick={() => window.open("https://app.videobit.io", "_self")}>
                Go see more{" "}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfirmReplyEmail;
