import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_MY_VIDEO_SEARCH } from "../../util/getQueries";
import { auth } from "../../util/nhost";
import SearchCard from "../video/searchCard";
import _ from "lodash";
const GlobalSearch = () => {
  const [open, setOpen] = useState(false);
  const client = useApolloClient();
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const searchMyVideo = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_VIDEO_SEARCH,
        variables: {
          user_id: auth.getClaim("x-hasura-user-id"),
          title: `%${value}%`,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });
      if (!loading && !error && data) {
        setData(data.video_table);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    searchMyVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <label
        className="btn btn-ghost btn-circle"
        onClick={() => setOpen(!open)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </label>

      <label
        htmlFor="my-modal-4"
        className={`modal cursor-pointer py-4 ${open && "modal-open"}`}>
        <label
          className="modal-box relative  h-96 max-w-xl w-full  overflow-hidden px-0"
          htmlFor="">
          <span
            className=" text-xl font-bold px-4 
          ">
            Search my topics
          </span>
          <br />
          <div className=" flex flex-row justify-between items-center px-4 py-4 ">
            <div className="input-group mr-8">
              <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                type="text"
                placeholder="Search…"
                className="input input-bordered w-full  focus:outline-0"
              />
              <button className="btn btn-square">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
            <label
              onClick={() => setOpen(false)}
              className="btn btn-sm btn-circle absolute right-2   ">
              ✕
            </label>
          </div>

          <div className=" flex flex-col py-4  overflow-y-auto h-full px-4 ">
            {_.isEmpty(data) && (
              <div className=" flex flex-col space-y-2 p-2 items-center">
                <span>There is no result</span>
                <button
                  onClick={() => setValue("")}
                  className=" btn btn-primary btn-sm  w-32">
                  see all
                </button>
              </div>
            )}
            {data.map((item) => (
              <SearchCard
                data={item}
                key={item.id}
                close={() => setOpen(false)}
              />
            ))}
          </div>
        </label>
      </label>
    </div>
  );
};

export default GlobalSearch;
