import React from "react";
import MainTopNavbar from "../navigation/mainTopNavbar";

const MainLayout = ({ children, onboarding }) => {
  return (
    <div className=" flex flex-col  w-full overflow-y-auto items-center py-2 ">
      <div className=" max-w-5xl w-full ">
        <MainTopNavbar onboarding={onboarding} />
        <div className=" mt-20 py-4 p-2    flex flex-col   w-full items-center space-y-2 overflow-x-hidden ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
