import React from "react";
import DataLoading from "./dataLoading";

const ScreenLoading = ({ open, uploading, progress }) => {
  return (
    <div className={`modal ${open && "modal-open"}`}>
      <div className="  bg-primary-300 bg-base-100 bg-opacity-90 flex flex-col space-y-4 items-center justify-center w-screen h-screen">
        <DataLoading />
        <span className=" text-3xl font-bold cursor-pointer">
          <span className="  text-primary">Video</span>
          <span className=" text-secondary">Bit</span>
        </span>{" "}
        {uploading && (
          <progress
            class="progress progress-primary w-56"
            value={progress}
            max="1"></progress>
        )}
      </div>
    </div>
  );
};

export default ScreenLoading;
