/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DetectRTC from "detectrtc";
import useUserMedias from "../../hooks/useUserMedia";

const RTCMediaDetectorForVideo = ({ resetRecorder, setMediaWarn, type }) => {
  // eslint-disable-next-line no-unused-vars
  const [isCheckingCamPermission, setIsCheckingCamPermission] =
    React.useState(true);

  const {
    hasPermission: hasCamPermission,
    requestAccess: requestCamAccess,
    // stream: cameraStream
  } = useUserMedias("video", setIsCheckingCamPermission);

  const handleRequestPermission = async () => {
    navigator.getUserMedia(
      // constraints
      {
        video: true,
        audio: true,
      },

      // successCallback
      function (localMediaStream) {
        var video = document.querySelector("video");
        video.src = window.URL.createObjectURL(localMediaStream);
        video.onloadedmetadata = function (e) {
          // Do something with the video here.
        };
      },

      // errorCallback
      function (err) {
        if (err === "PERMISSION_DENIED") {
          // Explain why you need permission and how to update the permission setting
          console.log("permission denied");
        }
      }
    );
  };
  const [showWarning, setShowWarning] = useState(false);
  const [mediaStatus, setMediaStatus] = useState({
    hasCamera: true,
    hasMic: true,
    cameraPermission: true,
    micPermission: true,
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      DetectRTC.load();
      setTimeout(() => {
        DetectRTC.DetectLocalIPAddress((ipAddress) => {
          console.log(ipAddress);
        });
        setMediaStatus({
          hasCamera: DetectRTC.hasWebcam,
          hasMic: DetectRTC.hasMicrophone,
          cameraPermission: DetectRTC.isWebsiteHasWebcamPermissions,
          micPermission: DetectRTC.isWebsiteHasMicrophonePermissions,
        });
      }, 300);
      //   console.log({
      //     hasCamera: DetectRTC.hasWebcam,
      //     hasMic: DetectRTC.hasMicrophone,
      //     cameraPermission: DetectRTC.isWebsiteHasWebcamPermissions,
      //     micPermission: DetectRTC.isWebsiteHasMicrophonePermissions,
      //   });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    setShowWarning(false);
  }, []);
  useEffect(() => {
    if (
      mediaStatus.hasCamera &&
      mediaStatus.hasMic &&
      mediaStatus.cameraPermission &&
      mediaStatus.micPermission
    ) {
      showWarning && resetRecorder();
      setMediaWarn(false);
      setShowWarning(false);
    } else {
      setMediaWarn(true);
      setShowWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaStatus]);

  return (
    showWarning && (
      <div className="modal modal-open">
        <div className="modal-box">
          <div className="flex flex-col  w-full items-center space-y-4 justify-center    ">
            <div>
              <svg
                width="120"
                height="100"
                viewBox="0 0 120 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M40.9443 0C37.9141 0 35.144 1.71202 33.7889 4.42229L28 16H8C3.58172 16 0 19.5817 0 24V92C0 96.4183 3.58172 100 8 100H112C116.418 100 120 96.4183 120 92V24C120 19.5817 116.418 16 112 16H92L86.2111 4.42229C84.856 1.71202 82.0859 0 79.0557 0H40.9443Z"
                  fill="#CBD5E0"
                />
                <circle cx="60" cy="60" r="32" fill="#111826" />
                <circle cx="60" cy="60" r="20" fill="#CBD5E0" />
                <rect
                  x="92"
                  y="24"
                  width="20"
                  height="12"
                  rx="6"
                  fill="#111826"
                />
              </svg>
            </div>

            <span className=" text-2xl  text-center w-full ">
              Give access to your camera and microphone
            </span>

            <div className=" w-full p-4">
              <button className=" btn w-full max-w-sm" onClick={resetRecorder}>
                {"Turn on camera and mic"}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default RTCMediaDetectorForVideo;
