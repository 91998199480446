import React, { useEffect, useRef, useState } from "react";
import Avatar, { genConfig } from "react-nice-avatar";
import _ from "lodash";

import domtoimage from "dom-to-image";
import { CHANGE_AVATAR_PROFILE } from "../../util/insertQueries";
import { auth, storage } from "../../util/nhost";
import { useApolloClient } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { idGenerator } from "../global/idGenerator";

const DefaultOptions = {
  sex: ["man", "woman"],
  faceColor: ["#F9C9B6", "#AC6651"],
  earSize: ["small", "big"],
  hairColor: [
    "#000",
    "#fff",
    "#77311D",
    "#FC909F",
    "#D2EFF3",
    "#506AF4",
    "#F48150",
  ],
  hairStyleMan: ["normal", "thick", "mohawk"],
  hairStyleWoman: ["normal", "womanLong", "womanShort"],
  hatColor: [
    "#000",
    "#fff",
    "#77311D",
    "#FC909F",
    "#D2EFF3",
    "#506AF4",
    "#F48150",
  ],
  hatStyle: ["beanie", "turban", "none"],
  eyeBrowWoman: ["up", "upWoman"],
  eyeStyle: ["circle", "oval", "smile"],
  glassesStyle: ["round", "square", "none"],
  noseStyle: ["short", "long", "round"],
  mouthStyle: ["laugh", "smile", "peace"],
  shirtStyle: ["hoody", "short", "polo"],
  shirtColor: ["#9287FF", "#6BD9E9", "#FC909F", "#F4D150", "#77311D"],
  bgColor: [
    "#9287FF",
    "#6BD9E9",
    "#FC909F",
    "#F4D150",
    "#E0DDFF",
    "#D2EFF3",
    "#FFEDEF",
    "#FFEBA4",
    "#506AF4",
    "#F48150",
    "#74D153",
  ],
  gradientBgColor: [
    "linear-gradient(45deg, #178bff 0%, #ff6868 100%)",
    "linear-gradient(45deg, #176fff 0%, #68ffef 100%)",
    "linear-gradient(45deg, #ff1717 0%, #ffd368 100%)",
    "linear-gradient(90deg, #36cd1c 0%, #68deff 100%)",
    "linear-gradient(45deg, #3e1ccd 0%, #ff6871 100%)",
    "linear-gradient(45deg, #1729ff 0%, #ff56f7 100%)",
    "linear-gradient(45deg, #56b5f0 0%, #45ccb5 100%)",
  ],
};
const AvatarMakerModal = ({ open, close }) => {
  const [isGirl, setIsGirl] = useState(true);
  const avatarRef = useRef(null);
  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig(genConfig());
  }, []);

  function changeGender() {
    setIsGirl((isGirl) => !isGirl);
    setConfig({
      ...config,
      sex: isGirl ? "woman" : "man",
      hairStyle: _.sample(
        _.filter(
          isGirl ? DefaultOptions.hairStyleWoman : DefaultOptions.hairStyleMan,
          function (r) {
            return r !== config.hairStyle;
          }
        )
      ),
    });
    // switchConfig("hairStype");
  }
  function switchConfig(type) {
    console.log(config[type]);
    let arr = DefaultOptions[type];
    console.log(
      _.sample(
        _.filter(arr, function (r) {
          return r !== config[type];
        })
      )
    );
    const opts = _.sample(
      _.filter(arr, function (r) {
        return r !== config[type];
      })
    );
    // console.log(genConfig().earSize);
    setConfig((config) => ({ ...config, [type]: opts }));
  }
  function switchConfigHair(type) {
    let arr = DefaultOptions[isGirl ? "hairStyleWoman" : "hairStyleMan"];
    console.log(
      _.sample(
        _.filter(arr, function (r) {
          return r !== config.hairStyle;
        })
      )
    );
    const opts = _.sample(
      _.filter(arr, function (r) {
        return r !== config.hairStyle;
      })
    );
    // console.log(genConfig().earSize);
    setConfig((config) => ({ ...config, [type]: opts }));
  }
  function onUploadProgress(e) {
    console.log(e.preview);
  }

  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const client = useApolloClient();
  const handleUpload = async () => {
    const blob = await domtoimage.toBlob(document.getElementById("vbavatar"));

    setSubmitting(true);

    try {
      await storage
        .put(
          `/public/avatar/${
            auth.getClaim("x-hasura-user-id") + "-" + idGenerator(5)
          }`,
          blob,
          onUploadProgress
        )
        .then((avatar) =>
          client.mutate({
            variables: {
              id: auth.getClaim("x-hasura-user-id"),

              avatar_url: `${process.env.REACT_APP_API_KEY}/storage/o/${avatar.key}`,
            },
            mutation: CHANGE_AVATAR_PROFILE,
            context: {
              headers: {
                "x-hasura-role": "user",
              },
            },
          })
        )
        .then((res) => {
          console.log(res);
          window.open(location.pathname, "_self");
        });
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className={`modal ${open && "modal-open"} -top-2 `}>
      <div className="modal-box   max-w-xl ">
        <h3 className="font-bold text-lg">Create your avatar</h3>

        <div className=" flex flex-col space-y-4 items-center">
          <Avatar
            id="vbavatar"
            ref={avatarRef}
            className=" w-40 h-40"
            {...config}
          />
          <div className="  grid grid-cols-4  gap-2 items-center space-x-1">
            <button className=" btn btn-xs" onClick={() => changeGender()}>
              {" "}
              Gender
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("faceColor")}>
              {" "}
              Face color
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfigHair("hairStyle")}>
              {" "}
              Hair style{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("hatStyle")}>
              {" "}
              Hat{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("eyeStyle")}>
              {" "}
              Eye{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("glassesStyle")}>
              {" "}
              Glasses{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("noseStyle")}>
              {" "}
              Nose{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("mouthStyle")}>
              {" "}
              Mouth{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("shirtStyle")}>
              {" "}
              Shirt{" "}
            </button>
            <button
              className=" btn btn-xs"
              onClick={() => switchConfig("bgColor")}>
              {" "}
              Background
            </button>
          </div>
        </div>
        <div className="modal-action">
          <button onClick={() => handleUpload()} className="btn   btn-primary">
            I want this
          </button>
          <button onClick={() => close()} className="btn  btn-outline">
            close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarMakerModal;
