import React, { createContext, useReducer } from "react";

export const StateContext = createContext("");
export const DispatchContext = createContext("");

function appReducer(state, action) {
  switch (action.type) {
    case "selectedTheme":
      return {
        ...state,
        selectedTheme: action.payload,
      };
    case "updateRecordingDestination":
      return {
        ...state,
        recordingDestination: action.payload,
      };
    case "finishOnboarding":
      return {
        ...state,
        userData: { ...state.userData, onboarding: true },
      };
    case "updateOnboardingLocation":
      return {
        ...state,
        onboardingLocation: action.payload,
      };
    case "updateRecordingStep":
      return {
        ...state,
        recordingStep: action.payload,
      };
    case "updateUserData":
      return {
        ...state,
        userData: action.payload,
      };
    case "updateUserRoles":
      return {
        ...state,
        userRoles: action.payload.roles,
        isLoaded: action.payload.isLoaded,
      };

    case "updateRecordedMedia":
      return {
        ...state,
        media: {
          blob: action.payload.blob,
          type: action.payload.type,
          coverPhoto: action.payload.coverPhoto,
          duration: action.payload.duration,
        },
      };

    default:
      return {
        state,
      };
  }
}

const initialState = {
  onboardingLocation: "home",
  selectedTheme: "light",
  authenticated: false,
  userData: {},
  userRoles: [],
  isLoaded: false,
  media: null,
  recordingDestination: "topic",
  recordingStep: 1,
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};
export default AppProvider;
