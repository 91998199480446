import React from "react";

const UserAvatarItem = ({ url }) => {
  return (
    <label>
      <div className="w-10 h-10 rounded-full object-cover  cursor-default ">
        <img
          alt=""
          src={url ? url : "/assets/images/avatar.png"}
          className="    w-full h-full  object-cover   object-top"
        />
      </div>
    </label>
  );
};

export default UserAvatarItem;
