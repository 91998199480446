import React, { useState, useEffect } from "react";

const BottomNotification = ({ message, type, rnd }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (message !== "") {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [message, type, rnd]);
  return (
    open && (
      <div
        className={`alert ${type} shadow-lg fixed bottom-4  max-w-3xl z-50  `}>
        <div>
          {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-current flex-shrink-0 w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg> */}
          <span>{message}</span>
        </div>
      </div>
    )
  );
};

export default BottomNotification;
