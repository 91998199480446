import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../../components/layout/mainLayout";
import { DispatchContext, StateContext } from "../../context/appContext";
import { GET_MY_CATEGORIES } from "../../util/getQueries";
import { useApolloClient } from "@apollo/client";
import { auth, storage } from "../../util/nhost";
import {
  CREATE_TOPIC_CATEGORY,
  MAIN_VIDEO_READ_RECEIPT,
  PUBLISH_NEW_TOPIC,
} from "../../util/insertQueries";
import PublishCoverDisplay from "../../components/global/publishCoverDisplay";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./texteditor.css";
import { idGenerator } from "../../components/global/idGenerator";
import CreateContentStatus from "../../components/global/createContentStatus";
import ScreenLoading from "../../components/global/screenLoading";
import ShareTopicLink from "../../components/modal/shareTopicLink";
import OnboardingModal from "../../components/modal/onboardingModal";
import ItemHelp from "../../components/modal/itemHelp";
import { pinGenerator } from "../../components/global/pinGenerator";
import ReactGA from "react-ga4";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown

    ["clean"],
  ],
};

const PublishTopic = () => {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  useEffect(() => {
    state.media === null && navigate("/create");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const client = useApolloClient();
  const [categories, setCategories] = useState([]);

  const handleReadReceiptForMainVideo = async (videoId) => {
    client
      .mutate({
        variables: {
          object: {
            user_id: auth.getClaim("x-hasura-user-id"),
            video_id: videoId,
          },
        },
        mutation: MAIN_VIDEO_READ_RECEIPT,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getMyCategories = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_CATEGORIES,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setCategories(data.topic_categories);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getMyCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [createNewCategory, setCreateNewCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const handleCreateCategory = () => {
    client
      .mutate({
        variables: {
          object: {
            user_id: auth.getClaim("x-hasura-user-id"),
            name: categoryName,
          },
        },
        mutation: CREATE_TOPIC_CATEGORY,
      })
      .then((res) => {
        setCreateNewCategory(false);
        getMyCategories();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [privateComm, setPrivateComm] = useState(false);
  const [controlledReply, setControlledReply] = useState(false);
  const [controllComments, setControlComments] = useState(false);
  const [studentToStudent, setStudentToStudent] = useState(false);
  const [created, setCreated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { type } = useParams();
  const [addressKey, setAddressKey] = useState();
  const [pincode, setPincode] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);

  function onUploadProgress(e) {
    console.log(e.loaded / e.total);
    setUploadProgress(e.loaded / e.total);
  }
  const handlePublishNewTopic = async () => {
    dispatch({ type: "updateOnboardingLocation", payload: "share" });
    console.log(state.media.blob);
    setSubmitting(true);
    let videoKey;
    let thumbnailKey;

    try {
      await storage
        .put(
          `/public/${auth.getClaim("x-hasura-user-id")}/video/${
            Date.now() + "-" + idGenerator(5)
          }`,
          state.media.blob,
          null,
          onUploadProgress
        )
        .then((vidKey) => (videoKey = vidKey.key))
        .then(
          () =>
            type === "video" &&
            storage.put(
              `/public/${auth.getClaim("x-hasura-user-id")}/thumbnail/${
                Date.now() + "-" + idGenerator(5)
              }`,
              state.media.coverPhoto,
              onUploadProgress
            )
        )
        .then((thumb) => (thumbnailKey = thumb.key))
        .then(() =>
          client.mutate({
            variables: {
              object: {
                creator_id: auth.getClaim("x-hasura-user-id"),
                title: title,
                desc: desc,
                private_comm: privateComm,
                control_reply: controlledReply,
                control_comment: controllComments,
                student_to_student: studentToStudent,
                video_category: selectedCategory,
                duration: state.media.duration,
                content_type: type,
                pincode: pinGenerator(),
                video_key: videoKey,
                thumbnail_key: type === "video" ? thumbnailKey : null,
                video_duration_tables: {
                  data: {
                    duration: state.media.duration,
                    user_id: auth.getClaim("x-hasura-user-id"),
                  },
                },
              },
            },
            mutation: PUBLISH_NEW_TOPIC,
            context: {
              headers: {
                "x-hasura-role": "user",
              },
            },
          })
        )
        .then((res) => {
          ReactGA.event({
            category: "topic",
            action: "topic_recorded ",
          });
          console.log(res);
          setAddressKey(res?.data?.insert_video_table_one?.access_key);
          setPincode(res?.data?.insert_video_table_one?.pincode);

          handleReadReceiptForMainVideo(
            res?.data?.insert_video_table_one?.access_key
          );
          setTimeout(() => {
            setCreated(true);
            setSubmitting(false);
          }, 300);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log({ error });
    }
  };
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Changes that you made may not be saved.")) {
        setfinishStatus(true);
        navigate(`/create/${type}`);
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const dispatch = useContext(DispatchContext);
  useEffect(() => {
    dispatch({ type: "updateRecordingStep", payload: 3 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <CreateContentStatus />
      <PublishCoverDisplay type={type} />
      {!state?.userData?.onboarding && <OnboardingModal />}
      <div className=" flex flex-col max-w-lg w-full items-start  space-y-4 py-4">
        <div className=" flex flex-row space-x-2">
          <span className=" form-title">Title</span>
          <ItemHelp text="Title of your topic. It helps you and the receipients to find and understand it easier" />{" "}
        </div>
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Title"
          className="input input-bordered w-full "
        />
        <div className=" py-4  flex flex-col space-y-4">
          <div className=" flex flex-row space-x-2">
            <span className=" form-title">Description</span>
            <div className="badge bg-red-500  border-0">Optional</div>
            <ItemHelp text="Add any additional details you want the receipients to know. Note that you have format options as well. " />{" "}
          </div>
          <div className="flex flex-col w-full min-h-full flex-grow">
            <ReactQuill modules={modules} value={desc} onChange={setDesc} />
          </div>
        </div>

        <div className=" py-4 space-y-4">
          <div className=" flex flex-row items-center space-x-2">
            <span className=" form-title">Category</span>
            <div className="badge bg-red-500  border-0">Optional</div>
          </div>
          <span>
            Select or create a category that helps to organize and search for
            topics easier. The categories you create are only visible to you.
          </span>
          <div className=" flex flex-row flex-wrap">
            {categories.map((cat) => (
              <div
                key={cat.id}
                onClick={() => setSelectedCategory(cat.name)}
                className={` ${
                  selectedCategory === cat?.name && "badge-secondary"
                } badge badge-lg mr-2 mb-2 uppercase  cursor-pointer`}>
                {cat.name}
              </div>
            ))}

            {createNewCategory ? (
              <div className=" flex  flex-row space-x-2 w-full py-2 items-center">
                <input
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                />
                <button
                  className=" btn btn-sm"
                  onClick={() => handleCreateCategory()}>
                  Save
                </button>
                <button
                  className=" btn btn-sm"
                  onClick={() => setCreateNewCategory(false)}>
                  Close
                </button>
              </div>
            ) : (
              <span
                className="link link-primary"
                onClick={setCreateNewCategory}>
                Create new category
              </span>
            )}
          </div>
        </div>
        <div className=" flex flex-col py-4 space-y-4">
          <span className=" form-title">Private communication</span>
          <span>
            Turn on the private reply if you want to comminucate with student
            individually . Other students cannot see each other reply in private
            comminucation .
          </span>
          <div className="btn-group">
            <button
              onClick={() => setPrivateComm(false)}
              className={`btn ${!privateComm && "btn-active"}`}>
              OFF
            </button>{" "}
            <button
              onClick={() => setPrivateComm(true)}
              className={`btn ${privateComm && "btn-active"}`}>
              ON
            </button>
          </div>
        </div>

        {!privateComm && (
          <div className=" flex flex-col space-y-2">
            <div className=" flex flex-col py-4 space-y-4">
              <span className=" form-title">Reply moderation</span>
              <span>
                Turn on the reply moderation if you want to approve student
                replies before they become visible to others.
              </span>
              <div className="btn-group">
                <button
                  onClick={() => setControlledReply(false)}
                  className={`btn ${!controlledReply && "btn-active"}`}>
                  OFF
                </button>{" "}
                <button
                  onClick={() => setControlledReply(true)}
                  className={`btn ${controlledReply && "btn-active"}`}>
                  ON
                </button>{" "}
              </div>
            </div>
            <div className=" flex flex-col py-4 space-y-4">
              <span className=" form-title">Student-to-student comments</span>
              <span>
                Turn Student-to-student replies on to allow students to Reply
                via video to another student.
              </span>
              <div className="btn-group">
                <button
                  onClick={() => setStudentToStudent(false)}
                  className={`btn ${!studentToStudent && "btn-active"}`}>
                  OFF
                </button>{" "}
                <button
                  onClick={() => setStudentToStudent(true)}
                  className={`btn ${studentToStudent && "btn-active"}`}>
                  ON
                </button>
              </div>
            </div>
            {studentToStudent && (
              <div className=" flex flex-col py-4 space-y-4">
                <span className=" form-title">Comment moderation</span>
                <span>
                  Turn on the comment moderation if you want to approve student
                  comments before they become visible to others.
                </span>
                <div className="btn-group">
                  <button
                    onClick={() => setControlComments(false)}
                    className={`btn ${!controllComments && "btn-active"}`}>
                    OFF
                  </button>{" "}
                  <button
                    onClick={() => setControlComments(true)}
                    className={`btn ${controllComments && "btn-active"}`}>
                    ON
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <div className=" flex flex-row w-full justify-end py-4 space-x-4">
          <button className=" btn">Go Back</button>
          {title !== "" && (
            <button className=" btn" onClick={() => handlePublishNewTopic()}>
              Finish
            </button>
          )}
        </div>
      </div>
      <ScreenLoading
        uploading={true}
        progress={uploadProgress}
        open={submitting}
      />
      {created && (
        <ShareTopicLink
          videoAccessKey={addressKey}
          pincode={pincode}
          open={true}
          close={() => navigate("/")}
          link={`https://app.videobit.io/a/${addressKey}`}
        />
      )}
    </MainLayout>
  );
};

export default PublishTopic;
