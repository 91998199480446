import { useAuth } from "@nhost/react-auth";
import React from "react";
import { Navigate, useLocation } from "react-router";
import DataLoading from "../components/global/dataLoading";

export function RequireAuth({ children }) {
  const { signedIn } = useAuth();
  const location = useLocation();

  if (signedIn === null) {
    return (
      <div className=" flex flex-col w-full h-full items-center justify-center">
        <DataLoading />
      </div>
    );
  }

  if (!signedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
