import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_FEEDBACK_PER_CATEGORY } from "../../util/getQueries";
import { auth } from "../../util/nhost";
import _ from "lodash";
const FeedbackForCategories = ({ category, recordExist }) => {
  const [data, setData] = useState([]);
  const client = useApolloClient();
  const getFeedbacks = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_FEEDBACK_PER_CATEGORY,
        fetchPolicy: "no-cache",
        variables: {
          category: category,
          creator_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setData(data.feedback_data);
        recordExist(!_.isEmpty(data.feedback_data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFeedbacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);
  return (
    <div className=" flex flex-row flex-wrap">
      {data?.map((item) => (
        <div
          key={item.id}
          className=" flex flex-col space-x-2  items-center p-2 rounded-lg bg-base-100 shadow mr-2 mb-2 ">
          <div className="  flex flex-row space-x-2 items-center">
            <img
              className="  w-10"
              src={`/assets/icons/${item.image}`}
              alt=""
            />{" "}
            {item.response_feedbacks_aggregate.aggregate.count > 0 && (
              <span className=" badge badge-secondary h-6 w-6   ">
                {item.response_feedbacks_aggregate.aggregate.count}
              </span>
            )}
          </div>{" "}
          <span className=" text-xs">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default FeedbackForCategories;
