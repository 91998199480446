import React, { useEffect, useState } from "react";
import useCountDown from "react-countdown-hook";

const initialTime = 3 * 1000;
const interval = 1000;
const RecordingButton = ({
  recording,
  startRecording,
  stopRecording,
  recordingTimeLeft,
}) => {
  const [preRecordCount, setPreRecordCount] = useState(false);
  const [timeLeft, actions] = useCountDown(initialTime, interval);
  const handleStart = () => {
    setPreRecordCount(true);
    actions.start();
  };

  useEffect(() => {
    if (preRecordCount) {
      if (timeLeft === 0) {
        setTimeout(() => {
          startRecording();
          setPreRecordCount(false);
        }, 700);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);
  return (
    <div className="  flex flex-col items-center  ">
      {recording ? (
        recordingTimeLeft < 57 && (
          <div
            onClick={() => stopRecording()}
            className=" btn btn-secondary btn-lg   animate-pulse  rounded-full flex flex-col items-center justify-center text-white bg-red-500 ">
            Stop recording
          </div>
        )
      ) : preRecordCount ? (
        <div className=" flex flex-col w-full h-full items-center justify-center font-bold">
          <span className=" text-2xl">Get ready</span>
          {timeLeft !== 0 && (
            <span className="countdown font-mono text-4xl scale-150">
              <span style={{ [`--value`]: timeLeft / 1000 }}></span>
            </span>
          )}
        </div>
      ) : (
        <div
          onClick={() => handleStart()}
          className=" btn btn-primary btn-lg     rounded-full flex flex-col items-center justify-center text-white ">
          Start recording
        </div>
      )}
    </div>
  );
};

export default RecordingButton;
