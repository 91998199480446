import { useState, useEffect } from "react";
import axios from "axios";

function GetUserIP() {
  //creating IP state
  // eslint-disable-next-line no-unused-vars
  const [ip, setIP] = useState("");
  const [data, setData] = useState();

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setData(res.data);
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  return data;
}

export default GetUserIP;
