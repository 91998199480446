import React from "react";
import VideoRecorder from "react-video-recorder/lib/video-recorder";
import RTCMediaDetectorForVideo from "../../components/global/rtcMediaDetectorForVideo";
import RecodingStatus from "./recordingStatus";
import "./videoRecording.css";

const VideoRecording = ({
  recording,

  setCoverPhoto,
  stopRecording,
  setDuration,
}) => {
  const handleVideoRecordingEnd = (
    videoBlob,
    startedAt,
    thumbnailBlob,
    duration
  ) => {
    setCoverPhoto(thumbnailBlob);
    stopRecording(videoBlob);
    setDuration(Math.floor(duration / 1000));
  };
  return (
    <div className=" flex flex-col w-full h-full  ">
      <RTCMediaDetectorForVideo
        setMediaWarn={(e) => console.log(e)}
        resetRecorder={(e) => console.log("reset RTC")}
      />
      {recording && <RecodingStatus />}
      <VideoRecorder
        className=""
        constraints={{ audio: true, video: true }}
        isOnInitially
        isFlipped={true}
        timeLimit={60000}
        chunkSize={100}
        dataAvailableTimeout={null}
        renderErrorView={() => (
          <div className=" bg-gray-900 flex flex-col w-full h-full "></div>
        )}
        // renderActions={ActionButton}
        // onTurnOffCamera={(e) => console.log(e, "sam mamali")}
        onRecordingComplete={
          // Do something with the video...
          handleVideoRecordingEnd
        }
      />
    </div>
  );
};

export default VideoRecording;
