import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { auth, storage } from "../../util/nhost";
import { StateContext } from "../../context/appContext";
import { GET_VIDEOBIT_CRTEATOR_ID } from "../../util/getQueries";
import { CREATE_COMMENT_REPLY } from "../../util/insertQueries";
import { idGenerator } from "../../components/global/idGenerator";
import DataLoading from "../../components/global/dataLoading";
import ReactGA from "react-ga4";

const PublishComment = () => {
  const [submitting, setSubmitting] = useState(false);
  const [created, setCreated] = useState(false);
  const client = useApolloClient();
  const state = useContext(StateContext);
  const [uploadProgress, setUploadProgress] = useState(0);

  function onUploadProgress(e) {
    console.log(e.loaded / e.total);
    setUploadProgress(e.loaded / e.total);
  }
  const { type, responseid } = useParams();
  const navigate = useNavigate();
  const getAssigmentCreatorId = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_VIDEOBIT_CRTEATOR_ID,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
        variables: { id: responseid },
      });

      if (!loading && !error && data) {
        console.log(data?.response_table_by_pk, "this is from response");
        handleSubmitAssignment(data?.response_table_by_pk);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getAssigmentCreatorId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseid]);

  const handleSubmitAssignment = async (data) => {
    setSubmitting(true);
    let videoKey;
    let thumbnailKey;
    try {
      await storage
        .put(
          `/public/${auth.getClaim("x-hasura-user-id")}/video/${
            Date.now() + "-" + idGenerator(5)
          }`,
          state.media.blob,
          null,
          onUploadProgress
        )
        .then((vidKey) => (videoKey = vidKey.key))
        .then(
          () =>
            type === "video" &&
            storage.put(
              `/public/${auth.getClaim("x-hasura-user-id")}/thumbnail/${
                Date.now() + "-" + idGenerator(5)
              }`,
              state.media.coverPhoto,
              onUploadProgress
            )
        )
        .then((thumb) => (thumbnailKey = thumb.key))
        .then(() =>
          client.mutate({
            variables: {
              object_reply: {
                response_id: responseid,
                video_key: videoKey,
                thumbnail_key: thumbnailKey,
                duration: state.media.duration,
                user_id: auth.getClaim("x-hasura-user-id"),
                assignment_creator_id: data?.video_table?.user?.id,
                response_user_email: data?.email,
                content_type: state.media.type,
              },
              object_billing: {
                access_key: data?.video_table?.access_key,
                duration: state.media.duration,
                type: 2,
                user_id: auth.getClaim("x-hasura-user-id"),
              },
            },
            mutation: CREATE_COMMENT_REPLY,
            context: {
              headers: {
                "x-hasura-role": "user",
              },
            },
          })
        )
        .then((res) => {
          ReactGA.event({
            category: "comment",
            action: "comment_recorded ",
          });
          console.log(res);
          navigate(`/topic/${data.video_table.access_key}/${responseid}`, {
            replace: true,
          });
          setCreated(true);
        });
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <div className="   flex flex-col  py-20 justify-between  h-full items-center   ">
      <div className="flex flex-col h-40">
        {/* <VideoPlayer url={URL.createObjectURL(videoBlob)} /> */}
      </div>
      <div className="flex flex-col ">
        {submitting ? (
          created ? (
            <div className="flex flex-col items-center space-y-4 py-20 ">
              <span className="text-gray-800 font-light text-xl">
                You replied{" "}
              </span>
              <Link to={`/p/${responseid}`}>
                <button className="btn-generic bg-gray-800 rounded-full">
                  Go back to response story
                </button>
              </Link>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-2">
              <DataLoading />
              <progress
                class="progress progress-primary w-56"
                value={uploadProgress}
                max="1"></progress>
            </div>
          )
        ) : (
          <button onClick={() => {}}></button>
        )}
      </div>
      {/* 

       */}
    </div>
  );
};

export default PublishComment;
