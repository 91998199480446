import { gql } from "@apollo/client";

export const CREATE_TOPIC_CATEGORY = gql`
  mutation MyMutation($object: topic_categories_insert_input!) {
    insert_topic_categories_one(object: $object) {
      id
    }
  }
`;

export const PUBLISH_NEW_TOPIC = gql`
  mutation MyMutation($object: video_table_insert_input!) {
    insert_video_table_one(object: $object) {
      access_key
      pincode
    }
  }
`;

export const CREATE_REPLY_PUBLIC = gql`
  mutation MyMutation(
    $object_response: response_table_insert_input!
    $object_billing: video_duration_table_insert_input!
  ) {
    insert_response_table_one(object: $object_response) {
      id
    }
    insert_video_duration_table_one(object: $object_billing) {
      id
    }
  }
`;

export const CREATE_COMMENT_REPLY = gql`
  # mutation MyMutation($object: reply_table_insert_input!) {
  #   insert_reply_table_one(object: $object) {
  #     id
  #   }
  # }

  mutation MyMutation(
    $object_reply: reply_table_insert_input!
    $object_billing: video_duration_table_insert_input!
  ) {
    insert_reply_table_one(object: $object_reply) {
      id
    }
    insert_video_duration_table_one(object: $object_billing) {
      id
    }
  }
`;

export const AGE_DECLARATION = gql`
  mutation MyMutation(
    $id: uuid!
    $age_declared: Boolean!
    $parent_email: String
    $under_aged: Boolean!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        age_declared: $age_declared
        parent_email: $parent_email
        under_aged: $under_aged
      }
    ) {
      id
    }
  }
`;

export const APPROVE_SIGNUP_BY_TEACHER = gql`
  mutation MyMutation(
    $parent_email: String!
    $user_id: uuid!
    $parent_ip: jsonb
  ) {
    insert_parent_approval_one(
      object: {
        approved: true
        parent_email: $parent_email
        user_id: $user_id
        parent_ip: $parent_ip
      }
    ) {
      id
    }
  }
`;

export const UPDATE_PROFILE_BY_USER = gql`
  mutation MyMutation($id: uuid!, $display_name: String, $avatar_url: String) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { display_name: $display_name, avatar_url: $avatar_url }
    ) {
      avatar_url
      display_name
    }
  }
`;

export const GIVE_FEEDBACK_TO_REPLY = gql`
  mutation MyMutation($object: response_feedback_insert_input!) {
    insert_response_feedback_one(
      object: $object
      on_conflict: {
        constraint: response_feedback_pkey
        update_columns: updated_at
      }
    ) {
      id
    }
  }
`;

export const DELETE_FEEDBACK_FOR_REPLY_BY_FEEDBACKER = gql`
  mutation MyMutation(
    $feedback_id: uuid!
    $feedbacker_user_id: uuid!
    $response_id: uuid!
  ) {
    delete_response_feedback_by_pk(
      feedback_id: $feedback_id
      feedbacker_user_id: $feedbacker_user_id
      response_id: $response_id
    ) {
      id
    }
  }
`;

export const CREATE_NEW_ADDRESS_BOOK_RECORD = gql`
  mutation MyMutation($object: address_book_insert_input!) {
    insert_address_book_one(object: $object) {
      id
    }
  }
`;

export const DELETE_ADDRESS_BOOK_BY_ID = gql`
  mutation MyMutation($id: uuid!) {
    delete_address_book_by_pk(id: $id) {
      id
    }
  }
`;

export const ADD_EMAIL_TO_SHARE_LINK_MAIL = gql`
  mutation MyMutation($object: share_link_email_insert_input!) {
    insert_share_link_email_one(object: $object) {
      id
    }
  }
`;

export const MAIN_VIDEO_READ_RECEIPT = gql`
  mutation MyMutation($object: main_video_read_insert_input = {}) {
    insert_main_video_read_one(
      object: $object
      on_conflict: { constraint: main_video_read_pkey, update_columns: [] }
    ) {
      id
    }
  }
`;

export const INSERT_VIDEO_COUNTER = gql`
  mutation MyMutation($access_key: String!) {
    insert_video_counter_one(object: { access_key: $access_key }) {
      id
    }
  }
`;

export const RESPONSE_READ_RECEIPT = gql`
  mutation MyMutation($object: response_video_read_insert_input!) {
    insert_response_video_read_one(
      object: $object
      on_conflict: { constraint: video_read_pkey, update_columns: [] }
    ) {
      id
    }
  }
`;

export const COMMENT_READ_RECEIPT = gql`
  mutation MyMutation($object: reply_video_read_insert_input!) {
    insert_reply_video_read_one(
      object: $object
      on_conflict: { constraint: reply_video_read_pkey, update_columns: [] }
    ) {
      id
    }
  }
`;

export const APPROVE_REPLY_BY_CREATOR = gql`
  mutation MyMutation($id: uuid!) {
    update_response_table_by_pk(
      pk_columns: { id: $id }
      _set: { approved_by_creator: true }
    ) {
      id
    }
  }
`;

export const APPROVE_COMMENT_BY_CREATOR = gql`
  mutation MyMutation($id: uuid!) {
    update_reply_table_by_pk(
      pk_columns: { id: $id }
      _set: { approved_by_creator: true }
    ) {
      id
    }
  }
`;

export const DELETE_VIDEO_BY_CREATOR_USER = gql`
  mutation MyMutation($access_key: String!) {
    update_video_table_by_pk(
      pk_columns: { access_key: $access_key }
      _set: { deleted: true }
    ) {
      id
    }
  }
`;

export const DELETE_RESPONSE_VIDEO = gql`
  mutation MyMutation($id: uuid!) {
    update_response_table_by_pk(
      pk_columns: { id: $id }
      _set: { deleted: true }
    ) {
      id
    }
  }
`;

export const DELETE_REPLY_VIDEO = gql`
  mutation MyMutation($id: uuid!) {
    update_reply_table_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
    }
  }
`;

export const CHANGE_AVATAR_PROFILE = gql`
  mutation MyMutation($id: uuid!, $avatar_url: String!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { avatar_url: $avatar_url }
    ) {
      id
    }
  }
`;

export const REJECT_RESPONSE_VIDEO = gql`
  mutation MyMutation($id: uuid!) {
    update_response_table_by_pk(
      pk_columns: { id: $id }
      _set: { reject_by_creator: true }
    ) {
      id
    }
  }
`;

export const LIKE_REPLY_CONTENT = gql`
  mutation MyMutation($object: response_like_insert_input!) {
    insert_response_like_one(
      object: $object
      on_conflict: {
        constraint: response_like_pkey
        update_columns: created_at
      }
    ) {
      id
    }
  }
`;

export const DELETE_RESPONSE_LIKE = gql`
  mutation MyMutation($response_id: uuid!, $user_id: uuid!) {
    delete_response_like_by_pk(response_id: $response_id, user_id: $user_id) {
      id
    }
  }
`;

export const UPDATE_ONBOARDING = gql`
  mutation MyMutation($id: uuid!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { onboarding: true }) {
      id
    }
  }
`;

export const SEND_INVITE_FROM_ADDRESSBOOK = gql`
  mutation MyMutation($id: uuid!) {
    update_address_book_by_pk(
      pk_columns: { id: $id }
      _set: { invite_sent: true }
    ) {
      id
    }
  }
`;

export const SMART_INDICATOR_UPDATE = gql`
  mutation MyMutation($id: uuid!, $smart_indicator: Boolean!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { smart_indicator: $smart_indicator }
    ) {
      id
      smart_indicator
    }
  }
`;

export const CREATE_NEW_ADDRESS_BOOK_GROUP = gql`
  mutation MyMutation($object: address_book_group_insert_input!) {
    insert_address_book_group_one(object: $object) {
      id
    }
  }
`;

export const ADD_CONTACT_TO_GROUP = gql`
  mutation MyMutation($id: uuid!, $group_id: uuid!) {
    update_address_book_by_pk(
      pk_columns: { id: $id }
      _set: { group_id: $group_id }
    ) {
      id
    }
  }
`;
