import { useApolloClient } from "@apollo/client";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MediaPlayer from "../../components/global/mediaPlayer";
import MainLayout from "../../components/layout/mainLayout";
import {
  CHECK_EMAIL_IN_ADDRESSBOOK,
  CHECK_REPLY_USER_AVATAR,
  GET_RESPONSE_REPLY,
} from "../../util/getQueries";
import _ from "lodash";
import FeedbackModal from "../../components/modal/feedback";
import {
  APPROVE_COMMENT_BY_CREATOR,
  APPROVE_REPLY_BY_CREATOR,
  COMMENT_READ_RECEIPT,
  CREATE_NEW_ADDRESS_BOOK_RECORD,
  DELETE_REPLY_VIDEO,
  DELETE_RESPONSE_LIKE,
  DELETE_RESPONSE_VIDEO,
  LIKE_REPLY_CONTENT,
  REJECT_RESPONSE_VIDEO,
  RESPONSE_READ_RECEIPT,
} from "../../util/insertQueries";
import { auth } from "../../util/nhost";
import DataLoading from "../../components/global/dataLoading";
import DeleteModal from "../../components/modal/deleteModal";
import BottomNotification from "../../components/notification/bottomNotification";
import RejectModal from "../../components/modal/rejectModal";
import SmartIndicator from "../../components/modal/smartIndicator";
import { StateContext } from "../../context/appContext";
import UserAvatarItem from "../../components/navigation/userAvatarItem";
const ResponsePlayer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, responseid } = useParams();
  const client = useApolloClient();
  const [responseData, setResponseData] = useState({});
  const [amICreator, setAmICreator] = useState(false);
  const [controlReply, setControlReply] = useState(false);
  const [controlComment, setControlComment] = useState(false);
  const [student2Student, setStudent2Student] = useState(false);
  const [amIReplyCreator, setAmIReplyCreator] = useState(false);
  const [creatorId, setCreatorId] = useState(null);
  const [responseEmail, setResponseEmail] = useState({});
  const [replyDeleteModal, setReplyDeleteModal] = useState(false);
  const [commentDeleteModal, setCommentDeleteModal] = useState(false);
  const [replyRejectModal, setReplyRejectModal] = useState(false);
  const [rnd, setRnd] = useState();
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState("alert-info");
  const handleAddNewToAddressBook = () => {
    client
      .mutate({
        variables: {
          object: {
            name: responseData.name,
            email: responseData.email,
            user_id: auth.getClaim("x-hasura-user-id"),
          },
        },
        mutation: CREATE_NEW_ADDRESS_BOOK_RECORD,
      })
      .then((res) => {
        // console.log(res);
        getResponseData();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleGiveLikeToReply = () => {
    // console.log(responseData?.mylike?.aggregate?.count);

    if (responseData?.mylike?.aggregate?.count === 0) {
      client
        .mutate({
          variables: {
            object: {
              response_id: responseid,
              user_id: auth.getClaim("x-hasura-user-id"),
            },
          },
          mutation: LIKE_REPLY_CONTENT,
        })
        .then((res) => {
          // console.log(res);
          getResponseData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      client
        .mutate({
          variables: {
            response_id: responseid,
            user_id: auth.getClaim("x-hasura-user-id"),
          },
          mutation: DELETE_RESPONSE_LIKE,
        })
        .then((res) => {
          // console.log(res);
          getResponseData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReadReceiptForReply = async (videoId) => {
    client
      .mutate({
        variables: {
          object: {
            user_id: auth.getClaim("x-hasura-user-id"),
            video_id: videoId,
          },
        },
        mutation: RESPONSE_READ_RECEIPT,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [replyUserUserData, setReplyUserData] = useState(null);
  const getReplyUserAvatar = async (email) => {
    try {
      const { loading, error, data } = await client.query({
        query: CHECK_REPLY_USER_AVATAR,
        fetchPolicy: "no-cache",
        variables: {
          _ilike: email,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        console.log(data);
        setReplyUserData(_.head(data?.users));
      }
    } catch (error) {
      console.log(error);

      /// error message need notification
    }
  };
  const getResponseData = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_RESPONSE_REPLY,
        fetchPolicy: "no-cache",
        variables: {
          id: responseid,
          user_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        getReplyUserAvatar(data?.response_table_by_pk?.email);
        checkEmailInAddressBook(
          data?.response_table_by_pk?.email,
          data?.response_table_by_pk?.name
        );
        setResponseData(data?.response_table_by_pk);

        handleReadReceiptForReply(data?.response_table_by_pk.id);
        setStudent2Student(
          data?.response_table_by_pk?.video_table?.student_to_student
        );
        setAmIReplyCreator(
          data?.response_table_by_pk?.email === auth.currentUser?.email
        );
        setControlReply(data?.response_table_by_pk?.video_table?.control_reply);
        setControlComment(
          data?.response_table_by_pk?.video_table?.control_comment
        );

        setCreatorId(data?.response_table_by_pk?.video_table?.creator_id);
        setAmICreator(
          data?.response_table_by_pk?.video_table?.creator_id ===
            auth.getClaim("x-hasura-user-id")
        );
        if (!_.isEmpty(data?.response_table_by_pk?.reply_tables)) {
          data?.response_table_by_pk?.reply_tables?.map((item) =>
            handleReadReceiptForComment(item.id)
          );
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    } catch (error) {
      console.log(error);

      /// error message need notification
    }
  };
  const checkEmailInAddressBook = async (email, name) => {
    try {
      const { loading, error, data } = await client.query({
        query: CHECK_EMAIL_IN_ADDRESSBOOK,
        fetchPolicy: "no-cache",
        variables: {
          email: email,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        if (!_.isEmpty(data.address_book)) {
          setResponseEmail({ email: email, name: name });
        }
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getResponseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseid]);

  const handleReadReceiptForComment = async (videoId) => {
    client
      .mutate({
        variables: {
          object: {
            user_id: auth.getClaim("x-hasura-user-id"),
            video_id: videoId,
          },
        },
        mutation: COMMENT_READ_RECEIPT,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApproveReplyByCreator = () => {
    client
      .mutate({
        variables: {
          id: responseid,
        },
        mutation: APPROVE_REPLY_BY_CREATOR,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })
      .then((res) => {
        // console.log(res);
        getResponseData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleApproveCommentByCreator = (commentid) => {
    client
      .mutate({
        variables: {
          id: commentid,
        },
        mutation: APPROVE_COMMENT_BY_CREATOR,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })
      .then((res) => {
        // console.log(res);
        getResponseData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const navigate = useNavigate();
  const handleDeleteReplyVideo = () => {
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    client
      .mutate({
        variables: {
          id: responseid,
        },
        mutation: DELETE_RESPONSE_VIDEO,
      })
      .then((res) => {
        // console.log(res);
      })
      .then((res) => {
        setReplyDeleteModal(false);
        setNotificationType("alert-info");
        setNotification("Reply deleted");
      })
      .then(() => {
        setTimeout(() => {
          amICreator
            ? navigate(`/topic/${id}`, { replace: true })
            : navigate(`/topics`, { replace: true });
        }, 300);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleRejectReplyVideo = () => {
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    client
      .mutate({
        variables: {
          id: responseid,
        },
        mutation: REJECT_RESPONSE_VIDEO,
      })
      .then((res) => {
        // console.log(res);
      })
      .then((res) => {
        setReplyDeleteModal(false);
        setNotificationType("alert-info");
        setNotification("Reply rejected");
      })
      .then(() => {
        setTimeout(() => {
          navigate(`/topic/${id}`, { replace: true });
        }, 300);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedCommentToDelete, setSelectedCommentToDelete] = useState(null);
  const handleDeleteComment = (id) => {
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    client
      .mutate({
        variables: {
          id: selectedCommentToDelete,
        },
        mutation: DELETE_REPLY_VIDEO,
      })
      .then((res) => {
        // console.log(res);
      })
      .then((res) => {
        setSelectedCommentToDelete(null);
        setCommentDeleteModal(false);
        setNotificationType("alert-info");
        setNotification("comment deleted");
      })
      .then(() => {
        getResponseData();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const state = useContext(StateContext);
  return (
    <MainLayout>
      {isLoading ? (
        <div className=" h-full w-full items-center justify-center flex flex-col py-20">
          <DataLoading />
        </div>
      ) : (
        <div className=" flex flex-col w-full items-center">
          <div className=" flex flex-col w-full items-start pb-4">
            <div className="text-lg font-bold breadcrumbs -ml-4">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/topic/${id}`}>
                    {responseData?.video_table?.title}
                  </Link>
                </li>
                <li>{responseData?.name}</li>
              </ul>
            </div>
          </div>{" "}
          <div className="  flex flex-col w-full  items-center py-2 pb-4 space-y-4  max-w-3xl">
            <div className=" flex flex-col space-y-6  w-full">
              <MediaPlayer data={responseData} />
              <div className=" flex flex-row items-center justify-end">
                {" "}
                {amICreator
                  ? controlReply &&
                    !responseData?.approved_by_creator && (
                      <div className="">
                        {" "}
                        <button
                          className=" btn  btn-outline mr-2 hover:bg-red-500"
                          onClick={() => setReplyRejectModal(true)}>
                          Reject
                        </button>
                        <button
                          className=" btn btn-primary"
                          onClick={() => handleApproveReplyByCreator()}>
                          Approve
                        </button>
                      </div>
                    )
                  : controlReply &&
                    !responseData?.approved_by_creator &&
                    responseData?.reject_by_creator && (
                      <div className=" badge bg-red-500">Rejected </div>
                    )}
              </div>
              <div className=" flex flex-col space-y-2">
                <div className=" flex flex-row items-center justify-between">
                  <div className=" flex flex-row space-x-2 items-center">
                    <UserAvatarItem url={replyUserUserData?.avatar_url} />{" "}
                    <span className=" text-xl font-bold  ">
                      {responseData?.name}
                    </span>
                    {_.isEmpty(responseEmail) && (
                      <button
                        className=" btn  btn-secondary btn-xs"
                        onClick={() => handleAddNewToAddressBook()}>
                        Add to addressbook
                      </button>
                    )}
                  </div>

                  <button
                    onClick={() => handleGiveLikeToReply()}
                    className=" flex flex-row items-center ">
                    {responseData?.response_likes_aggregate?.aggregate?.count >
                      0 && (
                      <span className=" badge text-white bg-red-500 border-0">
                        {
                          responseData?.response_likes_aggregate?.aggregate
                            ?.count
                        }
                      </span>
                    )}{" "}
                    <img
                      src="/assets/icons/like.svg"
                      className=" w-10"
                      alt=""
                    />
                  </button>
                </div>
                <span className=" text-sm font-light">
                  {/* {moment(data?.created_at).format("MMMM Do YYYY, h:mm:ss a")} */}
                  {moment().diff(moment(responseData.created_at), "days") < 3
                    ? moment(responseData.created_at).fromNow()
                    : moment(responseData.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                </span>

                <span>view</span>
                {(amICreator || amIReplyCreator || student2Student) && (
                  <div className=" flex flex-col">
                    <div className=" flex flex-row flex-wrap  max-w-3xl w-full items-start">
                      {!responseData?.reject_by_creator && (
                        <Link
                          to={`/comment/${responseid}`}
                          className=" btn btn-primary  w-full mr-2 mb-2 max-w-lg sm:max-w-xs">
                          Add comment
                        </Link>
                      )}
                      {(amICreator || amIReplyCreator) && (
                        <button
                          className=" btn btn-outline mr-2 mb-2 "
                          onClick={() => setReplyDeleteModal(true)}>
                          Delete reply
                        </button>
                      )}{" "}
                      {state?.userData?.smart_indicator && amICreator && (
                        <SmartIndicator email={responseData?.email} />
                      )}
                    </div>
                    {!responseData?.reject_by_creator && (
                      <FeedbackModal responseId={responseid} />
                    )}
                  </div>
                )}
              </div>
              <div className=" w-full border-b-2"></div>
              <div className="  flex flex-col   items-center py-2 pb-4 space-y-4 max-w-3xl w-full">
                <div className=" flex flex-row items-center justify-start w-full text-xl font-bold">
                  {_.size(responseData?.reply_tables)} Comment
                  {_.size(responseData?.reply_tables) > 1 && "s"}
                </div>
                <div className=" flex flex-col w-full space-y-6">
                  {responseData?.reply_tables?.map((item) =>
                    !controlComment ||
                    (controlComment &&
                      (item?.user_id === auth.getClaim("x-hasura-user-id") ||
                        amICreator ||
                        item?.user_id === creatorId ||
                        item?.approved_by_creator)) ? (
                      <div
                        className=" flex flex-col space-y-0 w-full"
                        key={item.id}>
                        <MediaPlayer
                          data={item}
                          close={(e) => console.log(e)}
                        />
                        <div className=" flex flex-row space-x-2   items-center py-2">
                          <UserAvatarItem url={item?.user?.avatar_url} />{" "}
                          <span className=" text-xl font-bold ">
                            {item?.user?.display_name}
                          </span>{" "}
                          {item?.user_id !== creatorId &&
                            controlComment &&
                            !item?.approved_by_creator &&
                            amICreator && (
                              <button
                                className=" btn  btn-primary btn-sm"
                                onClick={() =>
                                  handleApproveCommentByCreator(item?.id)
                                }>
                                Approve
                              </button>
                            )}
                          {controlComment &&
                            item?.user_id ===
                              auth.getClaim("x-hasura-user-id") &&
                            !item?.approved_by_creator &&
                            item?.user_id !== creatorId && (
                              <div className=" badge badge-secondary mr-2 mb-2">
                                {" "}
                                Waiting for approval
                              </div>
                            )}
                          {(amICreator ||
                            item?.user_id ===
                              auth.getClaim("x-hasura-user-id")) && (
                            <button
                              className=" btn btn-outline btn-sm "
                              onClick={() => {
                                setSelectedCommentToDelete(item.id);
                                setCommentDeleteModal(true);
                              }}>
                              {controlComment &&
                              !item?.approved_by_creator &&
                              amICreator &&
                              item?.user_id !== creatorId
                                ? "Reject/Delete comment"
                                : "Delete comment"}
                            </button>
                          )}
                        </div>
                        <span className=" text-sm font-light">
                          {/* {moment(data?.created_at).format("MMMM Do YYYY, h:mm:ss a")} */}
                          {moment().diff(moment(item?.created_at), "days") < 3
                            ? moment(item?.created_at).fromNow()
                            : moment(item?.created_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                        </span>
                      </div>
                    ) : (
                      <div className=" flex space-y-4 flex-col p-2 rounded-lg border-2 bg-base-100 shadow-sm">
                        <span className=" text-xl font-bold ">
                          {item?.user?.display_name}
                        </span>
                        <span className=" text-sm font-light">
                          {/* {moment(data?.created_at).format("MMMM Do YYYY, h:mm:ss a")} */}
                          {moment().diff(moment(item?.created_at), "days") < 3
                            ? moment(item?.created_at).fromNow()
                            : moment(item?.created_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                        </span>
                        <div className=" badge badge-secondary">
                          Wait for approval
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <RejectModal
        open={replyRejectModal}
        title="Reject Reply"
        close={() => setReplyRejectModal(false)}
        desc="Are you sure you want to reject this reply?"
        deleteAction={() => handleRejectReplyVideo()}
      />
      <DeleteModal
        open={replyDeleteModal}
        title="Delete Reply"
        close={() => setReplyDeleteModal(false)}
        desc="Are you sure you want to delete this reply? By deleting this reply
        and all its comments will get deleted as well."
        deleteAction={() => handleDeleteReplyVideo()}
      />
      <DeleteModal
        open={commentDeleteModal}
        title="Delete comment"
        close={() => {
          setSelectedCommentToDelete(null);
          setCommentDeleteModal(false);
        }}
        desc="Are you sure you want to delete this comment? "
        deleteAction={() => handleDeleteComment()}
      />

      <BottomNotification
        type={notificationType}
        message={notification}
        rnd={rnd}
      />
      {/* <FeedbackModal
        responseId={responseid}
        open={openFeedbackModal}
        close={(e) => setOpenFeedbackModal(false)}
      /> */}
    </MainLayout>
  );
};

export default ResponsePlayer;

// ((controlComment &&
//   (item?.user_id === auth.getClaim("x-hasura-user-id") ||
//     amICreator)) ||
//   item.user_id === creatorId) &&
