import React, { useState } from "react";
import axios from "axios";
const StandRegister = () => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async () => {
    axios
      .post(
        "https://nhost.tautapp.co:8080/api/rest/stand/register",
        {
          name: name,
          email: email,
        },
        {
          headers: {
            "content-type": "application/json",
            "x-hasura-role": "public",
          },
        }
      )
      .then((response) => setSubmitted(true));
  };
  return (
    <div className=" flex flex-col w-screen h-full bg-white items-center justify-center  ">
      <div className=" flex flex-col gap-4 max-w-sm ">
        <span>
          We're in private beta, so we're limiting public access for the time
          being. Fill the form below to join our waiting list and we'll reach
          out soon.
        </span>
        <input
          type="text"
          placeholder="Your name"
          className="input input-bordered"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="email"
          className="input input-bordered"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={() => handleSubmit()} className="btn  btn-primary">
          Register
        </button>
      </div>
      <div className={`modal ${submitted && "modal-open"} -top-2 `}>
        <div className="modal-box">
          <h3 className="font-bold text-lg">Thank you!</h3>
          <p className="py-4">We will reach out to you soon 😊</p>
          <div className="modal-action">
            {/* <button
              onClick={() => window.open("/", "_self")}
              className="btn  btn-outline">
              close
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandRegister;
