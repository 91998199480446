import { useContext, useEffect, useState } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { auth } from "../util/nhost";
import { useAuth } from "@nhost/react-auth";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { DispatchContext } from "../context/appContext";

export const useCheckUserData = () => {
  const { signedIn } = useAuth();

  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [roles, setRoles] = useState(null);
  const dispatch = useContext(DispatchContext);
  const client = useApolloClient();
  const navigate = useNavigate();
  const getMySelfData = async () => {
    let arr = [];
    try {
      const { loading, error, data } = await client.query({
        query: GET_SELF,
        fetchPolicy: "no-cache",
        variables: {
          user_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (loading) {
        return <div>loading</div>;
      }

      if (!loading && !error && data) {
        if (!data.me.age_declared) {
          navigate("/age");
        }

        if (data.me.age_declared) {
          if (data.me.under_aged) {
            if (_.isEmpty(data.parent_approval)) {
              navigate("/parentapproval");
            }
          }
        }

        dispatch({ type: "updateUserData", payload: data.me });

        data.me.account.account_roles.map((i) => arr.push(i.role));
        dispatch({
          type: "updateUserRoles",
          payload: { roles: arr, isLoaded: true },
        });
        setRoles(arr);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };

  useEffect(
    () => {
      signedIn && getMySelfData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signedIn]
  );
  return [loaded, roles];
};

const GET_SELF = gql`
  query getSelf($user_id: uuid!) {
    me: users_by_pk(id: $user_id) {
      id
      display_name
      avatar_url
      age_declared
      under_aged
      onboarding
      smart_indicator
      account {
        id
        email
        account_roles {
          role
        }
      }
    }
    parent_approval(where: { user_id: { _eq: $user_id } }) {
      approved
    }
  }
`;
