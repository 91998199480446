/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  GET_SHARE_LINK_EMAIL_PER_VIDEO,
  SEARCH_MY_ADDRESS_BOOK,
} from "../../util/getQueries";
import {
  ADD_EMAIL_TO_SHARE_LINK_MAIL,
  UPDATE_ONBOARDING,
} from "../../util/insertQueries";
import { auth } from "../../util/nhost";
import BottomNotification from "../notification/bottomNotification";
import _ from "lodash";
import { Link } from "react-router-dom";
import { validateEmail } from "../global/validateEmail";
import { wrongEmail } from "../global/wrongEmail";
import { DispatchContext, StateContext } from "../../context/appContext";
import ReactGA from "react-ga4";
import ItemHelp from "./itemHelp";

const ShareTopicLink = ({ open, link, close, videoAccessKey, pincode }) => {
  const [sharedLinkEmail, setSharedLinkEmail] = useState([]);
  const [notification, setNotification] = useState("");
  const [rnd, setRnd] = useState();
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setNotification("Topic's link copied");
    setRnd(Math.floor(Math.random() * 10 + 1));
    ReactGA.event({
      category: "Link sharing",
      action: "Topic link copied",
    });
  };

  const handleCopyIframe = () => {
    navigator.clipboard.writeText(
      ` <iframe
        src='${link}'
        title="description"
        width="100%"
        height="800"
        frameborder="0"></iframe>`
    );
    setNotification("iFrame codes  copied!@");
    setRnd(Math.floor(Math.random() * 10 + 1));
    ReactGA.event({
      category: "Link sharing",
      action: "Topic link copied",
    });
  };

  const client = useApolloClient();
  const [searchValue, setSearchValue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const getShareLinkEmailList = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_SHARE_LINK_EMAIL_PER_VIDEO,
        fetchPolicy: "no-cache",
        variables: {
          access_key: videoAccessKey,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setSharedLinkEmail(data.video_table_by_pk.share_link_emails);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  const [data, setData] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [offset, setOffset] = useState(0);

  const handleAddShareLinkEmail = (data) => {
    client
      .mutate({
        variables: {
          object: {
            access_key: videoAccessKey,
            email: data.email,
            name: data.name,
            user_id: auth.getClaim("x-hasura-user-id"),
            url: link,
          },
        },
        mutation: ADD_EMAIL_TO_SHARE_LINK_MAIL,
      })
      .then((res) => {
        setName("");
        setEmail("");
        getMyAddressBook();
        getShareLinkEmailList();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const getMyAddressBook = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: SEARCH_MY_ADDRESS_BOOK,
        fetchPolicy: "no-cache",
        variables: {
          offset: offset,
          limit: 10,
          _ilike: `%${searchValue}%`,
          _ilike1: `%${searchValue}%`,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setData(data.address_book);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    getMyAddressBook();
    getShareLinkEmailList();
  }, [videoAccessKey]);
  useEffect(() => {
    getMyAddressBook();
  }, [searchValue]);

  const [tabView, setTabView] = useState("addressbook");
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);

  const handleClose = () => {
    if (!state?.userData?.onboarding) {
      client
        .mutate({
          variables: {
            id: auth.getClaim("x-hasura-user-id"),
          },
          mutation: UPDATE_ONBOARDING,
        })
        .then((res) => {
          dispatch({ type: "finishOnboarding", payload: true });
        })
        .then(() => close())

        .catch((error) => {
          console.log(error);
        });
    } else {
      close();
    }
  };
  return (
    <div className={`modal ${open && "modal-open"} -top-2 p-2`}>
      <div className="modal-box max-w-xl w-full ">
        <h1 className="font-bold  text-2xl py-2">Share the Topic link</h1>
        <h3 className="font-bold text-lg">
          Anyone can access this Topic using this link
        </h3>
        <div className=" flex flex-row flex-wrap items-center space-x-2 py-2">
          <p className="py-4  break-all">
            <code>{link}</code>
          </p>

          {/* <div className=" text-2xl font-bold  bg-secondary text-base-100 p-2 rounded-lg">
            PIN:{pincode}
          </div> */}
          {/* <div className=" flex flex-row items-center space-x-2">
            <span>Or you can use </span>
            <kbd className="kbd kbd-sm">ctrl</kbd> <span>{` + `}</span>
            <kbd className="kbd kbd-sm">shift</kbd>
            <span>to copy link </span>
          </div> */}
        </div>
        <div className=" flex flex-row items-center  space-x-2">
          <button
            className=" btn btn-sm btn-accent "
            onClick={() => handleCopyLink()}>
            copy link
          </button>
          <button
            className=" btn btn-sm btn-primary "
            onClick={() => handleCopyIframe()}>
            Use it as iframe
          </button>
          <ItemHelp text="You can use VideoBit topic in your website or tool by iFrame. Just paste code in proper code place" />
        </div>
        <div>
          <h1 className="font-bold  text-2xl py-2">
            Send link directly by email
          </h1>

          <div className=" flex flex-row flex-wrap items-center">
            {sharedLinkEmail.map((item) => (
              <div className="tooltip" key={item.id} data-tip={item.email}>
                <div className=" badge  cursor-default mr-2 mb-2  badge-secondary border-0">
                  <img
                    src="/assets/icons/check.svg"
                    className="  w-3 mr-2"
                    alt=""
                  />
                  {item.name}
                </div>
              </div>
            ))}
          </div>

          {_.size(sharedLinkEmail) < 5 && (
            <div>
              <div className="tabs">
                <div
                  className={`tab tab-lifted  w-1/2 ${
                    tabView === "addressbook" && "tab-active"
                  }`}
                  onClick={() => setTabView("addressbook")}>
                  My Address book
                </div>
                <div
                  className={`tab tab-lifted w-1/2 ${
                    tabView === "manual" && "tab-active"
                  }`}
                  onClick={() => setTabView("manual")}>
                  Manual
                </div>
              </div>
              <div className=" w-full border border-t-0 py-4 p-2 rounded-b-md">
                {tabView === "addressbook" ? (
                  <div>
                    <div className=" py-4 flex flex-row items-center space-x-4">
                      <input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        type="text"
                        placeholder="Search here"
                        className="input input-bordered "
                        required
                      />
                      <Link to="/addressbook" className=" btn-link text-xs">
                        My addressbook
                      </Link>
                    </div>
                    <div className=" flex flex-row flex-wrap items-center">
                      {" "}
                      {_.differenceBy(data, sharedLinkEmail, "email").map(
                        (item) => (
                          <div
                            className="tooltip"
                            key={item.id}
                            data-tip={item.email}>
                            <div
                              className=" badge  mr-2 mb-2 cursor-pointer hover:badge-primary"
                              onClick={() => handleAddShareLinkEmail(item)}>
                              {item.name}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div className=" flex flex-row items-end flex-wrap space-x-2">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Name</span>
                      </label>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="name"
                        className="input input-bordered "
                        required
                      />
                    </div>
                    <div className="form-control">
                      <label className="label">
                        {wrongEmail(email)}
                        <span className="label-text">Email</span>
                      </label>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="email"
                        className="input input-bordered"
                        required
                      />
                    </div>
                    {name !== "" && validateEmail(email) && (
                      <button
                        className=" btn btn-primary"
                        onClick={() =>
                          handleAddShareLinkEmail({ name: name, email: email })
                        }>
                        Send
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="modal-action">
          <label className="btn" onClick={() => handleClose()}>
            Close
          </label>
        </div>
      </div>
      <BottomNotification
        type="alert-success"
        message={notification}
        rnd={rnd}
      />
    </div>
  );
};

export default ShareTopicLink;
