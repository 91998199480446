export const recordingTypes = [
  {
    type: "video",
    title: "Record video",
    desc: "Video is personal. Show yourself and your body language",
    img: "/assets/images/videorecording.jpg",
    linkForTopic: "/create/video",
    linkForReply: "video",
    startHelper:
      "You look great! 🤩 Double-check the background and you're ready to go!",
    learn: "https://www.youtube.com/watch?v=wcepHDSdPgA",
  },
  {
    type: "audio",
    title: "Audio only",
    desc: "Audio focuses on the message itself, without any distractions",
    img: "/assets/images/audiorecording.jpg",
    linkForTopic: "/create/audio",
    linkForReply: "audio",
    startHelper:
      "It's important to speak clearly and concisely. Warm up your voice 😊",
    learn: "https://www.youtube.com/watch?v=aE7oL3DxKRo",
  },
  {
    type: "screen",
    title: "Record your screen",
    desc: "Record your computer screen to present something",
    img: "/assets/images/screenrecording.jpg",
    linkForTopic: "/create/screen",
    linkForReply: "screen",
    startHelper:
      "Your screen will be visible so close any windows or tabs you don't want others to see 🙂",
    learn: "https://www.youtube.com/watch?v=IUWIpfJxz_Q",
  },
];
// export const recordingTypes = [
//   {
//     type: "video",
//     title: "Record video",
//     desc: "Video is personal. Show yourself and your body language",
//     img: "https://images.unsplash.com/photo-1594394489098-74ac04c0fc2e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dmlkZW8lMjByZWNvcmRpbmd8ZW58MHx8MHx8&w=1000&q=80",
//     linkForTopic: "/create/video",
//     startHelper:
//       "You look great! 🤩 Double-check the background and you're ready to go!",
//   },
//   {
//     type: "audio",
//     title: "Audio only",
//     desc: "Audio focuses on the message itself, without any distractions",
//     img: "https://y31uv4ra1.vo.llnwd.net/content/wp/tweaklibrary_com/uploads/2020/05/best-Audio-recording-software.jpg",
//     linkForTopic: "/create/audio",
//     startHelper:
//       "It's important to speak clearly and concisely. Warm up your voice 😊",
//   },
//   {
//     type: "screen",
//     title: "Record your screen",
//     desc: "Record your computer screen to present something",
//     img: "https://static.teamviewer.com/resources/2019/04/TeamViewer-Screen-Recorder-laptop-1024x683.jpg",
//     linkForTopic: "/create/screen",
//     startHelper:
//       "Your screen will be visible so close any windows or tabs you don't want others to see 🙂",
//   },
// ];
