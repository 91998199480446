import React from "react";
import AudioPlayer from "./audioPlayer";
import VideoPlayer from "./videoPlayer";

const PreviewPlayer = ({ src, type }) => {
  function renderMedia(type) {
    switch (type) {
      case "video":
        return <VideoPlayer src={src} />;

      case "screen":
        return <VideoPlayer src={src} />;

      case "":
        return <VideoPlayer src={src} />;

      case "audio":
        return <AudioPlayer src={src} />;

      default:
        break;
    }
  }
  return (
    src && (
      <div className=" flex flex-col w-full max-w-3xl">{renderMedia(type)}</div>
    )
  );
};

export default PreviewPlayer;
