import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_ALL_ACTIVE_FEEDBACK } from "../../util/getQueries";
import {
  DELETE_FEEDBACK_FOR_REPLY_BY_FEEDBACKER,
  GIVE_FEEDBACK_TO_REPLY,
} from "../../util/insertQueries";
import { auth } from "../../util/nhost";
import ReactGA from "react-ga4";

const FeedbackModal = ({ responseId }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const client = useApolloClient();

  const getAllFeedback = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_ALL_ACTIVE_FEEDBACK,
        fetchPolicy: "no-cache",
        variables: {
          response_id: responseId,
          user_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setFeedbacks(data.feedback_data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseId]);

  const handleFeedback = (item) => {
    if (item.myfeedback.aggregate.count === 0) {
      client
        .mutate({
          variables: {
            object: {
              feedbacker_user_id: auth.getClaim("x-hasura-user-id"),
              response_id: responseId,
              feedback_id: item.id,
            },
          },
          mutation: GIVE_FEEDBACK_TO_REPLY,
        })
        .then((res) => {
          ReactGA.event({
            category: "feedback",
            action: "feedback_clicked",
          });
          console.log(res);
          getAllFeedback();
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      client
        .mutate({
          variables: {
            feedbacker_user_id: auth.getClaim("x-hasura-user-id"),
            response_id: responseId,
            feedback_id: item.id,
          },
          mutation: DELETE_FEEDBACK_FOR_REPLY_BY_FEEDBACKER,
        })
        .then((res) => {
          console.log(res);
          getAllFeedback();
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={` -top-2 `}>
      <span className=" text-xl  font-bold">Feedback</span>
      <div className=" ">
        <br />
        <div className=" grid grid-cols-3 sm:grid-cols-6 gap-6 ">
          {" "}
          {feedbacks.map((item) => (
            <div
              onClick={() => handleFeedback(item)}
              key={item.id}
              className="indicator w-full ">
              {item.response_feedbacks_aggregate.aggregate.count > 0 && (
                <span className="indicator-item badge badge-secondary h-8 w-8 text-lg">
                  {item.response_feedbacks_aggregate.aggregate.count}
                </span>
              )}

              <div className=" hover:bg-base-300 cursor-pointer hover:shadow-md  p-2 rounded-md items-center justify-center space-y-2 shadow-sm grid  w-full aspect-square bg-base-100 place-items-center">
                <img
                  className="  w-12"
                  src={`/assets/icons/${item.image}`}
                  alt=""
                />
                <span className=" text-center text-sm">{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
