import React from "react";

const DataLoading = () => {
  return (
    <div className=" flex flex-col w-full items-center py-4 p-2  ">
      <img src="/assets/images/loading.svg" className=" w-20 " alt="" />
    </div>
  );
};

export default DataLoading;
