import React from "react";
import { Link } from "react-router-dom";

const SearchCard = ({ data, close }) => {
  function coverImageAddress() {
    switch (data.content_type) {
      case "video":
        return data?.thumbnail_key === null
          ? `/assets/images/videorecording.jpg`
          : `${process.env.REACT_APP_API_KEY}/storage/o/${data?.thumbnail_key}`;

      case "":
        return data?.thumbnail_key === null
          ? `/assets/images/videorecording.jpg`
          : `${process.env.REACT_APP_API_KEY}/storage/o/${data?.thumbnail_key}`;

      case "audio":
        return `/assets/images/audiorecording.jpg`;
      case "screen":
        return `/assets/images/screenrecording.jpg`;

      default:
        break;
    }
  }
  return (
    <Link
      to={`/topic/${data.access_key}`}
      onClick={() => close()}
      className=" flex flex-row items-center mb-4 py-1 border-b-2 rounded-lg hover:bg-base-300">
      <img
        className=" h-16 w-16 rounded-lg object-cover aspect-square shadow-md mr-6 "
        src={coverImageAddress()}
        alt=""
      />
      <span>{data?.title}</span>
    </Link>
  );
};

export default SearchCard;
