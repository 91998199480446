import { Link } from "react-router-dom";
import React, { useState } from "react";
import { auth } from "../util/nhost";
import MainLayout from "../components/layout/mainLayout";
import AuthWrapper from "./authWrapper";
import { useNavigate } from "react-router";
import BottomNotification from "../components/notification/bottomNotification";
import ScreenLoading from "../components/global/screenLoading";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassord, setConfirmPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [code, setCode] = useState("");

  const [rnd, setRnd] = useState();
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState("alert-info");
  async function handleSubmit(e) {
    setLoading(true);
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    setNotification("");
    e.preventDefault();

    try {
      await auth.requestPasswordChange(email).then((res) => {
        console.log(res);

        setTimeout(() => {
          setLoading(false);
          setNotificationType("alert-info");
          setNotification("Check your email for verification code");
        }, 100);

        setTimeout(() => {
          setCurrentStep(2);
        }, 1000);
      });
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoading(false);

        setNotificationType("alert-info");
        setNotification("Email does not exist");
      }, 100);
    }
  }
  async function handleChangePass(e) {
    setLoading(true);
    setNotification("");
    e.preventDefault();

    try {
      await auth.confirmPasswordChange(password, code).then(() =>
        setTimeout(() => {
          setLoading(false);

          setNotificationType("alert-info");
          setNotification(
            "Password changed successfully. You can login now with new password"
          );
        }, 100)
      );
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoading(false);

        setNotificationType("alert-info");
        setNotification("There is issue, try again");
      }, 100);
    }

    setTimeout(() => {
      navigate("/");
    }, 1000);
  }
  return (
    <MainLayout>
      <AuthWrapper
        header="Reset password"
        desc={
          currentStep === 1
            ? "After entering your email, we'll send you a verification code that lets you create a new password for your account."
            : "Please use the verification code we sent to your email and create a new password for your account."
        }
        footer={
          <span>
            Already have an account?{" "}
            <Link to="/login" className="link link-primary">
              Log in
            </Link>
          </span>
        }>
        <div className="card-body">
          <div className="form-control">
            {currentStep === 1 ? (
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className=" flex flex-col space-y-2">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="text"
                  placeholder="email"
                  className="input input-bordered"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="flex flex-row justify-end items-center space-x-2 py-6">
                  <button
                    type="submit"
                    className={` w-full ${
                      email === "" ? " btn  btn-disabled" : " btn btn-primary"
                    }`}
                    disabled={email === ""}>
                    SEND ME RESET PASSWORD CODE
                  </button>
                </div>
              </form>
            ) : (
              <div className=" flex flex-col space-y-4">
                <span className="  text-xl">
                  Verification code is sent to {email}.
                </span>
                <form
                  autoComplete="off"
                  onSubmit={handleChangePass}
                  className=" flex flex-col space-y-2">
                  <div className="w-full ">
                    <label className="label">
                      <span className="label-text">Verification code</span>
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      placeholder="verification code"
                      className="input input-bordered w-full"
                      required
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Password</span>
                    </label>
                    <input
                      type="password"
                      placeholder="password"
                      className="input input-bordered"
                      required
                      value={password}
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Confirm new password</span>
                    </label>
                    <input
                      type="password"
                      placeholder="password"
                      className="input input-bordered"
                      required
                      value={confirmPassord}
                      autoComplete="new-password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row justify-end items-center space-x-2 py-6">
                    <button
                      type="submit"
                      className={`btn ${
                        code === "" ||
                        password === "" ||
                        password !== confirmPassord
                          ? "btn-disabled  w-full"
                          : " btn-primary  w-full"
                      }`}
                      disabled={code === "" || password === ""}>
                      Change my password
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </AuthWrapper>
      <ScreenLoading open={loading} />

      <BottomNotification
        type={notificationType}
        message={notification}
        rnd={rnd}
      />
    </MainLayout>
  );
};

export default ResetPasswordPage;
