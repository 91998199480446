import React, { useContext } from "react";
import { StateContext } from "../../context/appContext";

const CreateContentStatus = () => {
  const state = useContext(StateContext);
  return (
    <div className=" max-w-lg w-full">
      <ul className="steps w-full cursor-default  ">
        <li className={`step ${state.recordingStep === 1 && " step-primary"}`}>
          Select type
        </li>
        <li className={`step ${state.recordingStep === 2 && " step-primary"}`}>
          Record
        </li>
        <li className={`step ${state.recordingStep === 3 && " step-primary"}`}>
          Publish
        </li>
      </ul>
    </div>
  );
};

export default CreateContentStatus;
