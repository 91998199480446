import React from "react";

const RejectModal = ({ open, title, desc, close, deleteAction }) => {
  return (
    <div className={`modal ${open && "modal-open"} -top-2 `}>
      <div className="modal-box">
        <h3 className="font-bold text-lg">{title}</h3>
        <p className="py-4">{desc}</p>
        <div className="modal-action">
          <button onClick={() => close()} className="btn  btn-outline">
            close
          </button>
          <button className="btn  btn-primary" onClick={() => deleteAction()}>
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;
