import React, { useContext, useEffect, useState } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { auth } from "../util/nhost";
import { Link, useNavigate } from "react-router-dom";
import { DispatchContext, StateContext } from "../context/appContext";

import {
  CHANGE_AVATAR_PROFILE,
  SMART_INDICATOR_UPDATE,
  UPDATE_PROFILE_BY_USER,
} from "../util/insertQueries";
import DataLoading from "../components/global/dataLoading";
import MainLayout from "../components/layout/mainLayout";
import { GET_MY_FEEDBACK_BADGES } from "../util/getQueries";
import AvatarUploadModal from "../components/modal/avatarUploadModal";
import _ from "lodash";
import AvatarMakerModal from "../components/modal/avatarMakerModal";
const ProfilePage = () => {
  const client = useApolloClient();
  const [data, setData] = useState({});
  const state = useContext(StateContext);
  const [editMode, setEditMode] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);

  const [avatarMakerOpen, setAvatarMakerOpen] = useState(false);
  useEffect(() => {
    setDisplayName(data?.display_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMySelfData = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_SELF,
        fetchPolicy: "no-cache",
        variables: {
          user_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setData(data.me);
        setSmartIndicator(data?.me?.smart_indicator || false);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout().then(() => navigate("/login"));
  };

  const [feedbacks, setFeedbacks] = useState([]);

  const getMyFeedback = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_FEEDBACK_BADGES,
        fetchPolicy: "no-cache",
        variables: {
          email: auth.currentUser.email,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setFeedbacks(data.feedback_data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMySelfData();
    getMyFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useContext(DispatchContext);
  const handleUpdateProfile = async () => {
    setSubmitting(true);
    client
      .mutate({
        variables: {
          id: auth.getClaim("x-hasura-user-id"),
          display_name: displayName,
          avatar_url: state?.userData?.avatar_url,
        },
        mutation: UPDATE_PROFILE_BY_USER,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })

      .then((res) => {
        console.log(res);
        dispatch({
          type: "updateUserData",
          payload: {
            ...state.userData,
            display_name: res.data.update_users_by_pk.display_name,
            avatar_url: res.data.update_users_by_pk.avatar_url,
          },
        });

        setTimeout(() => {
          setSubmitting(false);
          setEditMode(false);
        }, 700);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setSubmitting(false);
        }, 700);
      });
  };

  const handleRemoveAvatar = async () => {
    setSubmitting(true);
    client
      .mutate({
        variables: {
          id: auth.getClaim("x-hasura-user-id"),

          avatar_url: null,
        },
        mutation: CHANGE_AVATAR_PROFILE,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })

      .then((res) => {
        console.log(res);
        window.open("/profile", "_self");
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setSubmitting(false);
        }, 700);
      });
  };
  const [smartIndicator, setSmartIndicator] = useState(false);
  const handleChangeSmartIndicator = () => {
    setSubmitting(true);
    client
      .mutate({
        variables: {
          id: auth.getClaim("x-hasura-user-id"),
          smart_indicator: !smartIndicator,
        },
        mutation: SMART_INDICATOR_UPDATE,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })

      .then((res) => {
        setSmartIndicator(res?.data?.update_users_by_pk?.smart_indicator);
        setSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setSubmitting(false);
        }, 700);
      });
  };
  return (
    <MainLayout>
      <div className=" flex flex-col items-start w-full space-y-6">
        <span className="  text-5xl font-bold py-4 md:py-0 ">My Profile</span>
        <div className=" flex flex-row flex-wrap w-full justify-between items-start p-4 py-4 ">
          {editMode ? (
            <div className=" flex flex-col       mb-4 w-full  max-w-sm space-y-4 ">
              <div className=" flex flex-row flex-wrap items-center">
                <div className=" flex flex-col h-40 w-40 flex-shrink-0 mr-4">
                  {state?.userData?.avatar_url ? (
                    <img
                      src={state?.userData?.avatar_url}
                      className="h-40 w-40  rounded-full object-contain mr-4 mb-4 "
                      alt=""
                    />
                  ) : (
                    <span className="inline-block h-40 w-40 rounded-full overflow-hidden dark:bg-gray-100 bg-gray-500 ring-2">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                </div>
                <div className=" flex flex-row items-center space-x-2 py-4">
                  <button
                    className=" btn btn-primary"
                    onClick={() => setAvatarModalOpen(true)}>
                    Change photo
                  </button>
                  <button
                    className=" btn btn-primary"
                    onClick={() => setAvatarMakerOpen(true)}>
                    Create avatar
                  </button>
                  {state?.userData?.avatar_url !== null && (
                    <button
                      className=" btn  btn-outline"
                      onClick={() => handleRemoveAvatar()}>
                      remove photo
                    </button>
                  )}
                </div>
              </div>
              <div className=" flex flex-col  space-y-1">
                <span className="">{"First name"}</span>{" "}
                <input
                  type="text"
                  className="input input-bordered"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
              <div className=" flex flex-col  space-y-1">
                <span className=" ">{"Email"}</span>{" "}
                <input
                  type="text"
                  className="input input-bordered"
                  value={data?.account?.email}
                  disabled
                />
              </div>{" "}
              {submitting ? (
                <DataLoading />
              ) : (
                <button
                  className={`${
                    state?.userData?.display_name === displayName
                      ? " btn  btn-disabled"
                      : "btn "
                  } w-full sm:w-auto`}
                  disabled={state?.userData?.display_name === displayName}
                  onClick={() => {
                    handleUpdateProfile();
                  }}>
                  {"Save changes"}
                </button>
              )}
            </div>
          ) : (
            <div className=" flex flex-col sm:flex-row       mb-4 w-full md:w-auto    ">
              <div>
                {state?.userData?.avatar_url ? (
                  <span className="inline-block mr-4 h-40 w-40 rounded-full overflow-hidden ">
                    <img
                      src={state?.userData?.avatar_url}
                      className="h-40 w-40  rounded-full object-contain mr-4 mb-4 "
                      alt=""
                    />
                  </span>
                ) : (
                  <span className="inline-block h-40 w-40 mr-4 rounded-full overflow-hidden ">
                    <svg
                      className="h-full w-full "
                      fill="currentColor"
                      viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                )}
              </div>
              <div className="flex flex-col   space-y-4 justify-start items-start w-full">
                <div className="flex flex-col">
                  <span className="text-3xl font-bold  text-gray-800">
                    {state?.userData?.display_name}
                  </span>
                  <span className="   text-gray-700 flex flex-row space-x-4 items-center">
                    <span> {state?.userData?.account?.email} </span>
                    {!_.isEmpty(data?.account?.account_providers) && (
                      <div>
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg">
                          <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                            <path
                              fill="#4285F4"
                              d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                            />
                            <path
                              fill="#34A853"
                              d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                            />
                            <path
                              fill="#FBBC05"
                              d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                            />
                            <path
                              fill="#EA4335"
                              d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                            />
                          </g>
                        </svg>
                      </div>
                    )}
                  </span>
                </div>

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setEditMode(true);
                    setDisplayName(state?.userData?.display_name);
                  }}>
                  {"Edit profile"}
                </button>
                <div className=" flex flex-col py-4 space-y-4">
                  <span className=" form-title">
                    Reply's feedback smart indicator
                  </span>

                  <div className="btn-group">
                    <button
                      onClick={() => handleChangeSmartIndicator()}
                      className={`btn ${!smartIndicator && "btn-active"}`}>
                      OFF
                    </button>{" "}
                    <button
                      onClick={() => handleChangeSmartIndicator()}
                      className={`btn ${smartIndicator && "btn-active"}`}>
                      ON
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className=" flex flex-col space-y-2">
            <button
              className="btn  btn-outline  "
              onClick={() => handleLogout()}>
              {"Logout"}
            </button>
            <Link className="btn  btn-outline  " to="/reset">
              {"Reset Password"}
            </Link>
            <Link to="/usage" className=" btn btn-primary">
              usage
            </Link>
          </div>
        </div>
      </div>
      <div className=" flex flex-col space-y-2 w-full  justify-start  py-4 pb-8 border-t-2">
        <span className="  text-3xl font-bold py-4 md:py-0 ">My badges</span>
        <span>
          Topic creator can award you badges to your replies and you will see
          them under each of your replies. Here you can see all the badges you
          have ever get on VideoBit accumalatively
        </span>
      </div>
      <div className=" grid  grid-cols-2 md:grid-cols-3  lg:grid-cols-6 gap-6  ">
        {_.isEmpty(feedbacks) ? (
          <div className=" flex flex-col items-center w-full justify-center">
            <DataLoading />
          </div>
        ) : (
          feedbacks.map((item) => (
            <div key={item.id} className="indicator">
              {item.response_feedbacks_aggregate.aggregate.count > 0 && (
                <span className="indicator-item badge badge-secondary h-8 w-8 text-lg">
                  {item.response_feedbacks_aggregate.aggregate.count}
                </span>
              )}

              <div className="  bg-white  hover:shadow-md  p-2 rounded-md items-center justify-center space-y-2 shadow-sm grid  aspect-square bg-base-300 place-items-center w-32 h-32">
                <img
                  className="  w-14"
                  src={`/assets/icons/${item.image}`}
                  alt=""
                />
                <span className=" text-center text-sm">{item.name}</span>
              </div>
            </div>
          ))
        )}
      </div>
      <AvatarUploadModal
        open={avatarModalOpen}
        close={() => setAvatarModalOpen(false)}
      />
      <AvatarMakerModal
        open={avatarMakerOpen}
        close={() => setAvatarMakerOpen(false)}
      />
      <span>version: 2.1</span>
    </MainLayout>
  );
};

export default ProfilePage;

const GET_SELF = gql`
  query getSelf($user_id: uuid!) {
    me: users_by_pk(id: $user_id) {
      id
      display_name
      avatar_url
      age_declared
      under_aged
      smart_indicator
      account {
        id
        email
        account_providers {
          auth_provider
        }
        account_roles {
          role
        }
      }
    }
    parent_approval(where: { user_id: { _eq: $user_id } }) {
      approved
    }
  }
`;
