import { useApolloClient } from "@apollo/client";
import React, { useRef, useState } from "react";
import Avatar from "react-avatar-edit";
import { useLocation } from "react-router-dom";
import { CHANGE_AVATAR_PROFILE } from "../../util/insertQueries";
import { auth, storage } from "../../util/nhost";
import DataLoading from "../global/dataLoading";
import { idGenerator } from "../global/idGenerator";

const AvatarUploadModal = ({ open, close }) => {
  let avatarImg;
  const [inputValue, setInputValue] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const avar = useRef(null);
  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 171680) {
      alert("File is too big!");
      setInputValue(null);
      elem.target.value = "";
    }
  }

  function onUploadProgress(e) {
    console.log(e.preview);
  }
  const client = useApolloClient();
  const onCrop = (preview) => {
    fetch(preview)
      .then((res) => res.blob())
      .then((img) => (avatarImg = img));
  };
  const location = useLocation();
  const handleChangeAvatar = async (img) => {
    setSubmitting(true);

    try {
      await storage
        .put(
          `/public/avatar/${
            auth.getClaim("x-hasura-user-id") + "-" + idGenerator(5)
          }`,
          img,
          onUploadProgress
        )
        .then((avatar) =>
          client.mutate({
            variables: {
              id: auth.getClaim("x-hasura-user-id"),

              avatar_url: `${process.env.REACT_APP_API_KEY}/storage/o/${avatar.key}`,
            },
            mutation: CHANGE_AVATAR_PROFILE,
            context: {
              headers: {
                "x-hasura-role": "user",
              },
            },
          })
        )
        .then((res) => {
          console.log(res);
          window.open(location.pathname, "_self");
        });
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <div className={`modal ${open && "modal-open"} -top-2 `}>
      <div className="modal-box   max-w-xl w-full">
        <h3 className="font-bold text-lg">Upload your photo</h3>
        {submitting ? (
          <div>
            <DataLoading />
          </div>
        ) : (
          <div className=" p-2   flex flex-col items-center ">
            {inputValue ? (
              <div className=" flex flex-col space-y-4 w-full items-center">
                <img src={avatarImg} alt="" />
                <Avatar
                  onCrop={onCrop}
                  ref={avar}
                  width={250}
                  height={295}
                  onClose={() => setInputValue(null)}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={URL.createObjectURL(inputValue)}
                />
                <div className=" flex flex-row space-x-2">
                  <button
                    className="btn   btn-outline"
                    onClick={() => setInputValue(null)}>
                    New photo
                  </button>

                  <button
                    className="btn btn-primary"
                    onClick={() => handleChangeAvatar(avatarImg)}>
                    Save
                  </button>
                </div>{" "}
              </div>
            ) : (
              <div className=" py-6 w-full">
                {" "}
                <input
                  accept="image/*"
                  className="block w-full    rounded-lg border  cursor-pointer  text-lg   btn-primary"
                  type="file"
                  onChange={(e) => setInputValue(e.target.files[0])}
                />
              </div>
            )}
          </div>
        )}
        <div className="modal-action">
          <button onClick={() => close()} className="btn  btn-outline">
            close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarUploadModal;
