import React from "react";
import moment from "moment";
import _ from "lodash";
const VideoCoverCard = ({
  data,
  readed,
  label,
  needApproval,
  approved,
  amICreator,
  replyMode,
  rejected,
}) => {
  function coverImageAddress() {
    switch (data.content_type) {
      case "video":
        return data?.thumbnail_key === null
          ? `/assets/images/videorecording.jpg`
          : `${process.env.REACT_APP_API_KEY}/storage/o/${data?.thumbnail_key}`;

      case "":
        return data?.thumbnail_key === null
          ? `/assets/images/videorecording.jpg`
          : `${process.env.REACT_APP_API_KEY}/storage/o/${data?.thumbnail_key}`;

      case "audio":
        return `/assets/images/audiorecording.jpg`;
      case "screen":
        return `/assets/images/screenrecording.jpg`;

      default:
        break;
    }
  }
  // console.log(
  //   _.filter(data.response_feedbacks, function (o) {
  //     return o.feedback_datum.slug === "on_task";
  //   })
  // );
  return (
    <div className=" indicator">
      {needApproval && !approved && (
        <div className=" indicator-item badge  badge-secondary z-40 indicator-center indicator-middle">
          {amICreator
            ? " Need approval"
            : rejected
            ? "Rejected"
            : "Waiting for approval"}
        </div>
      )}
      <div className="stack w-full  ">
        <div
          className="  w-full     rounded-xl flex flex-col space-y-0 cursor-pointer relative
    ">
          <div
            className={`badge  text-xs   bg-blue-500 gap-2 border-0 shadow text-white  absolute left-0 right-0 mx-auto -top-6  z-20 ${
              readed && "hidden"
            }`}>
            {label}
          </div>

          <div className=" stack ">
            {/* <img
            src={coverImageAddress()}
            alt=""
            className={`  object-cover aspect-square flex flex-col  ring-8 rounded-xl w-full h-full ring-base-300  `}
          /> */}
            <img
              src={coverImageAddress()}
              alt=""
              className={`  object-cover aspect-square flex flex-col  outline  outline-4   rounded-xl w-full h-full  ${
                readed ? "   outline-base-300" : "  outline-blue-500 "
              } mb-2`}
            />

            {data?.response_tables_aggregate?.aggregate?.count > 0 && (
              <div className="card shadow  bg-purple-800  text-primary-content w-full h-full"></div>
            )}
            {data?.response_tables_aggregate?.aggregate?.count > 1 && (
              <div className="card shadow-lg bg-primary text-primary-content w-full h-full  -bottom-1 "></div>
            )}
          </div>
          <br />
          <div className=" flex flex-col space-y-1   flex-shrink-0  -top-2 ">
            <span className=" font-bold text-xl">{data?.title}</span>
            <span className=" text-lg font-bold  ">{data?.name}</span>
            {replyMode ? (
              <div>
                {_.size(data?.reply_tables)} Comment
                {_.size(data?.reply_tables) > 0 && "s"}
              </div>
            ) : (
              <span className=" text-sm font-light flex flex-row items-center space-x-2   break-normal">
                <span>
                  {" "}
                  {data?.video_counters_aggregate?.aggregate?.count} views{" "}
                </span>
                <div className=" bg-primary h-2 w-2 rounded-full" />
                <span>
                  {data?.response_tables_aggregate?.aggregate?.count} replies
                </span>
              </span>
            )}
            <span className=" text-sm font-light flex flex-row items-center space-x-2   break-normal">
              <div className=" bg-primary h-2 w-2 rounded-full" />
              <span>
                {" "}
                {/* {moment(data?.created_at).format("MMMM Do YYYY, h:mm:ss a")} */}
                {moment().diff(moment(data?.created_at), "days") < 3
                  ? moment(data?.created_at).fromNow()
                  : moment(data?.created_at).format("MMMM Do YYYY, h:mm:ss a")}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCoverCard;
