import React from "react";
import MainLayout from "../components/layout/mainLayout";

const ParentApprovalPending = () => {
  return (
    <MainLayout>
      <div className="flex flex-col   items-center justify-center  h-96  w-full space-y-4">
        <div className=" flex flex-col items-center w-24 p-1 h-24">
          <svg
            width="88"
            height="104"
            viewBox="0 0 88 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.6274 9.37258C72.6286 15.3737 76 23.5131 76 32V48H80C84.4183 48 88 51.5817 88 56V96C88 100.418 84.4183 104 80 104H8C3.58172 104 0 100.418 0 96V56C0 51.5817 3.58172 48 8 48H12V32C12 23.5131 15.3714 15.3737 21.3726 9.37258C27.3737 3.37142 35.5131 0 44 0C52.4869 0 60.6263 3.37142 66.6274 9.37258ZM28 48H60V32C60 27.7565 58.3143 23.6869 55.3137 20.6863C52.3131 17.6857 48.2435 16 44 16C39.7565 16 35.6869 17.6857 32.6863 20.6863C29.6857 23.6869 28 27.7565 28 32V48Z"
              fill="#94A3B7"
            />
            <circle cx="44" cy="71" r="10" fill="#111826" />
            <rect x="39" y="71" width="10" height="20" rx="4" fill="#111826" />
          </svg>
        </div>
        <span className="text-2xl  ">
          Waiting for parent or guardian consent
        </span>
        {/* <button className="btn-generic w-full max-w-sm">
          Resend consent email
        </button> */}
      </div>
    </MainLayout>
  );
};

export default ParentApprovalPending;
