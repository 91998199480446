import { gql, useQuery } from "@apollo/client";
import React from "react";
import _ from "lodash";
const PUBLIC_STATS = gql`
  query MyQuery {
    stats {
      root
      type
    }
  }
`;
const StatsPage = () => {
  const { loading, error, data } = useQuery(PUBLIC_STATS);

  return (
    !error &&
    !loading && (
      <div className="mockup-window border bg-base-300 flex flex-col  top-10  ">
        <div className=" flex flex-col  p-4 space-y-4">
          <div className=" flex flex-row items-start space-x-2">
            {" "}
            <img src="/vb512.png" className=" w-20 rounded-lg" alt="" />
            <span className="  text text-primary text-4xl font-bold">
              Live stats
            </span>
          </div>
          <div class="stats stats-vertical sm:stats-horizontal shadow">
            <div class="stat">
              <div class="stat-figure text-primary"></div>
              <div class="stat-title">Total users</div>
              <div class="stat-value text-primary">
                {_.find(data?.stats, { type: "user" })?.root}{" "}
              </div>
            </div>

            <div class="stat">
              <div class="stat-figure text-secondary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="inline-block w-8 h-8 stroke-current">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                </svg>
              </div>
              <div class="stat-title">topic Views</div>
              <div class="stat-value text-secondary">
                {_.find(data?.stats, { type: "counter" })?.root}
              </div>
            </div>
          </div>
          <div class="stats stats-vertical sm:stats-horizontal shadow">
            <div class="stat">
              <div class="stat-figure text-primary"></div>
              <div class="stat-title">Total topics</div>
              <div class="stat-value text-primary">
                {_.find(data?.stats, { type: "video" })?.root}{" "}
              </div>
            </div>

            <div class="stat w-52">
              <div class="stat-title">total replies</div>
              <div class="stat-value text-secondary">
                {_.find(data?.stats, { type: "response" })?.root}
              </div>
            </div>
          </div>
          <div class="stats stats-vertical sm:stats-horizontal shadow">
            <div class="stat">
              <div class="stat-figure text-primary"></div>
              <div class="stat-title">Total comments</div>
              <div class="stat-value text-primary">
                {_.find(data?.stats, { type: "reply" })?.root}{" "}
              </div>
            </div>

            <div class="stat w-52">
              <div class="stat-title">Total duration </div>
              <div class="stat-value text-secondary">
                {_.find(data?.stats, { type: "duration" })?.root}{" "}
              </div>
              <div class="stat-value text-secondary">Seconds</div>
            </div>
          </div>

          {/* {JSON.stringify(data)} */}
        </div>
      </div>
    )
  );
};

export default StatsPage;
