import React, { useContext, useEffect } from "react";
import { StateContext } from "./context/appContext";
import Router from "./util/router";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-3K2RVEH2KZ"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const state = useContext(StateContext);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
  return (
    <div
      className=" h-screen w-screen flex flex-col items-center overflow-hidden bg-base-200 "
      data-theme={state.selectedTheme}>
      <Router />
    </div>
  );
};

export default App;
