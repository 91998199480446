import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./videoPlayer.css";
const VideoPlayer = ({ src }) => {
  // eslint-disable-next-line no-unused-vars
  const [played, setPlayed] = useState(0);
  function handleProgress(e) {
    console.log(e);
    setPlayed(() => (e.playedSeconds / e.loadedSeconds) * 100);
  }
  return (
    <div
      className=" flex flex-col w-full  p-2 aspect-video relative"
      style={{ maxHeight: "620px" }}>
      {/* <Video src={src} controls={["Seek", "Time", "Volume", "Fullscreen"]} /> */}
      <ReactPlayer
        url={src}
        controls={true}
        height="100%"
        width="100%"
        playsinline
        playIcon="https://www.svgrepo.com/download/246576/play-button.svg"
        playbackRate={1}
        onProgress={handleProgress}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
      />
      {/* <div className="  px-4 w-full items-center flex flex-col absolute bottom-6 mx-auto left-0">
        {" "}
        <progress
          className="progress progress-secondary w-full h-4"
          value={played}
          max="100"></progress>
      </div> */}
    </div>
  );
};

export default VideoPlayer;
