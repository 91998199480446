import React, { useEffect } from "react";
import RTCMediaDetectorForAudio from "../../components/global/rtcMediaDetectorForAudio";

const AudioRecording = ({ recording, setReadyToRecord, checkingMedia }) => {
  useEffect(() => {
    setReadyToRecord(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {" "}
      {checkingMedia && (
        <RTCMediaDetectorForAudio
          setMediaWarn={(e) => console.log(e)}
          resetRecorder={(e) => console.log("reset RTC")}
        />
      )}
      <div
        className={`flex flex-col h-32 w-32 rounded-full border-2 transform duration-300 ease-out ${
          recording
            ? "border-red-400  bg-red-100"
            : "border-blue-400  bg-blue-50"
        } items-center justify-center`}>
        <div
          className={` ${
            recording ? "bg-red-500 " : " bg-blue-500"
          } transform duration-300 ease-out w-16 h-16  rounded-full  `}>
          {" "}
          <div className={recording ? "animate-pulse" : "animate-none"}>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32 0C14.327 0 0 14.327 0 32C0 49.673 14.327 64 32 64C49.673 64 64 49.673 64 32C64 14.327 49.673 0 32 0ZM32 62C15.432 62 2 48.568 2 32C2 15.432 15.432 2 32 2C48.568 2 62 15.432 62 32C62 48.568 48.568 62 32 62Z"
                fill="#fff"
              />
              <path
                d="M32 37C35.312 37 38 34.312 38 31V22C38 18.687 35.312 16 32 16C28.687 16 26 18.687 26 22V31C26 34.312 28.687 37 32 37ZM28 22C28 19.791 29.791 18 32 18C34.209 18 36 19.791 36 22V31C36 33.209 34.209 35 32 35C29.791 35 28 33.209 28 31V22ZM42 31C42 30.447 41.553 30 41 30C40.447 30 40 30.447 40 31C40 35.418 36.418 39 32 39C27.582 39 24 35.418 24 31C24 30.447 23.553 30 23 30C22.447 30 22 30.447 22 31C22 36.186 25.947 40.447 31 40.949V46H28C27.447 46 27 46.447 27 47C27 47.553 27.447 48 28 48H36C36.553 48 37 47.553 37 47C37 46.447 36.553 46 36 46H33V40.949C38.053 40.447 42 36.186 42 31Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioRecording;
