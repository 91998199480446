import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import MainLayout from "../../components/layout/mainLayout";
import ScreenLoading from "../../components/global/screenLoading";
import { GET_VIDEO_BY_LINK_PUBLIC } from "../../util/getQueries";
import MediaPlayer from "../../components/global/mediaPlayer";
import moment from "moment";
import parse from "html-react-parser";
import { useAuth } from "@nhost/react-auth";

const PublicTopicPlayer = () => {
  const { id } = useParams();
  const { signedIn } = useAuth();
  const [isIframe, setIsIframe] = useState(false);
  function inIframe() {
    try {
      return setIsIframe(window.self !== window.top);
    } catch (e) {
      return true;
    }
  }

  useEffect(() => {
    inIframe();
  }, []);

  const { loading, error, data } = useQuery(GET_VIDEO_BY_LINK_PUBLIC, {
    variables: {
      access_key: id,
    },
    context: {
      headers: {
        "x-hasura-role": signedIn ? "user" : "public",
      },
    },
  });
  error && console.log(error);
  if (loading) {
    return <ScreenLoading />;
  }

  console.log(data?.video_table_by_pk?.pincode);
  return (
    !loading &&
    (isIframe ? (
      <div className=" w-full  flex flex-col  overflow-y-auto aspect-square">
        <div className="flex flex-col p-2 items-center  sm:max-w-3xl w-full  ">
          <div className=" absolute top-5 left-5 z-50">
            <button
              onClick={() => window.open("/", "_blank")}
              className=" text-3xl font-bold cursor-pointer flex flex-row items-end">
              <span className="  text-primary">V</span>
              <span className=" hidden sm:flex  text-primary">ideo</span>
              <span className=" text-secondary">B</span>
              <span className=" text-secondary hidden sm:flex">it</span>
            </button>
          </div>
          <div className=" flex flex-col items-center w-full relative ">
            <MediaPlayer data={data.video_table_by_pk} />
            <div className="flex absolute   z-40 bottom-8 right-5 flex-col    btn btn-secondary w-20 h-20 rounded-full shadow-sm">
              <button
                onClick={() =>
                  window.open(`https://app.videobit.io/reply/${id}`, "_blank")
                }>
                Reply
              </button>
            </div>
          </div>
          <div className="flex flex-row  justify-between  py-4 w-full sm:max-w-5xl ">
            <div className=" z-20  ">
              <span className="text-2xl md:text-4xl font-bold   ">
                {data?.video_table_by_pk?.title}
              </span>
              <article className=" prose w-full ">
                {data?.video_table_by_pk?.desc &&
                  parse(String(data?.video_table_by_pk?.desc))}
              </article>
            </div>
            <div className="flex flex-col  space-y-2">
              {" "}
              <span className="text-md font-light ">
                {
                  data?.video_table_by_pk?.video_counters_aggregate?.aggregate
                    ?.count
                }{" "}
                views
              </span>
              <span className="font-bold text-xl ">
                {data?.video_table_by_pk?.user?.display_name}
              </span>
              <span className="text-md font-light ">
                {moment(data?.video_table_by_pk?.created_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
            </div>
          </div>{" "}
        </div>
      </div>
    ) : (
      <div className=" flex flex-col w-full   blur-none overflow-y-auto">
        <MainLayout>
          <div className="flex flex-col p-4 items-center  sm:max-w-3xl w-full  ">
            <MediaPlayer data={data.video_table_by_pk} />
            <div className="flex flex-row flex-wrap justify-between  py-4 w-full sm:max-w-5xl ">
              <div className=" z-20  w-full ">
                <span className="text-2xl md:text-4xl font-bold   ">
                  {data?.video_table_by_pk?.title}
                </span>

                <article className=" prose w-full ">
                  {data?.video_table_by_pk?.desc &&
                    parse(String(data?.video_table_by_pk?.desc))}
                </article>
              </div>
              <div className="flex flex-col  space-y-2">
                {" "}
                <span className="text-md font-light ">
                  {
                    data?.video_table_by_pk?.video_counters_aggregate?.aggregate
                      ?.count
                  }{" "}
                  views
                </span>
                <span className="font-bold text-xl ">
                  {data?.video_table_by_pk?.user?.display_name}
                </span>
                <span className="text-md font-light ">
                  {moment(data?.video_table_by_pk?.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </span>
              </div>
            </div>{" "}
            <div className="flex  sm:relative z-40 bottom-0 flex-col  max-w-lg  items-center justify-center sm:justify-end py-4   w-full  mx-auto mt-10 p-2  ">
              <Link to={`/reply/${id}`} className=" btn w-full   ">
                Reply to this Topic
              </Link>
            </div>
          </div>
        </MainLayout>
      </div>
    ))
  );
};

export default PublicTopicPlayer;
