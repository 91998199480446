// import DetectRTC from "detectrtc";
import { useState, useCallback } from "react";

const useUserMedias = (constraint, setCheckingState) => {
  const [hasPermission, setHasPermission] = useState(false);
  // const stream = useRef<HTMLElementTagNameMap[Media]>(null);
  // console.log(hasPermission);
  const requestAccess = useCallback(async () => {
    try {
      const permission = await navigator.mediaDevices
        .getUserMedia({ [constraint]: true })
        .catch(() => {
          setHasPermission(false);
          setCheckingState(false);
        });

      setCheckingState(false);

      // console.log('permission -> ', permission);
      if (permission) {
        setHasPermission(true);
        return;
      }

      setHasPermission(false);
    } catch (err) {
      console.log(err);
    }
  }, [constraint, setCheckingState]);

  // useEffect(() => {
  //   // requestAccess();

  //   return () => {
  //     setHasPermission(false);
  //   };
  // }, [requestAccess]);

  return {
    hasPermission,
    requestAccess,
    // stream
  };
};

export default useUserMedias;
