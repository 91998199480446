import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className=" min-h-full w-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6 space-y-4">
              <div>
                <div className=" flex flex-row items-end text-5xl font-bold">
                  <span className=" flex  text-primary">Video</span>
                  <span className=" text-secondary flex">Bit</span>
                </div>
              </div>{" "}
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link to="/" className=" btn btn-primary">
                Go back home
              </Link>
              <button
                onClick={() =>
                  window.open("https://videobit.io/help", "_blank")
                }
                className=" btn ">
                Help center
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotFoundPage;
