import React from "react";

const AuthWrapper = ({ children, header, desc, footer }) => {
  return (
    <div className="hero  bg-base-200      ">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">{header}</h1>
          <p className="py-6">{desc}</p>
        </div>
        <div className="flex-shrink-0 w-full max-w-sm  space-y-6">
          <div className="card flex-shrink-0 w-full max-w-sm shadow-xl bg-base-100">
            {children}
          </div>
          <div className=" flex flex-col w-full items-center">
            <span>{footer}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
