import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { auth } from "../util/nhost";
import { AGE_DECLARATION } from "../util/insertQueries";
import MainLayout from "../components/layout/mainLayout";
import AuthWrapper from "./authWrapper";
import { validateEmail } from "../components/global/validateEmail";
import { wrongEmail } from "../components/global/wrongEmail";

const AgeDeclare = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [underAge, setUnderAge] = useState(false);
  const [parentEmailAddress, setParentEmailAddress] = useState("");
  const client = useApolloClient();
  const handleAgeDeclatration = () => {
    client
      .mutate({
        variables: {
          id: auth.getClaim("x-hasura-user-id"),
          age_declared: true,
          under_aged: underAge,
          parent_email: parentEmailAddress,
        },
        mutation: AGE_DECLARATION,
      })
      .then((res) => {
        console.log(res);
        window.open("/", "_self");
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handleNormalAgeDeclaration = () => {
    client
      .mutate({
        variables: {
          id: auth.getClaim("x-hasura-user-id"),
          age_declared: true,
          under_aged: false,
          parent_email: "n/a",
        },
        mutation: AGE_DECLARATION,
      })
      .then((res) => {
        console.log(res);
        window.open("/", "_self");
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const [titleValue, setTitleValue] = useState("Confirm your age");
  return (
    <MainLayout onboarding>
      <AuthWrapper
        header={
          currentStep === 1
            ? "Age confirmation for educational use"
            : currentStep === 2 && underAge && "Enter parent or guardian email"
        }
        desc={
          currentStep === 1
            ? "In case you're using VideoBit for learning or for other activities in school, we need to confirm your age first."
            : currentStep === 2 &&
              underAge &&
              "We will send a message to the provided parent or guardian email for consent. After it’s approved, you’ll get access to VideoBit."
        }>
        <div className="card-body">
          <label className="label">
            <span className="label-text text-xl ">{titleValue}</span>
          </label>
          {currentStep === 1 && (
            <div className="flex flex-col   items-center justify-center w-full">
              <div className="flex flex-row items-center space-x-2 w-full">
                <button
                  className="btn "
                  onClick={() => {
                    setCurrentStep(2);
                    setUnderAge(true);
                    setTitleValue("Parent or guardian email");
                  }}>
                  {" "}
                  I’m 16 or younger
                </button>
                <button
                  className="btn "
                  onClick={() => {
                    setCurrentStep(2);
                    setUnderAge(false);
                    setTitleValue("User agreement");
                  }}>
                  {" "}
                  I’m 17+
                </button>
              </div>
            </div>
          )}

          {currentStep === 2 && underAge && (
            <div className="flex flex-col space-y-4  items-start justify-center">
              {wrongEmail(parentEmailAddress)}
              <input
                className="input input-bordered w-full"
                value={parentEmailAddress}
                onChange={(e) => setParentEmailAddress(e.target.value)}
              />
              <button
                disabled={!validateEmail(parentEmailAddress)}
                className={`w-full btn 
                ${
                  !validateEmail(parentEmailAddress)
                    ? " btn-disabled"
                    : "btn-primary"
                }
                `}
                onClick={() => handleAgeDeclatration()}>
                Submit
              </button>
            </div>
          )}
          {currentStep === 2 && !underAge && (
            <div className=" font-light text-lg space-y-2 ">
              I have read and agree with VideoBit's{" "}
              <a
                href="https://videobit.io/privacy"
                target="_blank"
                className=" btn-link"
                rel="noreferrer">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://videobit.io/terms"
                target="_blank"
                className=" btn-link"
                rel="noreferrer">
                Term of Services
              </a>
              . If you are a parent or guardian giving consent, you also agree
              on your child using VideoBit for class and related educational
              activities.
              <button
                className="btn btn-primary w-full"
                onClick={() => handleNormalAgeDeclaration()}>
                I understand and agree
              </button>
            </div>
          )}
        </div>
      </AuthWrapper>
    </MainLayout>
  );
};

export default AgeDeclare;
