import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../components/layout/mainLayout";
import {
  GET_ALL_FEEDBACK_FOR_FILTER,
  GET_VIDEOBIT_BY_ACCESSKEY,
} from "../../util/getQueries";
import parse from "html-react-parser";
import moment from "moment";
import MainTopicStat from "./mainTopicStat";
import ShareTopicLink from "../../components/modal/shareTopicLink";
import VideoCoverCard from "../../components/video/videoCoverCard";
import MediaPlayer from "../../components/global/mediaPlayer";
import _ from "lodash";
import {
  DELETE_VIDEO_BY_CREATOR_USER,
  INSERT_VIDEO_COUNTER,
  MAIN_VIDEO_READ_RECEIPT,
} from "../../util/insertQueries";
import { auth } from "../../util/nhost";
import DataLoading from "../../components/global/dataLoading";
import DeleteModal from "../../components/modal/deleteModal";
import BottomNotification from "../../components/notification/bottomNotification";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
const MainTopicPlayer = () => {
  const gaEventTracker = useAnalyticsEventTracker("Topic player");

  const [isLoading, setIsLoading] = useState(true);

  const client = useApolloClient();
  const [data, setData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [videoKey, setVideoKey] = useState();
  const { id } = useParams();
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
  const [privateComm, setPrivateComm] = useState(true);
  const [amICreator, setAmICreator] = useState(false);
  const [controlReply, setControlReply] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rnd, setRnd] = useState();
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState("alert-info");
  const [activeFeedback, setActiveFeedback] = useState([]);
  const [selectedFeedbackItem, setSelectedFeedbackItem] = useState("");

  const getAllFeedback = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_ALL_FEEDBACK_FOR_FILTER,
        fetchPolicy: "no-cache",

        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setActiveFeedback(data.feedback_data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [noAccess, setNoAccess] = useState(false);
  const getVideoData = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_VIDEOBIT_BY_ACCESSKEY,
        fetchPolicy: "no-cache",
        variables: {
          access_key: id,
          feedback: `%${selectedFeedbackItem}%`,
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setData(data?.video_table_by_pk);

        //  console.log(data?.video_table_by_pk);

        // console.log(
        //   _.filter(data?.video_table_by_pk.response_tables, function (o) {
        //     return _.filter(o.response_feedbacks, function (t) {
        //       return t.feedback_datum.slug === "on_task";
        //     });
        //   })
        // );
        setControlReply(data?.video_table_by_pk.control_reply);
        setAmICreator(
          data?.video_table_by_pk?.creator_id ===
            auth.getClaim("x-hasura-user-id")
        );
        setPrivateComm(data?.video_table_by_pk?.private_comm);
        setVideoKey(data?.video_table_by_pk?.video_key);

        if (
          data?.video_table_by_pk?.main_video_reads_aggregate?.aggregate
            ?.count === 0
        ) {
          handleReadReceiptForMainVideo(id);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      }
    } catch (error) {
      console.log(error);

      setNoAccess(true);
      /// error message need notification
    }
  };

  useEffect(() => {
    getVideoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeedbackItem]);
  const handleAddNewViewCounter = () => {
    client
      .mutate({
        variables: {
          access_key: id,
        },
        mutation: INSERT_VIDEO_COUNTER,
      })
      .then((res) => {
        // console.log(res, "viewer counter");
      })

      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (id) {
      getVideoData();
      handleAddNewViewCounter();
      getAllFeedback();
    }

    gaEventTracker("Player loaded");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleReadReceiptForMainVideo = async (videoId) => {
    client
      .mutate({
        variables: {
          object: {
            user_id: auth.getClaim("x-hasura-user-id"),
            video_id: videoId,
          },
        },
        mutation: MAIN_VIDEO_READ_RECEIPT,
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteTopicModalView = () => {
    setDeleteModalOpen(true);
  };

  const navigate = useNavigate();
  const handleDeleteMainTopicByCreator = () => {
    setRnd((rnd) => Math.floor(Math.random() * 10 + 1));

    client
      .mutate({
        variables: {
          access_key: id,
        },
        mutation: DELETE_VIDEO_BY_CREATOR_USER,
      })
      .then((res) => {
        setDeleteModalOpen(false);
      })
      .then((res) => {
        console.log(res);
        setNotificationType("alert-info");
        setNotification("This topic deleted");
      })
      .then(() => {
        setTimeout(() => {
          navigate("/topics");
        }, 400);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainLayout>
      {isLoading ? (
        <div className=" h-full w-full items-center justify-center flex flex-col py-20">
          <DataLoading />
        </div>
      ) : (
        <>
          <div className=" flex flex-col w-full  py-2 border-b-2 space-y-2">
            <div className="text-lg font-bold breadcrumbs  -ml-4">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>{data?.title}</li>
              </ul>
            </div>
            <span className=" text-sm font-light">
              {moment(data?.created_at).format("MMMM Do YYYY, h:mm a")}
            </span>{" "}
            <div className=" flex flex-row items-center justify-between">
              {" "}
              <div className=" flex flex-row space-x-2 flex-wrap items-center">
                <span className="  text-4xl  font-bold">{data?.title}</span>
                {data?.video_category &&
                  data?.video_category?.toLowerCase() !== "all" && (
                    <div className="badge badge-lg  uppercase  cursor-pointer badge-secondary">
                      {data?.video_category}
                    </div>
                  )}
              </div>
              {amICreator && (
                <div className=" flex flex-row items-center space-x-2">
                  <button
                    className=" btn  ring-primary"
                    onClick={() => setShareLinkModalOpen(true)}>
                    Share link
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className=" flex flex-col md:flex-row w-full ">
            <div className=" flex flex-col w-full  items-start   space-y-4  mr-4 ">
              <MediaPlayer data={data} />

              <article className=" prose  p-4 bg-base-100 w-full rounded-lg ">
                {/* <span className=" font-bold text-xl"> Description:</span> */}
                {data?.desc && parse(String(data?.desc))}
              </article>
            </div>

            <div className=" flex flex-col  justify-between">
              <MainTopicStat
                student2Student={data?.student_to_student}
                views={data?.video_counters_aggregate?.aggregate?.count}
                replies={_.size(data?.response_tables)}
                privateComm={privateComm}
              />
              <div className=" py-4 fle  flex-col space-y-2">
                <Link
                  to={`/reply/${id}`}
                  className=" btn  btn-secondary w-full">
                  Reply to this topic
                </Link>
                {amICreator && (
                  <button
                    className=" btn btn-outline w-full"
                    onClick={() => handleDeleteTopicModalView()}>
                    Delete topic
                  </button>
                )}{" "}
              </div>
            </div>
          </div>

          <div className=" border-t-2 py-2 flex flex-col items-start w-full">
            <span className=" text-3xl font-body ">Replies</span>
          </div>
          {!_.isEmpty(data?.response_tables) && (
            <div className=" flex flex-col w-full ">
              <span className=" text-xl font-body py-1 ">
                Filter by feedback
              </span>

              <div className=" grid md:grid-cols-8   grid-cols-3 gap-2 ">
                {activeFeedback?.map((item) => (
                  <div
                    onClick={() => setSelectedFeedbackItem(item.slug)}
                    key={item.id}
                    className={`flex flex-col space-x-2  w-full  items-center p-1 rounded-lg cursor-pointer ${
                      selectedFeedbackItem === item.slug
                        ? "bg-primary text-base-100"
                        : "bg-base-100 text-base"
                    } shadow mr-2 mb-2 `}>
                    <div className="  flex flex-row space-x-2 items-center">
                      <img
                        className="  w-8"
                        src={`/assets/icons/${item.image}`}
                        alt=""
                      />{" "}
                    </div>{" "}
                    <span className=" text-center text-xs">{item.name}</span>
                  </div>
                ))}
                {selectedFeedbackItem !== "" && (
                  <button
                    onClick={() => setSelectedFeedbackItem("")}
                    className=" flex flex-col justify-center  bg-secondary text-base-100 space-x-2  items-center p-2 rounded-lg  shadow mr-2 mb-2 ">
                    Clear filter
                  </button>
                )}
              </div>
            </div>
          )}
          <div className=" flex flex-col w-full  py-10">
            <div className=" grid grid-cols-2 md:grid-cols-4  lg:grid-cols-5 gap-8">
              {data?.response_tables.map((item) =>
                selectedFeedbackItem !== ""
                  ? _.some(item.response_feedbacks, {
                      feedback_datum: { slug: selectedFeedbackItem },
                    }) &&
                    ((item?.email === auth.currentUser.email &&
                      item?.reject_by_creator) ||
                      !item.reject_by_creator) && (
                      <Link to={`/topic/${id}/${item.id}`} key={item.id}>
                        <VideoCoverCard
                          replyMode={true}
                          needApproval={controlReply}
                          amICreator={amICreator}
                          approved={item?.approved_by_creator}
                          rejected={item?.reject_by_creator}
                          data={item}
                          label={"Unread reply or comment"}
                          readed={
                            item?.video_reads_aggregate?.aggregate?.count > 0 &&
                            _.isEmpty(
                              _.find(item?.reply_tables, function (o) {
                                return (
                                  o.reply_video_reads_aggregate.aggregate
                                    .count === 0
                                );
                              })
                            )
                          }
                        />
                      </Link>
                    )
                  : ((item?.email === auth.currentUser.email &&
                      item?.reject_by_creator) ||
                      !item.reject_by_creator) && (
                      <Link to={`/topic/${id}/${item.id}`} key={item.id}>
                        <VideoCoverCard
                          replyMode={true}
                          needApproval={controlReply}
                          amICreator={amICreator}
                          approved={item?.approved_by_creator}
                          rejected={item?.reject_by_creator}
                          data={item}
                          label={"Unread reply or comment"}
                          readed={
                            item?.video_reads_aggregate?.aggregate?.count > 0 &&
                            _.isEmpty(
                              _.find(item?.reply_tables, function (o) {
                                return (
                                  o.reply_video_reads_aggregate.aggregate
                                    .count === 0
                                );
                              })
                            )
                          }
                        />
                      </Link>
                    )
              )}
              <div className=" h-40 flex-shrink-0"></div>
            </div>
          </div>
          <DeleteModal
            open={deleteModalOpen}
            title="Delete topic"
            close={() => setDeleteModalOpen(false)}
            desc="Are you sure you want to delete this topic? By deleting this video
            all its replies and comments will get deleted as well."
            deleteAction={() => handleDeleteMainTopicByCreator()}
          />
          <BottomNotification
            type={notificationType}
            message={notification}
            rnd={rnd}
          />
          <ShareTopicLink
            pincode={data?.pincode}
            videoAccessKey={id}
            open={shareLinkModalOpen}
            close={(e) => setShareLinkModalOpen(false)}
            link={`https://app.videobit.io/a/${id}`}
          />
        </>
      )}
      {noAccess && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">
              You dont have access to this topic
            </h3>
            <p className="py-4">
              It seems ether you are in wrong place or you dont have access to
              this topic.
            </p>
            <div className="modal-action">
              <Link to="/" className="btn">
                Go to your topics
              </Link>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default MainTopicPlayer;
