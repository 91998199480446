import React from "react";
import { Link } from "react-router-dom";

const RecorderLayout = ({ children }) => {
  return (
    <div className=" flex flex-col  w-full h-full  overflow-y-auto items-center py-2 ">
      <div className=" max-w-5xl w-full h-full ">
        <div
          className={` z-20  fixed flex  top-0 flex-row   max-w-5xl w-full justify-between p-2 py-4    items-center`}>
          <Link to="/" className=" text-3xl font-bold cursor-pointer">
            <span className="  text-primary">Video</span>
            <span className=" text-secondary">Bit</span>
          </Link>
          <div className=" flex flex-row items-center space-x-2"></div>
        </div>{" "}
        <div className="     flex flex-col    mt-20   w-full items-center space-y-2  py-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default RecorderLayout;
