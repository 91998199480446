import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataLoading from "../../components/global/dataLoading";
import MainLayout from "../../components/layout/mainLayout";
import ItemHelp from "../../components/modal/itemHelp";
import VideoCoverCard from "../../components/video/videoCoverCard";
import _ from "lodash";
import {
  GET_MY_CATEGORIES,
  GET_MY_INTRACTIONS_VIDEO,
  GET_MY_VIDEOS,
  SUB_TO_MY_VIDEOS,
} from "../../util/getQueries";
import { auth } from "../../util/nhost";
import moment from "moment";
import FeedbackForCategories from "./feedbackForCategories";
import VideoPlayer from "../../components/global/videoPlayer";
import { StateContext } from "../../context/appContext";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import ReactGA from "react-ga4";

const LandingPage = () => {
  const [myVideos, setMyVideos] = useState([]);
  const [myVideoTimeline, setMyVideoTimeline] = useState([]);
  const [intractVideos, setIntractVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [timelineView, setTimelineView] = useState(false);

  const client = useApolloClient();
  const [topicViewType, setTopicViewType] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limitData, setLimitData] = useState(12);
  const state = useContext(StateContext);
  useEffect(() => {
    if (!_.isEmpty(state.userData)) {
      if (!state.userData.onboarding) {
        navigate("/onboarding");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getMyVideos = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_VIDEOS,
        variables: {
          _eq: auth.getClaim("x-hasura-user-id"),
          category: `%${selectedCategory}%`,
          limit: limitData,
        },
        fetchPolicy: "no-cache",

        context: {
          headers: {
            "x-hasura-role": "user",
            "X-Hasura-Email": auth.currentUser?.email,
          },
        },
      });

      if (!loading && !error && data) {
        setMyVideos(data.video_table);

        // setMyVideoTimeline(
        //   _.map(
        //     _.groupBy(data.video_table, function (item) {
        //       return moment(item.created_at).startOf("day").format();
        //     }),
        //     function (group, day) {
        //       return {
        //         day: day,
        //         data: group,
        //       };
        //     }
        //   )
        // );

        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };

  const getMyIntractVideos = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_INTRACTIONS_VIDEO,
        fetchPolicy: "no-cache",
        variables: {
          email: auth.currentUser.email,
          limit: limitData,

          user_id: auth.getClaim("x-hasura-user-id"),
        },
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setIntractVideos(data.video_table);
        // let arr = _.map(
        //   _.groupBy(data.video_table, function (item) {
        //     return moment(item.created_at).startOf("day").format();
        //   }),
        //   function (group, day) {
        //     return {
        //       day: day,
        //       data: group,
        //     };
        //   }
        // );

        // setMyVideoTimeline((myVideoTimeline) => _.merge(myVideoTimeline, arr));
        //  console.log(data.video_table);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };
  useEffect(() => {
    ReactGA.event({
      category: "Main page",
      action: "Main page loaded",
    });
    getMyVideos();
    getMyIntractVideos();
    getMyCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getMyVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, limitData]);

  useEffect(() => {
    const getlist = client.subscribe({
      query: SUB_TO_MY_VIDEOS,
      variables: {
        _eq: auth.getClaim("x-hasura-user-id"),
        category: `%${selectedCategory}%`,
        limit: limitData,
      },
      fetchPolicy: "no-cache",
    });
    const subscription = getlist.subscribe(
      ({ data }) => {
        setMyVideos(data.video_table);
        setMyVideoTimeline(
          _.map(
            _.groupBy(data.video_table, function (item) {
              return moment(item.created_at).startOf("day").format();
            }),
            function (group, day) {
              return {
                day: day,
                data: group,
              };
            }
          )
        );
      },
      (error) => console.log(error)
    );
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const [categories, setCategories] = useState([]);

  const getMyCategories = async () => {
    try {
      const { loading, error, data } = await client.query({
        query: GET_MY_CATEGORIES,
        fetchPolicy: "no-cache",
        context: {
          headers: {
            "x-hasura-role": "user",
          },
        },
      });

      if (!loading && !error && data) {
        setCategories(data.topic_categories);
      }
    } catch (error) {
      console.log(error);
      /// error message need notification
    }
  };

  const [feedbackExist, setFeedbackExist] = useState(false);
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div className=" flex flex-col  md:flex-row justify-between items-center w-full py-2 pb-6 border-b ">
        <div className=" flex flex-row space-x-2 items-center">
          {" "}
          <span className="  text-5xl font-bold py-4 md:py-0 ">My topics</span>
          <ItemHelp text="All the topics I have started or engaged in" />
        </div>

        <div className=" hidden sm:flex btn-group">
          <button
            className={`btn btn-sm ${topicViewType === "all" && "btn-active"}`}
            onClick={() => setTopicViewType("all")}>
            Show all
          </button>
          <button
            className={`btn btn-sm ${
              topicViewType === "myvideo" && "btn-active"
            }`}
            onClick={() => setTopicViewType("myvideo")}>
            Created By me
          </button>

          <button
            className={`btn btn-sm ${
              topicViewType === "intract" && "btn-active"
            }`}
            onClick={() =>
              !_.isEmpty(intractVideos) && setTopicViewType("intract")
            }>
            I have replied to
          </button>
        </div>
      </div>
      <div className=" flex flex-row items-center justify-start w-full">
        <div className=" flex flex-row items-center space-x-2">
          <span className=" text-lg font-bold">Categories</span>

          <ItemHelp text="When you are publishing a topic, you can add a tag to make it easier to filter your topics later. You can switch between them here and find what you need quicker." />
        </div>
      </div>
      <div className=" flex flex-row flex-wrap  items-start w-full  pt-2">
        <div
          onClick={() => setSelectedCategory("")}
          className={` ${
            selectedCategory === "" && "badge-secondary"
          } badge badge-lg mr-2 mb-2 uppercase  cursor-pointer`}>
          All
        </div>
        {categories.map((cat) => (
          <div
            key={cat.id}
            onClick={() => setSelectedCategory(cat.name)}
            className={` ${
              selectedCategory === cat?.name && "badge-secondary"
            } badge badge-lg mr-2 mb-2 uppercase  cursor-pointer`}>
            {cat.name}
          </div>
        ))}
      </div>
      <div className=" flex flex-col w-full">
        {selectedCategory !== "" && (
          <div className=" flex flex-col w-full space-y-2">
            {feedbackExist && <span> Feedback</span>}
            <FeedbackForCategories
              category={selectedCategory}
              recordExist={(e) => setFeedbackExist(e)}
            />
          </div>
        )}
      </div>
      {/* 
      <div className="tabs tabs-boxed w-full justify-end">
        <button
          className={timelineView ? "tab" : "tab tab-active"}
          onClick={() => {
            setLimitData(12);
            setTimelineView(false);
          }}>
          Grid view
        </button>
        <button
          className={timelineView ? "tab tab-active" : "tab"}
          onClick={() => {
            setLimitData(1000);
            setTimelineView(true);
          }}>
          Time line
        </button>
      </div> */}
      {/* <div>dd</div> */}

      {timelineView ? (
        <div className=" flex flex-col w-full py-4">
          {myVideoTimeline?.map((item, index) => (
            <div
              key={index}
              className="flex  flex-col sm:flex-row space-x-2 items-start w-full  border-gray-400 border-t-2">
              <div className="      indicator  ">
                <div className="    break-normal  w-44 p-2 px-4 rounded-full   btn cursor-default  -mt-6   ">
                  {moment().diff(moment(item.day), "days") < 1
                    ? "Today"
                    : moment(item.day).format("MMMM Do ")}
                </div>
              </div>
              <div className=" flex flex-col space-y-2  p-2 py-4 w-full   ">
                {item.data.map((item, index) => (
                  <TimelineItem key={index} data={item} />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {(topicViewType === "all" || topicViewType === "myvideo") && (
            <div className=" flex flex-col w-full items-start justify-start px-4 sm:px-0 ">
              {(topicViewType === "all" || topicViewType === "myvideo") &&
                !_.isEmpty(myVideos) && (
                  <div className=" flex flex-row space-x-2 items-center justify-start border-t py-10  w-full">
                    {" "}
                    <div className="  text-3xl font-bold ">Created by me</div>
                    <ItemHelp text="All topics created by me listed here" />
                  </div>
                )}

              {isLoading ? (
                <DataLoading />
              ) : _.isEmpty(myVideos) ? (
                selectedCategory !== "" ? (
                  <NoRecordBySelectedTag
                    setSelectedCategory={(e) => setSelectedCategory("")}
                  />
                ) : (
                  <FirstTimeNoVideo />
                )
              ) : (
                <div className=" grid grid-cols-1 md:grid-cols-3  lg:grid-cols-5 gap-8">
                  {myVideos?.map((item, index) =>
                    index === 11 ? (
                      <div key={item.id} onClick={() => navigate("/topics")}>
                        <ReadMoreButton />
                      </div>
                    ) : (
                      <Link to={`/topic/${item.access_key}`} key={item.id}>
                        <VideoCoverCard
                          data={item}
                          label={"new replies or comments"}
                          readed={
                            item?.main_video_reads_aggregate?.aggregate?.count >
                              0 &&
                            _.isEmpty(
                              _(item?.response_tables)
                                .thru(function (coll) {
                                  return _.union(
                                    coll,
                                    _.map(coll, "reply_tables") || []
                                  );
                                })
                                .flatten()
                                .find({
                                  reply_video_reads_aggregate: {
                                    aggregate: { count: 0 },
                                  },
                                })
                            ) &&
                            _.isEmpty(
                              _.find(item?.response_tables, function (o) {
                                return (
                                  o.video_reads_aggregate.aggregate.count === 0
                                );
                              })
                            )
                          }
                        />
                      </Link>
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {(topicViewType === "all" || topicViewType === "intract") &&
            !_.isEmpty(intractVideos) && (
              <div className=" flex flex-col w-full items-start justify-start px-4">
                <div className=" flex flex-row space-x-2 items-center justify-start border-t py-10  w-full">
                  {" "}
                  <div className="  text-3xl font-bold ">I have replied to</div>
                  <ItemHelp text="Any topic that i engaged and replied list here" />
                </div>
                {isLoading ? (
                  <DataLoading />
                ) : (
                  <div className=" grid grid-cols-1 md:grid-cols-2  lg:grid-cols-5 gap-8">
                    {intractVideos?.map((item, index) =>
                      index === 11 ? (
                        <div key={item?.id} onClick={() => navigate("/topics")}>
                          <ReadMoreButton />
                        </div>
                      ) : (
                        <Link to={`/topic/${item.access_key}`} key={item.id}>
                          <VideoCoverCard
                            data={item}
                            label={"new replies or comments"}
                            readed={
                              item?.main_video_reads_aggregate?.aggregate
                                ?.count > 0 &&
                              _.isEmpty(
                                _(item?.response_tables)
                                  .thru(function (coll) {
                                    return _.union(
                                      coll,
                                      _.map(coll, "reply_tables") || []
                                    );
                                  })
                                  .flatten()
                                  .find({
                                    reply_video_reads_aggregate: {
                                      aggregate: { count: 0 },
                                    },
                                  })
                              ) &&
                              _.isEmpty(
                                _.find(item?.response_tables, function (o) {
                                  return (
                                    o.video_reads_aggregate.aggregate.count ===
                                    0
                                  );
                                })
                              )
                            }
                          />
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
        </div>
      )}
      {/* <OnboardingModal /> */}
    </MainLayout>
  );
};

export default LandingPage;

const NoRecordBySelectedTag = ({ setSelectedCategory }) => {
  return (
    <div className="hero  py-10">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold ">
            There is nothing in this category, yet
          </h1>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => setSelectedCategory("all")}>
            See all videos
          </button>
        </div>
      </div>
    </div>
  );
};
const FirstTimeNoVideo = () => {
  return (
    <div className="hero   py-10">
      <div className="hero-content text-center">
        <div className="">
          <h1 className="text-5xl font-bold">
            Ready to create your first topic?
          </h1>
          <br />
          <div className=" flex flex-col w-full max-w-3xl relative ">
            <VideoPlayer src="https://www.youtube.com/watch?v=3iQeTaCtgnI" />
          </div>
          <br />
          <Link to="/create" className="btn btn-primary">
            Create topic
          </Link>
        </div>
      </div>
    </div>
  );
};

const ReadMoreButton = () => {
  return (
    <div className="card shadow  hover:opacity-80 cursor-pointer text-base-100  mx-auto my-auto  bg-primary  items-center justify-center w-full outline  outline-8  aspect-square outline-base-300  -bottom-2 ">
      <div className=" card-body  items-center justify-center">
        <span className=" card-title text-xl">More topics</span>
      </div>
    </div>
  );
};

const TimelineItem = ({ data }) => {
  return (
    <Link to={`/topic/${data.access_key}`}>
      <div className=" flex flex-col  bg-base-100 shadow-md   items-start space-y-2 p-2 pb-1 sm:pb-2 rounded-xl w-full hover:bg-base-200 cursor-pointer">
        <div className=" flex flex-row items-center space-x-4">
          {" "}
          <div className=" flex flex-row">
            <img
              src={`/assets/icons/${data?.content_type}.svg`}
              className=" w-10 z-20"
              alt=""
            />
            {data?.response_tables_aggregate?.aggregate?.count > 0 && (
              <img
                src={`/assets/icons/${data?.content_type}.svg`}
                className=" w-10 -ml-7 z-10 opacity-40"
                alt=""
              />
            )}
            {data?.response_tables_aggregate?.aggregate?.count > 1 && (
              <img
                src={`/assets/icons/${data?.content_type}.svg`}
                className=" w-10 -ml-7 z-0 opacity-20"
                alt=""
              />
            )}
          </div>
          <span>{data?.title}</span>
        </div>
        <div className=" flex flex-row  text-opacity-40 space-x-2">
          {" "}
          <span>{data?.video_counters_aggregate?.aggregate?.count} views </span>
          <span>
            {data?.response_tables_aggregate?.aggregate?.count} replies
          </span>
          {data?.main_video_reads_aggregate?.aggregate?.count > 0 &&
          _.isEmpty(
            _(data?.response_tables)
              .thru(function (coll) {
                return _.union(coll, _.map(coll, "reply_tables") || []);
              })
              .flatten()
              .find({
                reply_video_reads_aggregate: {
                  aggregate: { count: 0 },
                },
              })
          ) &&
          _.isEmpty(
            _.find(data?.response_tables, function (o) {
              return o.video_reads_aggregate.aggregate.count === 0;
            })
          ) ? (
            <></>
          ) : (
            <div className=" badge badge-primary ">
              {" "}
              Unread reply or comment
            </div>
          )}
          {!_.isEmpty(
            _.find(data?.response_tables, { email: auth.currentUser?.email })
          ) && (
            <div>
              {data.creator_id === auth.getClaim("x-hasura-user-id") ? (
                <div className=" flex flex-row space-x-2 items-center">
                  <div className=" badge badge-secondary">My topic </div>
                  <div className=" badge badge-secondary">
                    I have replied to{" "}
                  </div>
                </div>
              ) : (
                <div className=" badge badge-secondary">I have replied to </div>
              )}
            </div>
          )}
        </div>
      </div>{" "}
    </Link>
  );
};
