import { useAuth } from "@nhost/react-auth";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateContentStatus from "../../components/global/createContentStatus";
import MainLayout from "../../components/layout/mainLayout";
import OnboardingModal from "../../components/modal/onboardingModal";
import { DispatchContext, StateContext } from "../../context/appContext";
import { recordingTypes } from "./recordingTypes";

const CreateVideoMainPage = ({ destination }) => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    dispatch({ type: "updateRecordingStep", payload: 1 });
    dispatch({ type: "updateOnboardingLocation", payload: "type" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);
  useEffect(() => {
    state?.onboardingLocation &&
      dispatch({ type: "updateOnboardingLocation", payload: "type" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const updateRecordingDestination = (destination, item) => {
    dispatch({ type: "updateOnboardingLocation", payload: item.type });
    dispatch({ type: "updateRecordingDestination", payload: destination });
    navigate(
      destination === "topic"
        ? item.linkForTopic
        : destination === "reply"
        ? item.linkForReply
        : destination === "comment" && item.linkForReply
    );
  };

  function renderHeader(dest) {
    switch (dest) {
      case "topic":
        return (
          <div className="hero  bg-base-200">
            <div className="hero-content text-center">
              <div className="max-w-md">
                <h1 className="text-5xl font-bold">Select your Topic type</h1>
                <p className="py-6">
                  Here you can choose how you want to create the first
                  interaction in your Topic. People you share it with can select
                  a different type for their Reply.
                </p>
              </div>
            </div>
          </div>
        );

      case "reply":
        return (
          <div className="hero  bg-base-200">
            <div className="hero-content text-center">
              <div className="max-w-md">
                <h1 className="text-5xl font-bold">Select your Reply type</h1>
                <p className="py-6">
                  Here you can choose how you want to record a Reply to the
                  selected Topic.
                </p>
              </div>
            </div>
          </div>
        );
      case "comment":
        return (
          <div className="hero  bg-base-200">
            <div className="hero-content text-center">
              <div className="max-w-md">
                <h1 className="text-5xl font-bold">Select your Comment type</h1>
                <p className="py-6">
                  Here you can choose how you want to record a Comment to the
                  selected Topic.
                </p>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  }
  const { signedIn } = useAuth();

  return (
    <MainLayout>
      <CreateContentStatus />
      {signedIn && !state?.userData?.onboarding && <OnboardingModal />}
      {renderHeader(destination)}
      <br />
      <br />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {recordingTypes.map((item, index) => (
          <div key={index}>
            <RecordingSelectionCard
              title={item.title}
              desc={item.desc}
              img={item.img}
              action={() => updateRecordingDestination(destination, item)}
              learn={item.learn}
            />
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default CreateVideoMainPage;

const RecordingSelectionCard = ({ img, title, desc, action, learn }) => {
  return (
    <div className="card  w-full bg-base-100 shadow">
      <figure>
        <img src={img} alt="videobit" />
      </figure>
      <div className="card-body">
        <span className="card-title">{title}</span>
        <p>{desc}</p>
        <div className="card-actions justify-end">
          <button
            onClick={() => window.open(learn, "_blank")}
            className="btn btn-link ">
            Learn
          </button>
          <button className={"btn btn-primary"} onClick={() => action()}>
            Start
          </button>
        </div>
      </div>
    </div>
  );
};
